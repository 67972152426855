import React from "react";


import {Container, Row} from "react-bootstrap";


export const SiteBanner = (props) => {
	
	if (props.location.pathname === "/") {
		return null;
	}
	
	
	return (
		<Container fluid={true} id="site-banner" className="main-content-container">
			<Container id="site-banner-container">
				<Row id="site-banner-content" className="main-container-content">
				
				</Row>
			</Container>
		</Container>
	);
	
};


export default SiteBanner;
