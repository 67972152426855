import React, {useState} from "react";


import {Row, Col, Form, Button} from "react-bootstrap";

import {SectionHeader} from "@Components/Elements/Headers";
import {SystemMessageComponent} from "@Components/Elements/Messages";


import {PolandMap} from "@Components/Content/User/Logged/Profile/Localisations/Map/";
import "./_Map/cssmap-poland.css";


const LocalizationsZones = [
	{localizationID: 1, localizationZones: 38, localizationName: "dolnośląskie"},
	{localizationID: 2, localizationZones: 37, localizationName: "kujawsko-pomorskie"},
	{localizationID: 3, localizationZones: 35, localizationName: "lubelskie"},
	{localizationID: 4, localizationZones: 30, localizationName: "lubuskie"},
	{localizationID: 5, localizationZones: 34, localizationName: "łódzkie"},
	{localizationID: 6, localizationZones: 24, localizationName: "małopolskie"},
	{localizationID: 7, localizationZones: 55, localizationName: "mazowieckie"},
	{localizationID: 8, localizationZones: 24, localizationName: "opolskie"},
	{localizationID: 9, localizationZones: 32, localizationName: "podkarpackie"},
	{localizationID: 10, localizationZones: 33, localizationName: "podlaskie"},
	{localizationID: 11, localizationZones: 38, localizationName: "pomorskie"},
	{localizationID: 12, localizationZones: 31, localizationName: "śląskie"},
	{localizationID: 13, localizationZones: 28, localizationName: "świętokrzyskie"},
	{localizationID: 14, localizationZones: 37, localizationName: "warmińsko-mazurskie"},
	{localizationID: 15, localizationZones: 50, localizationName: "wielkopolskie"},
	{localizationID: 16, localizationZones: 38, localizationName: "zachodniopomorskie"}
];


export const ExpertProfilePageExpertLocalisations = ({REST_AP_URL, expertID, localizationsList, localizationsListHandler}) => {
	
	
	const [apiRequestStatus, setApiRequestStatus] = useState(false);
	const [apiRequestMessage, setApiRequestMessage] = useState(null);
	
	const [expertLocalizations, setExpertLocalizations] = useState(localizationsList);
	
	const updateExpertLocalizations = (localizationID) => {
		let updatedExpertLocalizations;
		if (expertLocalizations.indexOf(localizationID) !== -1) {
			if (localizationID === 17) {
				updatedExpertLocalizations = [];
			}
			else {
				updatedExpertLocalizations = expertLocalizations.filter(localization => localization !== localizationID && localizationID !== 17);
			}
		}
		else {
			if (localizationID === 17) {
				updatedExpertLocalizations = [];
				for (let i = 0; i < 17; i++) {
					updatedExpertLocalizations.push(i + 1);
				}
			} else {
				updatedExpertLocalizations = [...expertLocalizations, localizationID].filter(localizationID => localizationID !== 17);
			}
			
		}
		console.log(updatedExpertLocalizations)
		setExpertLocalizations(updatedExpertLocalizations);
		localizationsListHandler(updatedExpertLocalizations);
	};
	
	
	const selectedExpertLocalizations = LocalizationsZones.map(localization => {
		if (expertLocalizations.indexOf(localization.localizationID) === -1) {
			return null;
		}
		return (
			<li key={localization.localizationID}>{localization.localizationName}</li>
		);
	}).filter(localization => localization);
	
	
	const updateExpertLocalizationsRequest = () => {
		
		setApiRequestStatus(true);
		
		const expertLocalizationsCollection = expertLocalizations.map(localizationID => `${REST_AP_URL}/localization/provinces/${localizationID}/`);
		
		fetch(
			`${REST_AP_URL}/experts/${expertID}/`, {
				headers: {'Accept': 'application/json, text/plain', 'Content-Type': 'application/json;charset=UTF-8'},
				method: "PATCH", body: JSON.stringify({
					"expert_province": expertLocalizationsCollection
				})
			})
			.then(response => response.ok ? response.json() : response.text())
			.then(resource => {
				setApiRequestStatus(false);
				setApiRequestMessage({
					messageType: resource.error ? "danger" : "success",
					messageContent: resource.error ? `Wystąpił błąd` : "Lokalizacje zostały zaktualizowane"
				})
			})
			.catch(e => console.log(e));
		
	};
	
	
	return (
		
		<Col xs={12} id="expert-profile-localizations">
			
			<SectionHeader headerText="Regiony Działania" />
			
			<Row>
				
				{apiRequestMessage &&
				<SystemMessageComponent
					messageType={apiRequestMessage.messageType}
					userMessageContent={apiRequestMessage.messageContent}
					closeHandler={setApiRequestMessage}
				/>
				}
				
				<Col xs={12} md={3} className="expert-selected-localizations">
					<ul className="expert-localizations-list">
						{selectedExpertLocalizations.length > 0 ?
							selectedExpertLocalizations
						:
							<li className="no-regions-selected">Nie wybrano żadnego regionu</li>
						}
					</ul>
				</Col>
				
				<Col xs={12} md={{span: 6, offset: 3}} className="expert-localizations-map">
					<PolandMap
						apiRequestStatus={apiRequestStatus}
						localizationsZones={LocalizationsZones}
						expertLocalizations={expertLocalizations}
						updateExpertLocalizations={updateExpertLocalizations}
					/>
				</Col>
				
				<Col as={Form.Group} xs={12} className="text-center submit-row">
					<Button variant="primary" onClick={() => updateExpertLocalizationsRequest()} disabled={apiRequestStatus}>Zapisz</Button>
				</Col>
			
			</Row>
			
		</Col>
	
	);
	
};


export default ExpertProfilePageExpertLocalisations;
