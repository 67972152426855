import React from "react";


import {Row, Col, Alert} from "react-bootstrap";


import DigitalLibraryFileComponent from "@Components/Content/User/Logged/Library/Files/File";


export const DigitalLibraryFilesComponent = ({filesList}) => {
	
	let filesListContent = null;
	
	if (filesList && filesList.categoryFiles) {
		if (filesList.categoryFiles.length < 1) {
			filesListContent = <Col as={Alert} xs={{span: 10, offset: 2}} variant="warning">Brak Plików w tej Kategorii</Col>
		}
		else {
			filesListContent = filesList.categoryFiles.map((libraryFile, index) =>
				<DigitalLibraryFileComponent
					key={index}
					filesData={libraryFile}
				/>
			);
		}
	}
	else if (filesList && filesList.searchFiles) {
		if (filesList.searchFiles.length < 1) {
			filesListContent = <Col as={Alert} xs={12} variant="warning">Brak Plików dla tych filtrów</Col>
		}
	}
	
	
	return (
		
		<Col xs={12} className="library-files-list">
			<Row>
				{filesListContent}
			</Row>
		</Col>
		
	);
	
};


export default DigitalLibraryFilesComponent;