import React from "react";
import PricesList from "@Components/Content/Pages/ForExpert/Prices";


export default {
	
	page_description: <>
		<p>
			Portal <strong>eksperci-srodowiskowi.pl</strong> to miejsce zapewniejące wiodczność
			Twojej firmy dla inwestora i innych firm z branży. Niezależnie jak długo jesteś w
			branży, odpowiednie zaprezentowanie się przed inwestorem i innymi firmami to podstawa.
		</p>
	</>,
	
	page_content: {
	
		content_sections: {
			
			advantages: {
				section_id: "advantages",
				section_label: "korzyści z bycia w serwisie",
				section_title: "dlaczego warto mieć konto",
				section_content: <>
					<p>
						Najważniejszą korzyścią jest to, że Ty/Twoja firma będzie widoczna dla potencjalnych klientów oraz innych firm, co pozwoli na poszerzenie kontaktów biznesowych. Wbrew pozorom jeśli nie otrzymało się bezpośredniego kontaktu do danej firmy np. nazwy, telefonu czy strony internetowej, to wcale nie tak łatwo jest znaleźć firmę o interesującej nas specjalizacji czy konkretnym doświadczeniu.
					</p>
					<p>
						Na portalu <strong>eksperci-srodowiskowi.pl</strong> nie tylko jesteś widoczny dla potencjalnych klientów i partnerów, ale zyskujesz znacznie więcej:
					</p>
					<ul>
						<li>masz możliwość dodatkowo uzyskać dostęp do Newslettera z przetargami - bezpośrednio na maila będziesz otrzymywał wyselekcjonowane zapytania i ogłoszenia o przetargach,</li>
						<li>zyskujesz dostęp do biblioteki cyfrowej z opracowaniami przydatnymi przy tworzeniu dokumentacji,</li>
						<li>zyskujesz dostęp do Newslettera z informacjami o branżowych konferencjach i szkoleniach,</li>
						<li>masz możliwość zaprezentować się jako autor ciekawego artykułu, który będzie umieszczony na stronie głównej portalu - to nie tylko prestiż, ale też reklama dla Ciebie i Twojej firmy.</li>
					</ul>
				</>
			},
			prices: {
				section_id: "prices",
				section_label: "cennik",
				section_title: "cennik",
				section_content: <PricesList />
			},
			courses: {
				section_id: "courses",
				section_label: "informacje o szkoleniach i konferencjach",
				section_title: "informacje o szkoleniach i konferencjach",
				section_content: <>
					<p>
						Niełatwo jest śledzić informacje o szkoleniach i konferencjach – pojawiają się w różnym czasie w różnych miejscach w Internecie.
						Nie zawsze taka informacja dociera do odbiorcy i nie zawsze na czas.
						Nasz newsletter zbiera te informacje dla Was i dostarcza wprost na Wasze skrzynki mailowe.
						Możesz się zapisać, nawet jeśli nie jesteś posiadaczem konta w portalu.
					</p>
				</>
			},
			sells: {
				section_id: "sells",
				section_label: "informacje o przetargach",
				section_title: "informacje o przetargach",
				section_content: <>
					<p>
						Tego jak ważne dla firmy jest zdobywanie zleceń nie trzeba tłumaczyć, tylko dlaczego niektórym firmom udaje się ich pozyskiwać znacznie więcej?
						Odpowiedź jest jedna – najprawdopodobniej biorą udział w znacznie większej liczbie przetargów i zapytań.
						Pytanie tylko skąd oni biorą o nich informacje? Pewnie mają wykupione newslettery z przetargami.
						Na rynku jest wiele firm oferujących tego typu usługi, ale w każdym z nich pojawiają się inne oferty.
						Jeśli firma nie wykupi dostępu do kilku newsletterów, nie będzie wiedziała o części przetargów.
						Przetestowaliśmy to tworząc nasz newsletter. Newsletter stworzony przez eksperci.srodowiskowi.pl zbiera informacje z wielu źródeł, wyselekcjonowane dla branży.
						Dzięki naszemu newsletterowi otrzymacie tylko to co faktycznie Was interesuje oraz możecie być pewni, że nic Was nie omija.
					</p>
				</>
			},
			library: {
				section_id: "library",
				section_label: "biblioteka cyfrowa",
				section_title: "biblioteka cyfrowa",
				section_content: <>
					<p>
						Tworząc opracowania środowiskowe posiadanie odpowiednich materiałów źródłowych może znacząco uprościć jak i przyspieszyć prace.
						Rozumiemy to i stworzyliśmy koncept branżowej biblioteki cyfrowej.
						Każdy posiadacz konta na <a href="https://eksperci-środowiskowi.pl" title="Eksperci Środowiskowe">eksperci-środowiskowi.pl</a>  otrzymuje dostęp do zasobów biblioteki z poziomu konta użytkownika.
						To wiele branżowych opracowań i pracujemy nad sukcesywnym powiększaniem zbiorów.
					</p>
				</>
			}
			
		}
	
	}
	
};