import React, {useState} from "react";


import {NavLink} from "react-router-dom";


import {Row, Col, Alert} from "react-bootstrap";


import {PageMainHeader} from "@Components/Elements/Headers";


import FindExpertSingleResultComponent from "@Components/Content/Pages/FindExpert/Results/Result";
import FindExpertQuestionFormComponent from "@Components/Content/Pages/FindExpert/Results/Form";


export const FindExpertResultsComponent = ({searchData, premiumList, defaultList, sendQuestionHandler}) => {

	
	const {selectedSpecialization, selectedSpecializationName, selectedProvince, selectedProvinceName} = searchData;
	
	
	const [selectedExperts, setSelectedExperts] = useState([]);
	
	
	if (!selectedSpecialization || !selectedProvince) {
		return null;
	}
	
	
	const addExpertToListHandler = (expertID) => {
		if (selectedExperts.includes(expertID)) {
			const updatedSelectedExperts = selectedExperts.filter(expert => expertID !== expert);
			setSelectedExperts(updatedSelectedExperts);
			return false;
		}
		setSelectedExperts([...selectedExperts, expertID]);
	};

	
	const expertsList = premiumList.concat(defaultList);
	
	
	const expertsListComponent = expertsList.map((expert, i) =>
		<FindExpertSingleResultComponent
			key={i}
			expert={expert}
			addExpertToListHandler={addExpertToListHandler}
		/>
	);
	
	
	return (
		<Row id="find-expert-results">
			
			<PageMainHeader
				headerText="Wyniki Wyszukiwania"
			/>
			
			<Row className="results-page-filters">
				<Col xs={12} lg={9} className="filters-list">
					<h5>Wybrana Kategoria:</h5>
					<span className="chosen-specialization">{selectedSpecializationName}</span>
					<h5>Wybrany Region</h5>
					<span className="chosen-province">{selectedProvinceName}</span>
				</Col>
				<Col xs={12} lg={{span: 2, offset: 1}} className="filters-reset">
					<NavLink to="/znajdz-eksperta" className="filters-reset-button btn">
						<span>Resetuj Filtry</span>
					</NavLink>
				</Col>
			</Row>
			
			{!premiumList.length && !defaultList.length ?
			
			<Col xs={12} as={Alert} variant={"danger"} className="no-experts-found">Brak Wyników dla tej konfiguracji filtrów</Col>
				
				:
		<>
			
			<Row className="results-page-results">
				<Col xs={12} lg={7} as="h5" className="search-results-add-item-legend search-results-list-label">
					<span>W Wybranej specjalizacji ilokalizacji działają następujące firmy</span>
				</Col>
				<Col xs={12} lg={5} as="h5" className="search-results-add-item-legend">
					<span>Zaznacz firmy aby wysłać do nich zapytanie</span>
				</Col>
				<Col xs={12} as="ul" className="find-expert-results-list">
					{expertsListComponent}
				</Col>
			</Row>
			
			<FindExpertQuestionFormComponent
				sendQuestionHandler={sendQuestionHandler}
				selectedExperts={selectedExperts}
			/>
			
		</>
			}
			
		</Row>
	)
	
};


export default FindExpertResultsComponent;
