import React from "react";


import {Alert, Col, Row} from "react-bootstrap";


import {Slide} from "@material-ui/core";


export const DigitalLibraryMessageComponent = ({digitalLibraryMessage, digitalLibraryMessageHandler}) => {
	
	
	return (
		<Slide
			in={true}
			direction="down"
			timeout={{enter: 1000}}
			style={{transformOrigin: "left top", transform: "scale(1, 0.01) translate(0, 0)"}}
			mountOnEnter
			unmountOnExit
		>
			<Col
				as={Alert}
				xs={12}
				variant={digitalLibraryMessage.messageType}
				className="digital-library-message"
				dismissible
				onClose={() => digitalLibraryMessageHandler(null)}
			>
				<span>{digitalLibraryMessage.messageContent}</span>
			</Col>
		</Slide>
	);
	
};


export default DigitalLibraryMessageComponent;
