import React from "react";


import ReactTooltip from "react-tooltip";


export const PolandMapComponent = ({selectedProvinces = [], mapSize = 430, selectHandler = null}) => {
	
	
	// const provincesIDs = [...Array(16).keys()].map(i => i + 1);

	
	const provincesZones = [
		{provinceID: 1, provinceZones: 38, provinceName: "dolnośląskie"},
		{provinceID: 2, provinceZones: 37, provinceName: "kujawsko-pomorskie"},
		{provinceID: 3, provinceZones: 35, provinceName: "lubelskie"},
		{provinceID: 4, provinceZones: 30, provinceName: "lubuskie"},
		{provinceID: 5, provinceZones: 34, provinceName: "łódzkie"},
		{provinceID: 6, provinceZones: 24, provinceName: "małopolskie"},
		{provinceID: 7, provinceZones: 55, provinceName: "mazowieckie"},
		{provinceID: 8, provinceZones: 24, provinceName: "opolskie"},
		{provinceID: 9, provinceZones: 32, provinceName: "podkarpackie"},
		{provinceID: 10, provinceZones: 33, provinceName: "podlaskie"},
		{provinceID: 11, provinceZones: 38, provinceName: "pomorskie"},
		{provinceID: 12, provinceZones: 31, provinceName: "śląskie"},
		{provinceID: 13, provinceZones: 28, provinceName: "świętokrzyskie"},
		{provinceID: 14, provinceZones: 37, provinceName: "warmińsko-mazurskie"},
		{provinceID: 15, provinceZones: 50, provinceName: "wielkopolskie"},
		{provinceID: 16, provinceZones: 38, provinceName: "zachodniopomorskie"}
	];
	
	
	return (
		<div className={`poland-country-map cssmap-container cssmap-${mapSize}`}>
			<ul className="poland cssmap">
				
				{provincesZones.map(province => {
					
					return (
						<li
							key={province.provinceID}
							onClick={selectHandler}
							title={province.provinceName}
							data-tip={province.provinceName}
							className={`pl${province.provinceID}` + (selectedProvinces.indexOf(province.provinceID) !== -1 ? " active-region" : "")}
						>
							<span className="m">
								{[...Array(province.provinceZones).keys()].map(i =>
									<span key={i} className={`s${i + 1}`}></span>
								)}
							</span>
							<span className="bg"></span>
							<ReactTooltip place="top" type="success" effect="float" />
						</li>
					)}
				)}
				
			</ul>
		</div>
	);
	
};


export default PolandMapComponent;
