import React, {useState, useEffect, useRef} from "react";

import ReactHtmlParser from 'react-html-parser';


import {ScrollToDomElement} from "@Components/_UI/Animations/Scrolling";


import {Container, Row, Col} from "react-bootstrap";

import {MainContentLoader} from "@Components/Elements/Loaders";


export const ArticlesListPage = () => {
	
	
	const [articlesList, getArticlesList] = useState(null);
	
	useEffect(
		() => {
			fetch("http://environmental.webprosdev.usermd.net/api/v1/articles/")
				.then(response => response.json())
				.then(resource => getArticlesList(resource))
		}, []
	);
	
	
	const domRef = useRef();
	
	useEffect(() => {
		ScrollToDomElement(domRef.current, 1000)
	}, []);
	
	
	return (
		<Container id="site-primary-content" ref={domRef}>
			<Container id='site-primary-content-container'>
				<Row id="articles-list-page" className="main-container-content main-content">
					
					<h1 className="main-header">
						<span>Artykuły</span>
					</h1>
					
					
					{articlesList ?
					<ul className="articles-list">
						
						{articlesList.length === 0 ? <li className="zero-list-elements">Brak Artykułów</li> : articlesList.map(article => {
							
							const {
								id: articleID, text_article_title: articleTitle, text_article_slug: articleSlug,
								text_article_image: articleImage, text_article_publish_status: articlePublishStatus,
								text_article_publish_date: articlePublishDate, text_article_short_content: articleShortContent,
								text_article_author: articleAuthorID, text_article_author_name: articleAuthorName,
								text_article_author_company: articleAuthorCompany
							} = article;
							
							if (!articlePublishStatus) {
								return null;
							}
							
							return (
								<ArticleListPageItem
									key={articleID}
									articleID={articleID}
									articleTitle={articleTitle}
									articleSlug={articleSlug}
									articleImage={articleImage}
									articlePublishDate={articlePublishDate}
									articleShortContent={articleShortContent}
									articleAuthorID={articleAuthorID}
									articleAuthorName={articleAuthorName}
									articleAuthorCompany={articleAuthorCompany}
								/>
							);
							
						})}
					
					</ul>
					:
					<MainContentLoader loaderColor="#9DBF80" />
					}
				
				</Row>
			</Container>
		</Container>
	);
	
};


		const ArticleListPageItem = ({articleID, articleImage, articleTitle, articleSlug, articlePublishDate, articleShortContent, articleAuthorID, articleAuthorName, articleAuthorCompany}) => {
			
			return (
				<Row as="li" className="articles-list-item">
					<Col xs={12} md={2} className="article-image">
						<a href={`artykuly/${articleSlug}`} title={articleTitle}>
							<img src={articleImage} alt={articleTitle} />
						</a>
					</Col>
					<Col xs={12} md={{span: 9, offset: 1}} className="article-data">
						<h4 className="article-title">
							<a href={`artykuly/${articleSlug}`} title={articleTitle}>{articleTitle}</a>
						</h4>
						<div className="article-meta">
                            <span className="article-author">
	                            {articleAuthorName}
                                <br/>
                                <a href={`/eksperci/${articleAuthorID}`} title={articleAuthorCompany}>
                                    <font>{articleAuthorCompany}</font>
                                </a>
                            </span>
							<span className="article-date">{articlePublishDate}</span>
						</div>
						<div className="article-content">
							<a href={`artykuly/${articleSlug}`} title={articleTitle}>
								{ReactHtmlParser(articleShortContent)}
							</a>
						</div>
					</Col>
				</Row>
			);
			
		};


export default ArticlesListPage;
