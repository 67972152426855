import React, {useEffect, useState} from "react";


import ReactHtmlParser from "react-html-parser";


import {Container, Row} from "react-bootstrap";


import HomePageArticlesList from "@Components/Content/Pages/HomePage/Content/Articles";


export const HomePageContent = ({homePageContentLabel, homePageContentValue, showArticlesOnHomePage, homePageArticlesLabel}) => {
	
	
	const [articlesList, getArticlesList] = useState(null);
	
	
	useEffect(
		() => {
			fetch("http://environmental.webprosdev.usermd.net/api/v1/articles/?results_limit=4")
				.then(response => response.json())
				.then(resource => getArticlesList(resource))
		}, []
	);
	
	
	return (
		
		<Container id="site-secondary-content" fluid={true} className="home-page-content">
			<Container>
				<Row>
					
					{showArticlesOnHomePage &&
					<h2 className="secondary-header">
						<span>{homePageArticlesLabel}</span>
					</h2>
					}
					
					{showArticlesOnHomePage &&
					<HomePageArticlesList
						articlesList={articlesList}
					/>
					}
					
					
					<h2 className="secondary-header">
						<span>{homePageContentLabel}</span>
					</h2>
					
					<div className="main-content">
						{ReactHtmlParser(homePageContentValue)}
					</div>
				
				</Row>
			</Container>
		</Container>
	
	);
	
};


export default HomePageContent;
