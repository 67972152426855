import React, {useState} from "react";


import {Row, Col, Form, Button} from "react-bootstrap";

import {SectionHeader} from "@Components/Elements/Headers";
import {SystemMessageComponent} from "@Components/Elements/Messages";


export const ExpertProfilePageExpertDescription = ({environmentalExpertDescription = "", environmentalExpertData}) => {
	
	
	const {expertID} = environmentalExpertData;
	
	const [
		environmentalExpertActualDescription, changeEnvironmentalExpertDescription
	] = useState(environmentalExpertDescription ? environmentalExpertDescription : "");
	
	
	const [apiRequestStatus, setApiRequestStatus] = useState(false);
	const [apiRequestMessage, setApiRequestMessage] = useState(null);
	
	const updateExpertDescription = () => {
		
		setApiRequestStatus(true);
		
		fetch(
			`http://environmental.webprosdev.usermd.net/api/v1/experts/${expertID}/`, {
			headers: {'Accept': 'application/json, text/plain', 'Content-Type': 'application/json;charset=UTF-8'},
			method: "PATCH", body: JSON.stringify({"expert_description": environmentalExpertActualDescription})
		})
			.then(response => response.ok ? response.text() : {error: true, message: response.text()})
			.then(resource => {
				setApiRequestStatus(false);
				setApiRequestMessage({
					messageType: resource.error ? "danger" : "success",
					messageContent: resource.error ? `Wystąpił błąd` : "Opis został zaktualizowany"
				})
			})
			.catch(e => console.log(e));
		
	};
	
	
	return (
		<Col xs={12} id="expert-profile-description">
			<Row>
				
				<SectionHeader headerText="Opis"/>
				
				{apiRequestMessage &&
				<SystemMessageComponent
					messageType={apiRequestMessage.messageType}
					userMessageContent={apiRequestMessage.messageContent}
					closeHandler={setApiRequestMessage}
				/>
				}
				
				<Col xs={12} as={Form.Group} controlId="expert-description-textarea">
					<Form.Control
						as="textarea"
						name="expert_description"
						value={environmentalExpertActualDescription}
						onChange={(e) => changeEnvironmentalExpertDescription(e.target.value)}
						disabled={apiRequestStatus}
						className={apiRequestStatus ? "disabled" : ""}
					/>
				</Col>
				
				<Col as={Form.Group} xs={12} className="text-center submit-row">
					<Button variant="primary" onClick={updateExpertDescription}>Zapisz</Button>
				</Col>
				
			</Row>
		</Col>
	);
	
};


export default ExpertProfilePageExpertDescription;