import React, {useEffect, useState} from "react";


import bsCustomFileInput from "bs-custom-file-input";


import {Alert, Button, Col, Form} from "react-bootstrap";


import {BeatLoader} from "react-spinners";


import {ProfileSubSectionHeader} from "@Components/Content/User/Logged/Profile/_Elements/Headers";


export const UploadLibraryFileComponent = ({environmentalExpertID, uploadedLibraryFileHandler}) => {
	
	
	useEffect(
		() => {bsCustomFileInput.init()}, []
	);
	
	
	const [libraryMainCategories, getLibraryMainCategories] = useState(null);
	
	const [selectedMainCategory, changeSelectedMainCategory] = useState("0");
	const [libraryFileTitle, changeLibraryFileTitle] = useState("");
	const [libraryFileDescription, changeLibraryFileDescription] = useState("");
	const [libraryFileFile, changeLibraryFileFile] = useState("");
	
	const [formMessage, setFormMessage] = useState({messageType: null, messagesList: []});
	const [fileSendStatus, setFileSendStatus] = useState(false);
	
	
	// GET MAIN CATEGORIES LIST
	const getLibraryMainCategoriesList = () => {
		fetch("http://environmental.webprosdev.usermd.net/api/v1/digital-library/categories/main-categories/")
			.then(response => response.json())
			.then(resource => getLibraryMainCategories(resource))
	};
	
	// MAIN CATEGORIES LIST
	const libraryMainCategoriesList = libraryMainCategories ? libraryMainCategories.map((mainCategory) => {
		const {
			id: mainCategoryID,
			digital_library_main_category_name: mainCategoryName
		} = mainCategory;
		return (
			<option key={mainCategoryID} value={mainCategoryID}>{mainCategoryName}</option>
		);
	}) : null;
	
	
	const formSubmitHandler = (e) => {
		
		e.preventDefault();
		
		const formErrors = {messageType: "error", messagesList: []};
		
		if (!selectedMainCategory || selectedMainCategory === "0") {
			formErrors["messagesList"].push("Nie wybrano kategorii głównej");
		}
		
		if (!libraryFileTitle || libraryFileTitle.trim() < 5) {
			formErrors["messagesList"].push("Nie podano tytułu publikacji");
		}
		
		if (!libraryFileFile) {
			formErrors["messagesList"].push("Nie dodano pliku");
		}
		
		
		if (formErrors.messagesList.length > 0) {
			setFormMessage(formErrors);
		}
		else {
			setFileSendStatus("pending");
			uploadLibraryFile();
		}
		
	};
	
	
	const uploadFileSuccess = (response) => {
		
		setFileSendStatus(false);
		uploadedLibraryFileHandler("upload", response);
		
		setFormMessage({messageType: null, messagesList: []});
		changeSelectedMainCategory("0");
		changeLibraryFileTitle("");
		changeLibraryFileDescription("");
		changeLibraryFileFile(undefined);
		
	};
	
	const uploadLibraryFile = () => {
		
		if (!libraryFileFile) {
			return;
		}
		
		const formData = new FormData();
		
		formData.append("digital_library_file_name", libraryFileTitle);
		formData.append("digital_library_file_title", libraryFileTitle);
		formData.append("digital_library_file_file", libraryFileFile, libraryFileFile.name);
		formData.append(
			"digital_library_file_category",
			`http://environmental.webprosdev.usermd.net/api/v1/digital-library/categories/main-categories/${selectedMainCategory}/`
		);
		formData.append(
			"digital_library_file_expert",
			`http://environmental.webprosdev.usermd.net/api/v1/experts/${environmentalExpertID}/`
		);
		
		fetch("http://environmental.webprosdev.usermd.net/api/v1/digital-library/resources/", {
			method: "POST",
			body: formData,
			// mode: 'no-cors'
		})
			.then(response => response.ok ? response.json() : {error: true, message: response.text()})
			.then(resource => uploadFileSuccess(resource))
			.catch(error => {
				setFormMessage({
					messageType: "error",
					messagesList: ["Nie udało się załadować pliku (może być zbyt duży)"]
				});
				setFileSendStatus(false);
			});
		
	};
	
	
	// API REQUEST - DID MOUNT
	useEffect(getLibraryMainCategoriesList, []);
	
	// LOADER
	if (!libraryMainCategories) {
		return (
			<Col xs={12} className="text-center">
				<BeatLoader color="#7B96B8" />
			</Col>
		);
	}
	
	
	return (
		
		<Col
			as={Form}
			xs={12} md={5}
			encType="multipart/form-data"
			className="upload-library-file-form expert-profile-upload-file-form"
			onSubmit={(e) => (formSubmitHandler(e))}
		>
			
			<ProfileSubSectionHeader
				headerText="Dodaj Materiały"
			/>
			
			{formMessage.messagesList.length > 0 &&
			<ul className={"upload-library-form-message " + formMessage.messageType}>
				{formMessage.messagesList.map((message, index) =>
					<li key={index}>{message}</li>
				)}
			</ul>
			}
			
			<Form.Group controlId="library-file-main-category">
				<Form.Label>Kategoria Główna:</Form.Label>
				<Form.Control
					as="select"
					name="library_file_main_category"
					value={selectedMainCategory}
					onChange={e => changeSelectedMainCategory(e.target.value)}
					onBlur={e => changeSelectedMainCategory(e.target.value)}
					disabled={fileSendStatus === "pending"}
					className="library-file-main-category-select"
					custom
				>
					<option value="0">- wybierz -</option>
					{libraryMainCategoriesList}
				</Form.Control>
			</Form.Group>
			
			<Form.Group controlId="library-file-title">
				<Form.Label>Tytuł:</Form.Label>
				<Form.Control
					// required
					name="library_file_title"
					value={libraryFileTitle}
					onChange={e => changeLibraryFileTitle(e.target.value)}
					disabled={fileSendStatus === "pending"}
					className="library-file-title-input"
				/>
			</Form.Group>
			
			<Form.Group controlId="library-file-description">
				<Form.Label>Opis:</Form.Label>
				<Form.Control
					as="textarea"
					name="library_file_description"
					value={libraryFileDescription}
					onChange={e => changeLibraryFileDescription(e.target.value)}
					disabled={fileSendStatus === "pending"}
					className="library-file-description-textarea"
				/>
			</Form.Group>
			
			<Form.Group controlId="library-file-file custom-file">
				<Form.File
					name="library_file_file"
					onChange={e => {
						changeLibraryFileFile(e.target.files[0]);
					}}
					// required
					label={libraryFileFile ? libraryFileFile.name : "wybierz plik"}
					className="library-file-file-input"
					disabled={fileSendStatus === "pending"}
					custom
				/>
			</Form.Group>
			
			<Form.Group className="upload-file-submit">
				<Button type="submit" variant="primary">Dodaj</Button>
			</Form.Group>
			
			{fileSendStatus === "pending" &&
			<Alert variant="success" className="text-center login-form-loader" style={{marginTop: 20}}>
				<span>Wgrywam...</span>
				<BeatLoader color="#9DBF80"/>
			</Alert>
			}
		
		</Col>
	
	);
	
};


export default UploadLibraryFileComponent;
