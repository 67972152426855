import React, {useEffect, useRef, useState, useContext} from "react";


import {UI as UIContext} from "@Contexts";


import {Container, Row, Col} from "react-bootstrap";


import {MainContentLoader} from "@Components/Elements/Loaders";
import {PageMainHeader} from "@Components/Elements/Headers";
import {ScrollToDomElement} from "@Components/_UI/Animations/Scrolling";

import {formatDigitalLibraryList} from "@Components/Content/User/Logged/Library/_Utilities";
// import DigitalLibrarySearchComponent from "@Components/Content/User/Logged/Library/Search";
import DigitalLibraryCategoriesComponent from "@Components/Content/User/Logged/Library/Categories";
import DigitalLibraryFilesComponent from "@Components/Content/User/Logged/Library/Files";
import DigitalLibraryExpertFilesComponent from "@Components/Content/User/Logged/Library/Expert";


export const DigitalLibraryPageComponent = () => {
	
	
	const domRef = useRef();
	
	
	const [digitalLibraryCategories, getDigitalLibraryCategories] = useState(null);
	const [digitalLibraryFiles, getDigitalLibraryFiles] = useState(null);
	
	
	const [activeLibraryCategory, changeActiveLibraryCategory] = useState(null);
	
	// const [librarySearchKeywords, changeLibrarySearchKeywords] = useState("");
	// const [librarySearchTitle, changeLibrarySearchTitle] = useState("");
	// const [librarySearchAuthor, changeLibrarySearchAuthor] = useState("");
	const [librarySearchSubmit, changeLibrarySearchSubmit] = useState(false);
	
	
	
	const UI = useContext(UIContext);
	const MAIN_API_URL = UI["URLS"]["API"]["REST_API_MAIN_URL"];
	
	const API_REQUEST_URL = `${MAIN_API_URL}/digital-library`;
	
	
	useEffect(() => {
		fetch(`${API_REQUEST_URL}/categories/main-categories/`)
			.then(response => response.json())
			.then(resource => getDigitalLibraryCategories(resource));
		fetch(`${API_REQUEST_URL}/resources/`)
			.then(response => response.json())
			.then(resource => getDigitalLibraryFiles(resource));
	}, []);
	
	
	useEffect(() => {
		ScrollToDomElement(domRef.current, 1000)
	}, [digitalLibraryCategories, digitalLibraryFiles]);
	
	
	const updateDigitalLibraryFiles = (event, fileData) => {
		let updatedDigitalLibraryFiles;
		switch (event) {
			case "upload":
				updatedDigitalLibraryFiles = [...digitalLibraryFiles, fileData]
				break;
			case "delete":
				updatedDigitalLibraryFiles = digitalLibraryFiles.filter(file => file.id !== fileData);
				break;
		}
		getDigitalLibraryFiles(updatedDigitalLibraryFiles);
	};
	
	
	if (!digitalLibraryCategories || !digitalLibraryFiles) {
		return <MainContentLoader />
	}
	
	
	const digitalLibraryResources = formatDigitalLibraryList(digitalLibraryCategories, digitalLibraryFiles);
	
	const digitalLibraryCategoriesList = Object.values(digitalLibraryResources).map(category => {
		const {categoryID, categoryName} = category;
		return {categoryID, categoryName};
	});
	
	
	let digitalLibraryFilesList = null;
	
	if (librarySearchSubmit) {
	
	}
	else if (activeLibraryCategory) {
		digitalLibraryFilesList = digitalLibraryResources[activeLibraryCategory];
	}
	
	return (
		
		<Container fluid={true} id="site-primary-content" className="digital-library-site-content">
			<Container id="site-primary-content-container">
				
				<Row id="digital-library-page-main-content" className="digital-library-content" ref={domRef}>
					
					<PageMainHeader
						headerText="Biblioteka Cyfrowa"
					/>
					
					<Col xs={12} lg={5} className="library-categories">
						<Row>
							
							{/*<DigitalLibrarySearchComponent*/}
							{/*	searchKeywords={librarySearchKeywords}*/}
							{/*	searchKeywordsChangeHandler={changeLibrarySearchKeywords}*/}
							{/*	searchTitle={librarySearchTitle}*/}
							{/*	searchTitleChangeHandler={changeLibrarySearchTitle}*/}
							{/*	searchAuthor={librarySearchAuthor}*/}
							{/*	searchAuthorChangeHandler={changeLibrarySearchAuthor}*/}
							{/*/>*/}
							
							<DigitalLibraryCategoriesComponent
								libraryCategories={digitalLibraryCategoriesList}
								activeCategory={activeLibraryCategory}
								activeCategoryChangeHandler={changeActiveLibraryCategory}
							/>
							
						</Row>
					</Col>
					
					<Col xs={12} lg={7} className="library-files">
						<Row>
							
							<DigitalLibraryFilesComponent
								filesList={digitalLibraryFilesList}
							/>
							
						</Row>
					</Col>
					
				</Row>
				
				
				<DigitalLibraryExpertFilesComponent
					digitalLibraryFilesHandler={updateDigitalLibraryFiles}
				/>
				
				
			</Container>
		</Container>
	);
	
};


export default DigitalLibraryPageComponent;
