import React, {useState} from "react";


import {Row, Col, Alert} from "react-bootstrap";

import {Slide} from "@material-ui/core";

import {SectionHeader} from "@Components/Elements/Headers";
import {ProfileSubSectionHeader} from "@Components/Content/User/Logged/Profile/_Elements/Headers";

import ExpertReferencesUploadFormComponent from "@Components/Content/User/Logged/Profile/ReferencesAndCertificates/UploadReferenceComponent";
import ExpertReferencesGroupComponent from "@Components/Content/User/Logged/Profile/ReferencesAndCertificates/ReferencesListComponent";


export const ExpertProfilePageExpertReferences = ({environmentalExpertID, environmentalExpertReferences, environmentalExpertSpecializations}) => {
	
	
	const [expertReferencesList, updateExpertReferencesList] = useState(environmentalExpertReferences);
	
	const groupExpertReferencesList = (expertReferencesListArg = expertReferencesList) => {
		const expertGroupedReferences = {};
		for (let expertReference of expertReferencesListArg) {
			const {
				expert_reference_specialization_name: expertReferenceSpecializationName,
			} = expertReference;
			if (!(expertReferenceSpecializationName in expertGroupedReferences)) {
				expertGroupedReferences[expertReferenceSpecializationName] = [];
			}
			expertGroupedReferences[expertReferenceSpecializationName].push(expertReference);
		}
		return expertGroupedReferences;
	};
	
	
	const [environmentalExpertGroupedReferences, updateEnvironmentalExpertGroupedReferences] = useState(groupExpertReferencesList());
	
	
	const removeReferenceFileHandler = async (referenceID) => {
		const fileRemove = await fetch(`http://environmental.webprosdev.usermd.net/api/v1/experts-data/references/${referenceID}/`, {method: "DELETE"})
			.then(response => response.ok)
			.then(resource => resource)
			.catch(error => console.log(error));
		if (fileRemove !== true) {
			return false;
		}
		const updatedExpertReferencesList = expertReferencesList.filter(reference => reference.id !== referenceID);
		updateExpertReferencesList(updatedExpertReferencesList);
		updateEnvironmentalExpertGroupedReferences(groupExpertReferencesList(updatedExpertReferencesList));
		setExpertReferencesMessage({messageType: "warning", messageContent: "Referencje zostały usunięte"})
	};
	
	
	const addReferenceFileHandler = (reference) => {
		const updatedExpertReferencesList = [...expertReferencesList, reference];
		updateExpertReferencesList(updatedExpertReferencesList);
		updateEnvironmentalExpertGroupedReferences(groupExpertReferencesList(updatedExpertReferencesList));
		setExpertReferencesMessage({messageType: "success", messageContent: "Referencje zostały dodane"})
	};
	
	
	const [expertReferencesMessage, setExpertReferencesMessage] = useState(null);
	
	const referencesMessage = expertReferencesMessage ?
		<Slide
			in={true}
			direction="down"
			timeout={{enter: 1000}}
			style={{transformOrigin: "left top", transform: "scale(1, 0.01) translate(0, 0)"}}
			mountOnEnter
			unmountOnExit
		>
			<Row style={{width: "100%"}}>
				<Col
					as={Alert}
					xs={12}
					variant={expertReferencesMessage.messageType}
					className="digital-library-message"
					dismissible
					onClose={() => setExpertReferencesMessage(null)}
				>
					<span>{expertReferencesMessage.messageContent}</span>
				</Col>
			</Row>
		</Slide>
		:
		null
	;
	
	
	const environmentalExpertReferencesList = Object.entries(environmentalExpertGroupedReferences).map(referenceGroup => {
		return (
			<ExpertReferencesGroupComponent
				key={referenceGroup[0]}
				referenceGroupName={referenceGroup[0]}
				referenceGroupReferences={referenceGroup[1]}
				removeReferenceFileHandler={removeReferenceFileHandler}
				environmentalExpertID={environmentalExpertID}
			/>
		)
	});
	
	
	return (
		
		<Col xs={12} id="expert-profile-references-and-certificates">
			<Row>
				
				<SectionHeader
					headerText="Referencje i Certyfikaty"
				/>
				
				{referencesMessage}
				
				<Col xs={12} md={5} className="upload-reference-file">
					<Row>
						<ProfileSubSectionHeader
							headerText="Dodaj Referencje"
						/>
						<ExpertReferencesUploadFormComponent
							environmentalExpertID={environmentalExpertID}
							environmentalExpertSpecializations={environmentalExpertSpecializations}
							addReferenceListHandler={addReferenceFileHandler}
						/>
					</Row>
				</Col>
				
				
				<Col xs={12} md={{span: 6, offset: 1}} className="expert-profile-references-and-certificates">
					
					{environmentalExpertReferencesList.length > 0 &&
					<Row>
						<Col as="h4" xs={12} className="profile-section-header references-header">
							<span className="title">Referencje</span>
						</Col>
						<Col xs={12} className="expert-profile-references">
							{environmentalExpertReferencesList}
						</Col>
					</Row>
					}
					
					
				</Col>
				
			
			</Row>
		</Col>
	
	);
	
};



		


export default ExpertProfilePageExpertReferences;