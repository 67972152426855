import React, {useState} from "react";


import {Row, Col, Form, Button} from "react-bootstrap";


import {FaPlus, FcCancel} from "react-icons/all";


const PRICES = {
	country: {
		1: {standard: 480, premium: 400},
		2: {standard: 400, premium: 320},
		3: {standard: 320, premium: 260},
		4: {standard: 240, premium: 200},
		5: {standard: 160, premium: 140},
	},
	province: {
		1: {standard: 240, premium: 200},
		2: {standard: 200, premium: 160},
		3: {standard: 160, premium: 130},
		4: {standard: 120, premium: 100},
		5: {standard: 80, premium: 70},
	}
};


export const ShopContentAddSpecializationComponent = ({
	REST_API_URL, restApiRequestStatus, restApiRequestStatusHandler,
	expertSpecializations, expertProvinces,
	orderedExpertPremiumPositionsIDs,
	addSpecializationHandler
}) => {
	
	
	const [addNewPositionStatus, setAddNewPositionStatus] = useState(false);
	
	const [newPositionSpecialization, setNewPositionSpecialization] = useState("0");
	const [newPositionLocalization, setNewPositionLocalization] = useState("0");
	const [newPositionPositionsList, setNewPositionPositionList] = useState(null);
	const [newPositionPosition, setNewPositionPosition] = useState("0");
	
	
	const expertSpecializationsList = expertSpecializations.map(specialization => {
		const {id: specializationID, specialization_name: specializationName} = specialization;
		return (
			<option key={specializationID} value={specializationID}>{specializationName}</option>
		);
	});
	
	
	const expertLocalizationsList = expertProvinces.map(specialization => {
		const {id: provinceID, province_name: provinceName} = specialization;
		return (
			<option key={provinceID} value={provinceID}>{provinceName.toLowerCase()}</option>
		);
	});
	
	
	const changeNewPositionAddStatus = () => {
		setAddNewPositionStatus(!addNewPositionStatus);
		setNewPositionSpecialization("0");
		setNewPositionLocalization("0");
		setNewPositionPositionList(null);
		setNewPositionPosition("0");
	};
	
	
	const changeNewPositionSpecialization = (specializationID) => {
		setNewPositionSpecialization(specializationID);
		setNewPositionLocalization("0");
		setNewPositionPositionList(null);
		setNewPositionPosition("0");
	};
	
	
	const changeNewPositionLocalization = async (localizationID) => {
		
		restApiRequestStatusHandler(true);
		
		setNewPositionLocalization(localizationID);
		
		const response = await fetch(
				`${REST_API_URL}/specializations/premium-positions/
				?experts_premium_position_province_id=${localizationID}&experts_premium_position_specialization_id=${newPositionSpecialization}`
			);

		const resource = response.ok === true ? await response.json() : {error: true, message: await response.text()};
		
		if (resource.error) {
			return false;
		}
		
		const newPositionAvailablePositions = resource.map(position => {
			const {
				id: positionID,
				experts_premium_position_position: positionPosition,
				experts_premium_position_expert: positionExpert,
				experts_premium_position_reservation: expertsPremiumPositionReservation
			} = position;
			if (orderedExpertPremiumPositionsIDs.indexOf(positionID) !== -1) {
				return false;
			}
			return (
				<option
					key={positionID}
					value={positionPosition}
					disabled={positionExpert !== null || expertsPremiumPositionReservation}
				>
					Pozycja {positionPosition} {(positionExpert !== null || expertsPremiumPositionReservation) ? "(zajęta)" : ""}
				</option>
			);
		});
		
		setNewPositionPositionList(newPositionAvailablePositions);
		
		restApiRequestStatusHandler(false);
		
	};
	

	const addPremiumPositionToOrderHandler = () => {
		changeNewPositionAddStatus(false)
		addSpecializationHandler({
			newPositionLocalization, newPositionSpecialization, newPositionPosition
		});
	};
	
	
	return (
		<Row className="shop-premium-position-add">
			
			<Col xs={1} className={"add-position-cell position-add-button" + (addNewPositionStatus ? " opened" : " closed")}>
				<Row className="position-cell-content">
					<a title={addNewPositionStatus ? "Anuluj" : "Dodaj Nową"} onClick={e => {e.preventDefault(); changeNewPositionAddStatus()}}>
						{addNewPositionStatus ? <FcCancel /> : <FaPlus />}
					</a>
				</Row>
			</Col>
			
		{addNewPositionStatus &&
		<>
		
			<Col xs={3} className="add-position-cell position-specialization">
				<Row className="position-cell-content">
					<Form.Control
						as="select"
						value={newPositionSpecialization}
						onChange={e => changeNewPositionSpecialization(e.target.value)}
						disabled={newPositionLocalization !== "0" || restApiRequestStatus}
					>
						<option value="0">- Specjalizacja - </option>
						{expertSpecializationsList}
					</Form.Control>
				</Row>
			</Col>
			
			<Col xs={2} className="add-position-cell position-localization">
				<Row className="position-cell-content">
					<Form.Control
						as="select"
						value={newPositionLocalization}
						onChange={e => changeNewPositionLocalization(e.target.value)}
						disabled={newPositionSpecialization === "0" || newPositionPosition !== "0" || restApiRequestStatus}
					>
						<option value="0">- Lokalizacja - </option>
						{expertLocalizationsList}
					</Form.Control>
				</Row>
			</Col>
			
			<Col xs={2} className="add-position-cell position-localization">
				<Row className="position-cell-content">
					<Form.Control
						as="select"
						value={newPositionPosition}
						onChange={e => setNewPositionPosition(e.target.value)}
						disabled={newPositionSpecialization === "0" || newPositionLocalization === "0" || restApiRequestStatus}
					>
						<option value="0">- Wybierz Pozycję -</option>
						{newPositionPositionsList}
					</Form.Control>
				</Row>
			</Col>
			
			{newPositionPosition !== "0" &&
			<Col xs={1} className="add-position-cell standard-price position-price">
				<Row className="position-cell-content">
					<label>Standard</label>
					<span>{PRICES[newPositionLocalization === "17" ? "country" : "province"][newPositionPosition].standard} zł</span>
				</Row>
			</Col>
			}
			
			{newPositionPosition !== "0" &&
			<Col xs={1} className="add-position-cell premium-price position-price">
				<Row className="position-cell-content">
					<label>Premium</label>
					<span>{PRICES[newPositionLocalization === "17" ? "country" : "province"][newPositionPosition].premium} zł</span>
				</Row>
			</Col>
			}
			
			{newPositionPosition !== "0" &&
			<Col xs={2} className="add-position-cell position-add-submit">
				<Row className="position-cell-content">
					<Button variant="primary" onClick={() => addPremiumPositionToOrderHandler()}>
						<span>Dodaj</span>
					</Button>
				</Row>
			</Col>
			}
			
		</>}
		
		</Row>
	);
	
};


export default ShopContentAddSpecializationComponent;
