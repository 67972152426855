import React, {useState} from "react";


import {Row, Col} from "react-bootstrap";


import {SectionHeader} from "@Components/Elements/Headers";


import {SystemMessageComponent} from "@Components/Elements/Messages";


import SpecializationsListComponent from "@Components/Content/User/Logged/Profile/Specializations/SpecializationsList/index.";
import SpecializationAddComponent from "@Components/Content/User/Logged/Profile/Specializations/SpecializationsAdd";


export const ExpertProfilePageSpecializations = (specializationsComponentFields) => {

	
	const {
		expertID, accountType,
		specializations, specializationAddHandler, specializationRemoveHandler,
		premiumPositions,
		REST_API_ROOT_URL
	} = specializationsComponentFields;
	
	
	const [specializationsMessage, setSpecializationsMessage] = useState(null);
	
	
	const [deleteSpecializationRequestStatus, setDeleteSpecializationRequestStatus] = useState(false);
	
	
	const deleteSpecializationApiRequest = async (specialization) => {
		
		setDeleteSpecializationRequestStatus(specialization.id);
		
		const updatedSpecializations = specializations.filter(
			s => s.id !== specialization.id
		).map(
			s => s.url
		);
		
		const request = await fetch(
			`http://environmental.webprosdev.usermd.net/api/v1/experts/${expertID}/`, {
				headers: {'Accept': 'application/json, text/plain', 'Content-Type': 'application/json;charset=UTF-8'},
				method: "PATCH", body: JSON.stringify({
					"expert_specialization": updatedSpecializations
				})
			})
			.then(response => response.ok ? true : false)
			.then(resource => resource)
			.catch(() => false);
		
		if (!request) {
			return false;
		}
		
		specializationRemoveHandler(specialization.id);
		
		setDeleteSpecializationRequestStatus(false);
		
		setSpecializationsMessage({
			messageType: "warning",
			messageContent: "Specializacja została usunięta"
		});
		
	};
	
	
	const addExpertSpecialization = (specializations) => {
		specializationAddHandler(specializations);
		setSpecializationsMessage({
			messageType: "success",
			messageContent: "Specializacja została dodana"
		});
	};
	
	
	return (
		
		<Col xs={12} id="expert-profile-specializations">
			
			<SectionHeader headerText="Specjalizacje" />
			
			{specializationsMessage &&
			<SystemMessageComponent
				messageType={specializationsMessage.messageType}
				userMessageContent={specializationsMessage.messageContent}
				closeHandler={setSpecializationsMessage}
			/>
			}
			
			<Row>
				
				<Col xs={12} md={5} className="expert-profile-specializations-list">
					<SpecializationsListComponent
						expertID={expertID}
						specializations={specializations}
						deleteRequestStatus={deleteSpecializationRequestStatus}
						deleteApiRequest={deleteSpecializationApiRequest}
						premiumPositions={premiumPositions}
						REST_API_ROOT_URL={REST_API_ROOT_URL}
					/>
				</Col>
				
				<Col xs={12} md={{span: 5, offset: 2}} className="expert-profile-specializations-add">
					<SpecializationAddComponent
						expertID={expertID}
						accountType={accountType}
						expertSpecializationsList={specializations}
						addExpertSpecializationHandler={addExpertSpecialization}
						REST_API_ROOT_URL={REST_API_ROOT_URL}
					/>
				</Col>
			
			</Row>
			
		</Col>
		
	);

};


export default ExpertProfilePageSpecializations;