import React, {useEffect, useState} from "react";


import {Col, Alert} from "react-bootstrap";


import {PropagateLoader} from "react-spinners";


import {ScrollToDomElement} from "@Components/_UI/Animations/Scrolling";


import ShopContentHeaderComponent from "@Components/Content/User/Logged/Shop/Content/Header";
import ShopContentServicesComponent from "@Components/Content/User/Logged/Shop/Content/Services";
import ShopContentSpecializationsComponent from "@Components/Content/User/Logged/Shop/Content/Specializations";
import ShopContentSummaryComponent from "@Components/Content/User/Logged/Shop/Content/Summary";


const PRICES = {
	country: {
		1: {standard: 480, premium: 400},
		2: {standard: 400, premium: 320},
		3: {standard: 320, premium: 260},
		4: {standard: 240, premium: 200},
		5: {standard: 160, premium: 140},
	},
	province: {
		1: {standard: 240, premium: 200},
		2: {standard: 200, premium: 160},
		3: {standard: 160, premium: 130},
		4: {standard: 120, premium: 100},
		5: {standard: 80, premium: 70},
	}
};


export const ServicesShopContentComponent = ({REST_API_URL, loggedEnvironmentalExpert}) => {
	
	
	const {
		id: expertID,
		expert_active_status: expertActiveStatus,
		expert_account_type: expertAccountType, expert_account_valid_date: expertAccountValidDate,
		expert_newsletter_service: expertNewsletterService, expert_newsletter_service_valid_date: expertNewsletterServiceValidDate,
		expert_specializations: expertSpecializations, expert_provinces: expertProvinces
	} = loggedEnvironmentalExpert;
	
	
	const ref = React.createRef();
	
	useEffect(
		() => {
			if (ref && ref.current) {
				ScrollToDomElement(ref, 500);
			}
		},
		[]
	);
	
	
	const [restApiRequestStatus, setRestApiRequestStatus] = useState(false);
	
	
	const emptyOrderProcessContent = {step: 1, services: {}, specializations: []};
	const [orderProcessContent, setOrderProcessContent] = useState(
		localStorage.getItem("orderProcessContent") ? {...JSON.parse(localStorage.getItem("orderProcessContent")), step: 1} : emptyOrderProcessContent
	);
	
	
	const changeOrderProcessContent = (dataType, orderContent) => {
		
		let newOrderProcessContent;
		
		switch (dataType) {
			case "account":
				newOrderProcessContent = {
					...orderProcessContent,
					services: formatOrderedServices(orderContent),
					specializations: formatOrderedPositions(orderProcessContent["specializations"], orderContent["account"][0] ? orderContent["account"] : expertAccountType)
				};
				break;
			case "submit":
				return cartOrderFinalize(orderContent);
			case "positions":
				newOrderProcessContent = {...orderProcessContent, specializations: formatOrderedPositions(orderContent)};
				break;
			default:
				newOrderProcessContent = {...orderProcessContent};
		}
		
		localStorage.setItem("orderProcessContent", JSON.stringify(newOrderProcessContent));
		setOrderProcessContent(newOrderProcessContent);
		
		setRestApiRequestStatus(false);
		
	};
	
	const formatOrderedServices = (actualServicesData) => {
		
		const orderData = {};
		
		const {
			account: accountData, newsletter: newsletterData
		} = actualServicesData;
		
		if (accountData[0] && (accountData[0] !== +expertAccountType || !expertActiveStatus)) {
			orderData["accountData"] = {
				accountType: accountData[0],
				accountDate: accountData[1],
				accountValue: accountData[0] === 2 ? (expertActiveStatus ? 300 : 900) : 600
			};
		}
		
		if (newsletterData[0] && !expertNewsletterService) {
			orderData["newsletterData"] = {
				newsletterDate: newsletterData[1],
				newsletterValue: +expertAccountType === 2 || accountData[0] === 2 ? 400 : 500
			};
		}
		
		return orderData;
		
	};
	
	
	const formatOrderedPositions = (orderedPositions, accountData = null) => {
		
		const updatedOrderedPositions = [...orderedPositions];
		
		const actualExpertAccountType =
			accountData && accountData[0] ?
				+accountData[0]
			:
				orderProcessContent["services"]["accountData"] ? orderProcessContent["services"]["accountData"]["accountType"] : expertAccountType
		;
		
		if (updatedOrderedPositions.length) {
			for (let [key, specification] of updatedOrderedPositions.entries()) {
				const positionPrice = PRICES
					[+specification.positionLocalization === 17 ? "country" : "province"]
					[specification.positionPosition][+actualExpertAccountType === 1 ? "standard" : "premium"]
				;
				updatedOrderedPositions[key].positionPrice = positionPrice;
			}
		}
		
		return updatedOrderedPositions;
		
	};
	
	
	const formatEndDate = (actual) => {
		const d = new Date();
		const nd = actual ? d : new Date(d.setFullYear(d.getFullYear() + 1));
		const day = nd.getDate() + 1;
		const month = nd.getMonth() + 1;
		const year = nd.getFullYear();
		return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
	};
	
	
	const cartOrderFinalize = async (orderTotalValue) => {
		
		ScrollToDomElement(ref, 500);
		
		setRestApiRequestStatus(true);
		
		setOrderProcessContent({...orderProcessContent, step: 2});
		
		const {
			services, specializations
		} = orderProcessContent;

		const orderData = new FormData();
		orderData.append("shop_order_value", orderTotalValue);
		orderData.append("shop_order_expert", `${REST_API_URL}/experts/${expertID}/`);
		
		const createdOrderData = await
			fetch(`${REST_API_URL}/shop/orders/`, {method: "POST", body: orderData})
				.then(response => response.ok ? response.json() : {error: response.text()})
				.then(resource => resource)
				.catch(error => {return {error: true, message: error}});
		
		if (createdOrderData.error) {
			alert("błąd");
			return false;
		}
		
		const {
			id: orderID
		} = createdOrderData;
		
		if (services["accountData"]) {
			const accountTypeData = new FormData();
			accountTypeData.append("service_type", services["accountData"]["accountType"]);
			accountTypeData.append("service_type_end_date", services["accountData"]["accountDate"].split("-").reverse().join("-"));
			accountTypeData.append("service_value", services["accountData"]["accountValue"]);
			accountTypeData.append("service_shop_order", `${REST_API_URL}/shop/orders/${orderID}/`);
			const accountTypeOrder = await
				fetch(`${REST_API_URL}/shop/items/services/`, {method: "POST", body: accountTypeData})
					.then(response => response.ok ? response.json() : {error: response.text()})
					.then(resource => resource)
					.catch(error => {return {error: true, message: error}});
			if (accountTypeOrder.error) {
				return false;
			}
		}
		
		if (services["newsletterData"]) {
			const newsletterTypeData = new FormData();
			newsletterTypeData.append("service_type", "3");
			newsletterTypeData.append("service_type_end_date", services["newsletterData"]["newsletterDate"].split("-").reverse().join("-"));
			newsletterTypeData.append("service_value", services["newsletterData"]["newsletterValue"]);
			newsletterTypeData.append("service_shop_order", `${REST_API_URL}/shop/orders/${orderID}/`);
			const newsletterTypeOrder = await
				fetch(`${REST_API_URL}/shop/items/services/`, {method: "POST", body: newsletterTypeData})
					.then(response => response.ok ? response.json() : {error: response.text()})
					.then(resource => resource)
					.catch(error => {return {error: true, message: error}});
			if (newsletterTypeOrder.error) {
				return false;
			}
		}
		
		if (specializations && specializations.length > 0) {
			for (let specialization of specializations) {
				const orderSpecialization = new FormData();
				orderSpecialization.append("position_shop_order", `${REST_API_URL}/shop/orders/${orderID}/`);
				orderSpecialization.append("position_premium_position", specialization.positionURL);
				orderSpecialization.append("position_order_value", specialization.positionPrice);
				orderSpecialization.append("position_end_date", formatEndDate());
				const orderSpecializationResponse = await fetch(
					`${REST_API_URL}/shop/items/positions/`, {method: "POST", body: orderSpecialization}
					)
					.then(response => response.ok ? response.json() : {error: response.text()})
					.then(resource => resource)
					.catch(error => {return {error: true, message: error}})
				;
				if (!orderSpecializationResponse.error) {
					const orderSpecializationReservation = new FormData();
					orderSpecializationReservation.append("experts_premium_position_expert", `${REST_API_URL}/experts/${expertID}/`);
					orderSpecializationReservation.append("experts_premium_position_start_date", formatEndDate(true));
					orderSpecializationReservation.append("experts_premium_position_end_date", formatEndDate());
					orderSpecializationReservation.append("experts_premium_position_reservation", "1");
					await fetch(
						specialization.positionURL, {method: "PATCH", body: orderSpecializationReservation}
					)
						.then(response => response.ok ? response.json() : {error: response.text()})
						.then(resource => resource)
						.catch(error => {return {error: true, message: error}})
					;
				}
				
			}
		}
		
		console.log(createdOrderData)
		
		setRestApiRequestStatus(false);
		localStorage.setItem("orderProcessContent", JSON.stringify({
			...orderProcessContent, step: 2, orderID: createdOrderData.id})
		);
		
	};
	
	
	const orderProcessStep = orderProcessContent["step"];
	
	
	const {expert_shop_orders: expertShopOrders} = loggedEnvironmentalExpert;
	const unfinishedOrders = expertShopOrders.filter(order => +order["shop_order_status"] !== 3);
	
	
	if (orderProcessContent.orderID) {
		for (let order of expertShopOrders) {
			if (order.id === orderProcessContent["orderID"]) {
				if (order["shop_order_status"] === 3) {
					setOrderProcessContent(emptyOrderProcessContent);
					localStorage.removeItem("orderProcessContent");
				}
			}
		}
	}
	
	if (unfinishedOrders.length > 0) {
		return (
			<Col as={Alert} xs={12} variant="warning" className="find-expert-form-warning text-center justify-content-center">
				<p className="text-center">
					Posiadasz niedokończone zamówienia.<br/>
					Aby złożyć kolejne prosimy poczekać na finalizację poprzednich.
				</p>
			</Col>
		);
	}
	
	
	return (
		<div ref={ref}>
		
			<ShopContentHeaderComponent
				orderProcessStep={orderProcessStep}
				expertActiveStatus={expertActiveStatus}
			/>
			
			{restApiRequestStatus &&
			<Alert variant="success" className="find-expert-form-success">
				<h4>Trwa Składanie zamówienia</h4>
				<PropagateLoader
					css={`display: inline-block; min-height: 30px;`}
				/>
			</Alert>
			}
			
			{orderProcessStep !== 2 &&
			<ShopContentServicesComponent
				servicesOrderContent={orderProcessContent["services"]}
				expertAccountStatus={{
					serviceStatus: expertActiveStatus, serviceValue: expertAccountType, serviceDate: expertAccountValidDate
				}}
				expertNewsletterService={{
					serviceStatus: expertNewsletterService, serviceDate: expertNewsletterServiceValidDate
				}}
				orderProcessHandler={changeOrderProcessContent}
			/>
			}
			
			{orderProcessStep !== 2 &&
			<ShopContentSpecializationsComponent
				REST_API_URL={REST_API_URL}
				restApiRequestStatus={restApiRequestStatus}
				restApiRequestStatusHandler={setRestApiRequestStatus}
				expertAccountType={orderProcessContent["services"] && orderProcessContent["services"]["accountData"] ? orderProcessContent["services"]["accountData"]["accountType"] : expertAccountType}
				specializationsOrderContent={orderProcessContent["specializations"]}
				expertSpecializations={expertSpecializations}
				expertProvinces={expertProvinces}
				orderProcessHandler={changeOrderProcessContent}
			/>
			}
			
			<ShopContentSummaryComponent
				restApiRequestStatus={restApiRequestStatus}
				orderProcessContent={orderProcessContent}
				orderProcessContentHandler={changeOrderProcessContent}
				orderProcessHandler={changeOrderProcessContent}
				expertAccountType={orderProcessContent["services"] && orderProcessContent["services"]["accountData"] ? orderProcessContent["services"]["accountData"]["accountType"] : expertAccountType}
			/>
			
		</div>
	);
	
};


export default ServicesShopContentComponent;
