import React, {Component} from "react";
import {connect as CONNECT} from "react-redux";


import {Container, Row} from "react-bootstrap";


import SiteLogo from "@Components/Containers/Header/Logo";
import SiteNavigation from "@Components/Containers/Header/Navigation";


export class SiteHeader extends Component {
	
	render() {
		return (
			<Container fluid={true} as="header" id="site-header" className="main-content-container">
				<Container id="site-header-container">
					<Row id="site-header-content" className="main-container-content">
						
						<SiteLogo />
						
						<SiteNavigation />
					
					</Row>
				</Container>
			</Container>
		);
	}
	
}


export default CONNECT(null, null)(SiteHeader)
