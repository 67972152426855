import React, {useContext, useState, useEffect} from "react";
import {useSelector} from "react-redux";


import {UI as UIContext} from "@Contexts";

import {Redirect} from "react-router-dom";

import {Container, Row, Col, Alert} from "react-bootstrap";

import {BounceLoader} from "react-spinners";

import {PageMainHeader} from "@Components/Elements/Headers";


import ExpertProfilePageCompanyData from "@Components/Content/User/Logged/Profile/CompanyData";
import ExpertProfilePageExpertLocalisations from "@Components/Content/User/Logged/Profile/Localisations";
import ExpertProfilePageExpertDescription from "@Components/Content/User/Logged/Profile/Description";
import ExpertProfilePageExpertReferences from "@Components/Content/User/Logged/Profile/ReferencesAndCertificates";
import ExpertProfilePageSpecializations from "@Components/Content/User/Logged/Profile/Specializations";
import {ScrollToDomElement} from "@Components/_UI/Animations/Scrolling";


export const ExpertProfilePage = () => {
	
	
	const ref = React.createRef();
	
	useEffect(() => {
		ScrollToDomElement(ref, 500);
	}, []);
	
	
	const UI = useContext(UIContext);
	
	
	const [environmentalExpert, getEnvironmentalExpert] = useState(null);
	
	useEffect(() => {
		fetch(`${REST_API_URL}/${LOGGED_EXPERT["expertID"]}/`)
			.then(
				response => response.ok === true ? response.json() : null
			)
			.then(resource => getEnvironmentalExpert(resource))
			.catch(e => console.log(e));
	}, []);
	
	const LOGGED_EXPERT = useSelector(state => state.authentication.authenticatedUserData);
	// LOGGED_EXPERT["expertID"] = 78;
	
	if (!LOGGED_EXPERT) {
		return <Redirect to="/logowanie" />
	}
	
	
	// REST API URL - GET LOGGED USER
	const REST_API_URL = UI["URLS"]["API"]["REST_API_MAIN_URL"] + "/experts";
	const REST_API_ROOT_URL = UI["URLS"]["API"]["REST_API_MAIN_URL"];
	
	// MEDIA URL - IMAGES & REFERENCES
	const MEDIA_URL =
		UI["URLS"]["ROOT"] +
		UI["URLS"]["MEDIA"]["URL"] +
		UI["URLS"]["MEDIA"]["EXPERTS"]["URL"] +
		// "/" + LOGGED_EXPERT["expertID"]
		"/" + LOGGED_EXPERT["expertID"]
	;
	
	
	return (
		<Container fluid={true} id="site-primary-content" className="expert-profile-page">
			<Container id='site-primary-content-container'>
				<Row id="expert-profile-page" className="main-container-content main-content" ref={ref}>
				
					<PageMainHeader headerText="Profil" />
					
				{!environmentalExpert ?
					
					<EnvironmentExpertProfileLoader
						loaderColor={UI["themeDetails"]["specialColors"]["primary"]}
					/>
					
					:
					
					<EnvironmentExpertProfileContent
						environmentalExpert={environmentalExpert}
						URLS={{MEDIA_URL, REST_API_URL, REST_API_ROOT_URL}}
					/>
					
				}
				
				</Row>
				
			</Container>
		</Container>
	);
	
};

	


		const EnvironmentExpertProfileLoader = ({loaderColor}) => {
			return (
				<Col xs={12} className="profile-loader text-center">
					<BounceLoader color={loaderColor} />
					<BounceLoader color={loaderColor} />
					<BounceLoader color={loaderColor} />
				</Col>
			);
		};
		
		
		
		
		const EnvironmentExpertProfileContent = ({environmentalExpert, URLS}) => {
			
			
			
			/*  PORTAL MAIN URLS OBJECT DESTRUCTURE  */
			const {
				REST_API_ROOT_URL // MAIN REST API URL
			} = URLS;
			
			const REST_AP_URL = REST_API_ROOT_URL;
			
			/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
			*	ENVIRONMENTAL EXPERT OBJECT DESTRUCTURE
			*/
			const {
				
				// Expert ID (PK)
				id: expertID,
				
				expert_account_type: expertAccountType,
				expert_account_valid_date: environmentalExpertAccountValidDate,
				expert_active_status: environmentalExpertActiveStatus,
				expert_company_email: environmentalExpertEmail,
				expert_last_login_date: environmentalExpertLastLoginDate,
				expert_registration_date: environmentalExpertRegistrationDate,
				expert_description: environmentalExpertDescription,
				
				/* Expert Localizations List */
				expert_provinces: expertLocalizations,
				
				/* Expert Specializations List */
				expert_specializations: expertSpecializations,
				
				/* Expert Specializations Premium Positions List */
				expert_premium_positions: expertPremiumPositions,
				
				/* Expert References List */
				expert_references: expertReferences,
				
				/* Expert Digital Library Files List */
				expert_digital_library_files: digitalLibraryFiles,
				
				...environmentalExpertEditableFields
				
			} = environmentalExpert;
			
			
			/*  Expert Specializations List Handlers  */
			const [specializationsList, updateSpecializationsList] = useState(expertSpecializations);
			
			const specializationAddHandler = (specializations) => {
				updateSpecializationsList(specializations);
			};
			
			const specializationRemoveHandler = (specializationID) => {
				const updatedSpecializationsList = specializationsList.filter(specialization => specialization.id !== specializationID);
				updateSpecializationsList(updatedSpecializationsList);
			};
			
			/*  Expert Specializations List Handlers  */
			const [localizationsList, setLocalizationsList] = useState(
				expertLocalizations.map(localization => localization.id)
			);
			
			const updateLocalizationsList = (localizations) => {
				setLocalizationsList(localizations);
			};
			
			
			return (
				<>
					
					{+environmentalExpertActiveStatus === 0 &&
					<Col as={Alert} xs={12} variant="danger" className="expert-not-active text-center">
						Twoje Konto jest nieaktywne - skontaktuj się z obsługą portalu w celu jego aktywacji
					</Col>
					}
					
					<ExpertProfilePageCompanyData
						environmentalExpertData={{expertID, environmentalExpertEmail}}
						environmentalExpertEditableFields={environmentalExpertEditableFields}
						URLS={URLS}
					/>
					
					<ExpertProfilePageExpertLocalisations
						REST_AP_URL={REST_AP_URL}
						expertID={expertID}
						localizationsList={localizationsList}
						localizationsListHandler={updateLocalizationsList}
					/>
					
					<ExpertProfilePageSpecializations
						expertID={expertID}
						accountType={expertAccountType}
						specializations={specializationsList}
						specializationAddHandler={specializationAddHandler}
						specializationRemoveHandler={specializationRemoveHandler}
						premiumPositions={expertPremiumPositions}
						REST_API_ROOT_URL={REST_API_ROOT_URL}
					/>
					
					<ExpertProfilePageExpertDescription
						environmentalExpertData={{expertID}}
						environmentalExpertDescription={environmentalExpertDescription}
					/>
					
					<ExpertProfilePageExpertReferences
						environmentalExpertID={expertID}
						environmentalExpertSpecializations={specializationsList}
						environmentalExpertReferences={expertReferences}
					/>
				
				</>
			);
			
		};
		

export default ExpertProfilePage;
