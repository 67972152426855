import React, {useState} from "react";


import {MdRemoveCircle} from "react-icons/all";
import {Alert} from "react-bootstrap";
import {BeatLoader} from "react-spinners";


export const SingleSpecializationComponent = ({specialization, premiumPositions, deleteRequestStatus, deleteApiRequest}) => {
	
	
	const {
		id: ID,
		specialization_name: specializationName,
		specialization_parent_name: specializationParentName,
	} = specialization;
	
	
	const [deleteSpecializationStatus, setDeleteSpecializationStatus] = useState(false);
	
	
	return (
		<li className="expert-profile-specializations-list-item" style={{opacity: (deleteRequestStatus === ID ? 0.5: 1)}}>
			<div className="expert-specialization-info">
				<span className="expert-specialization-name">{specializationName}</span>
				<span className="expert-specialization-parent-name">({specializationParentName})</span>
				{!premiumPositions && !deleteRequestStatus &&
				<a
					href="#deleteSpecialization"
					title="Usuń"
					className={(deleteSpecializationStatus ? "active " : "") + "remove-expert-specialization"}
					onClick={(e) => {
						e.preventDefault();
						if (deleteSpecializationStatus === true) {
							setDeleteSpecializationStatus(false);
							return deleteApiRequest(specialization);
						}
						setDeleteSpecializationStatus(true);
					}}
				>
					<MdRemoveCircle/>
				</a>
				}
				{premiumPositions &&
				<>
					<h6 className="expert-specialization-premium-positions-header">Pozycja gwarantowana</h6>
					<ul className="expert-specialization-premium-positions-list">
						{premiumPositions.map((premiumPosition, index) =>
							<li key={index} className="expert-specialization-premium-positions-list-position">
								<span className="expert-specialization-premium-positions-list-district">
									{premiumPosition["experts_premium_position_province_name"]}
								</span>
								&nbsp;-&nbsp;
								<span className="expert-specialization-premium-positions-list-position-label">
									Pozycja {premiumPosition["experts_premium_position_position"]}
								</span>
							</li>
						)}
					</ul>
				</>
				}
			</div>
		</li>
	);
	
};


export default SingleSpecializationComponent;