import React, {useContext, useState} from "react";

import {useSelector, useDispatch} from "react-redux";
import {LOG_USER_IN_ACTION} from "@Store/Actions/Authentication";


import {UI as UIContext} from "@Contexts";


import {Redirect} from "react-router-dom";


import {Container, Row, Form, Button} from "react-bootstrap";


import MD5 from "crypto-js/md5";
import isEmail from 'validator/lib/isEmail';


export const RegisterPageComponent = () => {
	
	
	const REST_API_URL = useContext(UIContext)["URLS"]["API"]["REST_API_MAIN_URL"];
	const LOGGED_EXPERT = useSelector(state => state.authentication.authenticatedUserData);
	const DISPATCHER = useDispatch();
	
	const [expertCompanyName, setExpertCompanyName] = useState("");
	const [expertEmail, setExpertEmail] = useState("");
	const [expertPassword, setExpertPassword] = useState("");
	const [expertRepeatedPassword, setExpertRepeatedPassword] = useState("");
	
	const changeExpertCompanyName = (value) => {
		if (value.length > 2) {
			setRegisterFormErrors({...registerFormErrors, expertCompanyName: null});
		}
		setExpertCompanyName(value);
	};
	
	const changeExpertEmail = (value) => {
		if (isEmail(value)) {
			setRegisterFormErrors({...registerFormErrors, expertEmail: null});
		}
		setExpertEmail(value);
	};
	
	const changeExpertPassword = (value) => {
		if (value.length > 7) {
			setRegisterFormErrors({...registerFormErrors, expertPassword: null});
		}
		setExpertPassword(value);
	};
	
	const changeExpertRepeatedPassword = (value) => {
		if (value === registerFormErrors.expertEmail) {
			setRegisterFormErrors({...registerFormErrors, expertPassword: null});
		}
		setExpertRepeatedPassword(value);
	};
	
	
	const [registerFormErrors, setRegisterFormErrors] = useState({
		expertCompanyName: null, expertEmail: null, expertPassword: null, expertRepeatedPassword: null
	});
	const [apiRequestErrors, setApiRequestErrors] = useState(null);
	
	
	const registerFormSubmitHandler = async(e) => {
		
		e.preventDefault();
		
		const formErrors = {};
		
		if (expertCompanyName.length < 3) {
			formErrors.expertCompanyName = "Proszę podać nazwę firmy";
		}
		
		if (!isEmail(expertEmail)) {
			formErrors.expertEmail = "Proszę podać prawidłowy adres Email";
		}
		
		if (expertPassword.length < 8) {
			formErrors.expertPassword = "Hasło musi składać się z minimum 8 znaków";
		}
		
		if (expertRepeatedPassword !== expertPassword) {
			formErrors.expertRepeatedPassword = "Hasła nie są identyczne";
		}
		
		if (Object.entries(formErrors).length > 0) {
			setRegisterFormErrors({...registerFormErrors, ...formErrors});
			return null;
		}
		
		await registerUserRequestHandler();
		
	};
	
	
	const registerUserRequestHandler = async() => {
		
		const formData = new FormData();
		formData.append("expert_company_name", expertCompanyName);
		formData.append("expert_company_email", expertEmail);
		formData.append("expert_password", MD5(expertPassword));
		
		const response = await fetch(`${REST_API_URL}/experts/`, {method: "POST", body: formData})
			.then(response => response.ok ? response.json() : {error: true, code: response.status, message: response.statusText, detail: response.text()})
			.catch(error => {return {error: true, code: 500, message: error}})
		;
		
		if (response.error) {
			if (response.detail) {
				response.detail = await response.detail;
				if (JSON.parse(response.detail)["expert_company_email"]) {
					response.detail = "Podany adres Email znajduje się w bazie danych";
				}
				setApiRequestErrors([response.detail]);
			}
			return null;
		}
		
		setApiRequestErrors(null);
		
		if (response.id) {
			DISPATCHER(LOG_USER_IN_ACTION({expertID: response.id}));
			return <Redirect to={`/profil`} />;
		}
		
		return response;
		
	};
	
	
	if (LOGGED_EXPERT) {
		return <Redirect to={`/profil`} />;
	}
	
	
	return (
		<Container id="site-primary-content">
			<Container id='site-primary-content-container'>
				<Row id="register-page" className="main-container-content main-content">
					
					<h1 className="main-header">
						<span>Zarejestruj się</span>
					</h1>
					
					<Form method="POST" id="register-form">
						
						{apiRequestErrors &&
						<Form.Group className="form-row register-form-company">
							<Form.Label className="form-error-label">{apiRequestErrors[0]}</Form.Label>
						</Form.Group>
						}
						
						<Form.Group className="form-row register-form-company">
							<Form.Control
								name="register_form_company"
								value={expertCompanyName}
								onChange={e => changeExpertCompanyName(e.target.value)}
								required="required"
								placeholder="Nazwa Firmy"
								className={registerFormErrors.expertCompanyName ? "is-invalid" : ""}
							/>
							{registerFormErrors.expertCompanyName &&
							<Form.Label className="form-error-label">{registerFormErrors.expertCompanyName}</Form.Label>
							}
						</Form.Group>
						<Form.Group className="form-row register-form-email">
							<Form.Control
								type="email"
								name="register_form_email"
								value={expertEmail}
								onChange={e => changeExpertEmail(e.target.value)}
								required="required"
								placeholder="Email"
								className={registerFormErrors.expertEmail ? "is-invalid" : ""}
							/>
							{registerFormErrors.expertEmail &&
							<Form.Label className="form-error-label">{registerFormErrors.expertEmail}</Form.Label>
							}
						</Form.Group>
						<Form.Group className="form-row register-form-password">
							<Form.Control
								type="password"
								name="register_form_password"
								value={expertPassword}
								onChange={e => changeExpertPassword(e.target.value)}
								required="required"
								placeholder="Hasło"
								className={registerFormErrors.expertPassword ? "is-invalid" : ""}
							/>
							{registerFormErrors.expertPassword &&
							<Form.Label className="form-error-label">{registerFormErrors.expertPassword}</Form.Label>
							}
						</Form.Group>
						<Form.Group className="form-row register-form-password-repeat">
							<Form.Control
								type="password"
								name="register_form_password_repeat"
								value={expertRepeatedPassword}
								onChange={e => changeExpertRepeatedPassword(e.target.value)}
								required="required"
								placeholder="Powtórz Hasło"
								className={registerFormErrors.expertRepeatedPassword ? "is-invalid" : ""}
							/>
							{registerFormErrors.expertRepeatedPassword &&
							<Form.Label className="form-error-label">{registerFormErrors.expertRepeatedPassword}</Form.Label>
							}
						</Form.Group>
						<Form.Group className="form-row submit-row">
							<Button type="submit" variant="primary" onClick={e => registerFormSubmitHandler(e)}>Zarejestruj się</Button>
						</Form.Group>
					</Form>
					
				</Row>
			</Container>
		</Container>
	);
	
};


export default RegisterPageComponent;
