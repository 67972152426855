import React, {useState} from "react";


import {Row, Col, Form, Button} from "react-bootstrap";


export const FindExpertQuestionFormComponent = ({sendQuestionHandler, selectedExperts}) => {

	
	const [formSenderCompanyName, setFormSenderCompanyName] = useState("");
	const [formSenderName, setFormSenderName] = useState("");
	const [formSenderLocalization, setFormSenderLocalization] = useState("");
	const [formSenderEmail, setFormSenderEmail] = useState("");
	const [formSenderPhone, setFormSenderPhone] = useState("");
	const [formSenderQuestion, setFormSenderQuestion] = useState("");
	
	const [formSubmitStatus, setFormSubmitStatus] = useState(false);
	const [formErrors, setFormErrors] = useState(null);
	
	
	const sendMessage = (e) => {
		
		e.preventDefault();
		
		setFormSubmitStatus(true);
		
		const errors = {};
		
		// if (formSenderCompanyName.length < 3) {
		// 	errors["senderCompanyName"] = <li key="senderCompanyName"></li>
		// }
		
		if (formSenderName.length < 3) {
			errors["senderName"] = <li key="senderName">Proszę podać Imię i Nazwisko</li>
		}
		
		if (formSenderEmail.length < 3) {
			errors["senderEmail"] = <li key="senderEmail">Proszę podać prawidłowy adres email</li>
		}
		
		if (formSenderLocalization.length < 3) {
			errors["senderLocalization"] = <li key="senderPhone">Proszę podać lokalizację</li>
		}
		
		if (formSenderPhone.length < 3) {
			errors["senderPhone"] = <li key="senderPhone">Proszę podać prawidłowy numer telefonu</li>
		}
		
		if (formSenderQuestion.length < 10) {
			errors["senderQuestion"] = <li key="senderQuestion">Proszę podać poprawną treść zapytania</li>
		}
		
		if (!selectedExperts.length) {
			errors["selectedExperts"] = <li key="selectedExperts">Nie wybrano żadnego Eksperta</li>
		}
		
		if (Object.keys(errors).length > 0) {
			setFormErrors(errors);
			setFormSubmitStatus(false);
			return false;
		}
		
		setFormErrors(null);
		sendQuestionHandler({
			formSenderCompanyName, formSenderName, formSenderLocalization, formSenderEmail, formSenderPhone, formSenderQuestion
		});
		
	};
	
	
	return (
		<Col as={Form} xs={12} id="find-expert-question-form" onSubmit={e => e.preventDefault()}>
			
			<Row as="h2" className="secondary-header">
				<span>Zadaj Pytanie</span>
			</Row>
			
			{formErrors &&
			<Row className="question-form-errors">
				<Col xs={12} as="ul">
					{Object.values(formErrors)}
				</Col>
			</Row>
			}
			
			<Row className="question-form-fields">
				
				<Col as={Form.Group} xs={12}>
					<Form.Control
						value={formSenderCompanyName}
						onChange={e => setFormSenderCompanyName(e.target.value)}
						onInput={e => setFormSenderCompanyName(e.target.value)}
						placeholder="Nazwa Firmy"
						disabled={formSubmitStatus}
						className={formErrors ? (formErrors.senderCompanyName ? "error" : "valid") : null}
					/>
				</Col>
				
				<Col as={Form.Group} xs={12}>
					<Form.Control
						value={formSenderName}
						onChange={e => setFormSenderName(e.target.value)}
						onInput={e => setFormSenderName(e.target.value)}
						placeholder="Imię i Nazwisko"
						disabled={formSubmitStatus}
						className={formErrors ? (formErrors.senderName ? "error" : "valid") : null}
					/>
				</Col>
				
				<Col as={Form.Group} xs={12}>
					<Form.Control
						type="email"
						value={formSenderEmail}
						onChange={e => setFormSenderEmail(e.target.value)}
						onInput={e => setFormSenderEmail(e.target.value)}
						placeholder="Adres Email"
						disabled={formSubmitStatus}
						className={formErrors ? (formErrors.senderEmail ? "error" : "valid") : null}
					/>
				</Col>
				
				<Col as={Form.Group} xs={12}>
					<Form.Control
						type="tel"
						value={formSenderPhone}
						onChange={e => setFormSenderPhone(e.target.value)}
						onInput={e => setFormSenderPhone(e.target.value)}
						placeholder="Telefon"
						disabled={formSubmitStatus}
						className={formErrors ? (formErrors.senderPhone ? "error" : "valid") : null}
					/>
				</Col>
				
				<Col as={Form.Group} xs={12}>
					<Form.Control
						type="tel"
						value={formSenderLocalization}
						onChange={e => setFormSenderLocalization(e.target.value)}
						onInput={e => setFormSenderLocalization(e.target.value)}
						placeholder="Lokalizacja"
						disabled={formSubmitStatus}
						className={formErrors ? (formErrors.senderLocalization ? "error" : "valid") : null}
					/>
				</Col>
				
				<Col as={Form.Group} xs={12}>
					<Form.Control
						as="textarea"
						value={formSenderQuestion}
						onChange={e => setFormSenderQuestion(e.target.value)}
						onInput={e => setFormSenderQuestion(e.target.value)}
						placeholder="Treść Pytania"
						disabled={formSubmitStatus}
						className={formErrors ? (formErrors.senderQuestion ? "error" : "valid") : null}
					/>
				</Col>
				
				<Col as={Form.Group} xs={12} className="form-submit">
					<Button variant="primary" onClick={e => sendMessage(e)} disabled={formSubmitStatus}>
						<span>Wyślij</span>
					</Button>
				</Col>
			
			</Row>
			
		
		</Col>
	);
	
};


export default FindExpertQuestionFormComponent;