import React from "react";


import {Alert, Col, Row} from "react-bootstrap";


export const ShopContentHeaderComponent = ({orderProcessStep, expertActiveStatus}) => {
	
	
	return (
		<Col xs={12} className="services-shop-page-header">
		
			<Row className="shop-page-container">
			
			
				{!expertActiveStatus && orderProcessStep === 1 &&
				<Col xs={12} md={{span: 6, offset: 3}} className="inactive-status-message">
					<Alert variant="danger">
						<span>Twoje Konto jest nieaktywne! Opłać je aby aktywować</span>
					</Alert>
				</Col>
				}
				
				{orderProcessStep === 2 &&
				<Col xs={12} md={{span: 10, offset: 1}} className="order-summary-message">
					<Alert variant="success" className="text-center">
						<span>Dziękujemy za złożenie zamówienia - teraz trzeba je opłacić i grzecznie poczekać</span>
					</Alert>
				</Col>
				}
			
			
			</Row>
		
		</Col>
	);
	
};


export default ShopContentHeaderComponent;