import React, {useContext, useEffect, useRef, useState} from "react";

import ReactHtmlParser from "react-html-parser";


import {UI as UIContext} from "@Contexts";


import {Col, Container, Row} from "react-bootstrap";

import {MainContentLoader} from "@Components/Elements/Loaders";
import {PageMainHeader, SectionHeader} from "@Components/Elements/Headers";

import {ScrollToDomElement} from "@Components/_UI/Animations/Scrolling";


import CONTENT from "@Components/Content/Pages/ForExpert/_CONTENT";


export const ForExpertPage = () => {
	
	
	const domRef = useRef();
	
	
	const [forExpertPageData, getForExpertPageData] = useState(null);
	
	
	const UI = useContext(UIContext);
	const MAIN_API_URL = UI["URLS"]["API"]["REST_API_MAIN_URL"];
	
	const API_REQUEST_URL = `${MAIN_API_URL}/portal-pages/for-expert-page/1/`;

	
	useEffect(() => {
		ScrollToDomElement(domRef.current, 500);
		fetch(API_REQUEST_URL)
			.then(response => response.json())
			.then(resource => getForExpertPageData(resource));
	}, []);
	
	
	const [activeSection, setActiveSection] = useState(null);
	const [activeSectionContent, setActiveSectionContent] = useState(null);
	
	
	if (!forExpertPageData) {
		return <MainContentLoader />
	}
	
	
	const {
		for_expert_page_title: forExpertPageTitle, for_expert_page_main_content: forExpertPageContent,
		for_expert_page_blocks_advantages_label: advantagesBlockLabel, for_expert_page_blocks_advantages_content: advantagesBlockContent,
		for_expert_page_blocks_advantages_active_status: forExpertPageBlocksAdvantagesActiveStatus,
		for_expert_page_blocks_prices_label: pricesBlockLabel, for_expert_page_blocks_prices_active_status: forExpertPageBlocksPricesActiveStatus,
		for_expert_page_blocks_courses_label: coursesBlockLabel, for_expert_page_blocks_courses_content: coursesBlockContent,
		for_expert_page_blocks_courses_active_status: forExpertPageBlocksCoursesActiveStatus,
		for_expert_page_blocks_sells_label: sellsBlockLabel, for_expert_page_blocks_sells_content: sellsBlockContent,
		for_expert_page_blocks_sells_active_status: forExpertPageBlocksSellsActiveStatus,
		for_expert_page_blocks_library_label: libraryBlockLabel, for_expert_page_blocks_library_content: libraryBlockContent,
		for_expert_page_blocks_library_active_status: forExpertPageBlocksLibraryActiveStatus
	} = forExpertPageData;
	
	
	const forExpertBlocks = [
		{blockName: "advantages", blockLabel: advantagesBlockLabel, blockContent: advantagesBlockContent},
		{blockName: "prices", blockLabel: pricesBlockLabel, blockContent: CONTENT.page_content.content_sections.prices.section_content},
		{blockName: "courses", blockLabel: coursesBlockLabel, blockContent: coursesBlockContent},
		{blockName: "sells", blockLabel: sellsBlockLabel, blockContent: sellsBlockContent},
		{blockName: "library", blockLabel: libraryBlockLabel, blockContent: libraryBlockContent},
	];
	
	
	return (
		
		<>
			
			<Container fluid={true} id="site-primary-content" className="for-expert-site-content">
				<Container id="site-primary-content-container">
					<Row id="for-expert-page-main-content" className="for-expert-content" ref={domRef}>
					
						<PageMainHeader
							headerText={forExpertPageTitle}
						/>
						
						<Col xs={12} className="page-text-content for-investor-page-description">
							<Row>
								{ReactHtmlParser(forExpertPageContent)}
							</Row>
						</Col>
						
						<Col xs={12} className="for-expert-page-content-switcher">
							<Row>
							{forExpertBlocks.map((block, i) => {
								if (block.blockName === "advantages" && !forExpertPageBlocksAdvantagesActiveStatus) {
									return null;
								}
								if (block.blockName === "prices" && !forExpertPageBlocksPricesActiveStatus) {
									return null;
								}
								if (block.blockName === "courses" && !forExpertPageBlocksCoursesActiveStatus) {
									return null;
								}
								if (block.blockName === "sells" && !forExpertPageBlocksSellsActiveStatus) {
									return null;
								}
								if (block.blockName === "library" && !forExpertPageBlocksLibraryActiveStatus) {
									return null;
								}
								return (
								<Col key={i} xs={12} md={4} className="for-expert-page-content-switcher-button">
									<a
										href={`#${block.blockName}`}
										title={block.blockLabel}
										className={activeSection === block.blockName ? "active-element" : ""}
										onClick={(e) => {
											e.preventDefault();
											setActiveSection(activeSection === block.blockName ? null : block.blockName);
											setActiveSectionContent(activeSection === block.blockName ? null : {
												blockTitle: block.blockLabel,
												blockContent: block.blockContent
											})
										}}
									>
										<span>{block.blockLabel}</span>
									</a>
								</Col>
								)
							})}
							</Row>
						</Col>
					
					</Row>
				</Container>
			</Container>
			
			{activeSection &&
			<Container
				fluid={true}
				id="site-secondary-content"
				className={`${activeSection}-content for-expert-site-content`}
				
			>
				<Container id="site-secondary-content-container">
					
					<Row id="for-expert-page-secondary-content" className="for-expert-content-section active-element">
						<SectionHeader
							headerText={activeSectionContent.blockTitle}
						/>
						<Col id={`for-expert-${activeSection}-content`} as="section" xs={12} className="section-content">
							{typeof activeSectionContent.blockContent === "string" ?
								ReactHtmlParser(activeSectionContent.blockContent) :
								activeSectionContent.blockContent
							}
						</Col>
					</Row>
					
				</Container>
			</Container>
			}
			
		</>
		
	);
	
};


export default ForExpertPage;
