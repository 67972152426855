import React, {useRef} from "react";


import {usePdf} from 'react-pdf-js';


import {Row, Col} from "react-bootstrap";



export const ExpertCardReferencesComponent = ({environmentalExpertID, groupedExpertReferences}) => {

	
	const expertReferencesGroups = Object.entries(groupedExpertReferences).map(([groupName, referencesGroup]) =>
		<ExpertCardReferencesGroupComponent
			key={groupName}
			environmentalExpertID={environmentalExpertID}
			expertReferencesGroupName={groupName}
			expertReferencesGroup={referencesGroup}
		/>
	);
	
	
	return (
		
		<Col xs={12} md={{span:7, offset: 1}} className="expert-card-references">
			
			<h4 className="expert-card-section-header section-header">
				<span>Referencje</span>
			</h4>
			
			<Row className="references-groups">
				{expertReferencesGroups}
			</Row>
			
		</Col>
	
	);
	
};


		const ExpertCardReferencesGroupComponent = ({environmentalExpertID, expertReferencesGroupName, expertReferencesGroup}) => {
		
			
			const referencesList = expertReferencesGroup.map(reference =>
				<ExpertCardReferenceComponent
					environmentalExpertID={environmentalExpertID}
					key={reference.id}
					singleReference={reference}
				/>
			);
			
			
			return (
				
				<Col xs={12} className="references-group">
					
					<Row as="h5" className="references-group-header">{expertReferencesGroupName}</Row>
					
					<Row as="ul" className="references-list">
						{referencesList}
					</Row>
					
				</Col>
				
			);
		
		};
		
		
		const ExpertCardReferenceComponent = ({environmentalExpertID, singleReference}) => {
			
			const {
				expert_reference_title: expertReferenceTitle,
				expert_reference_file: expertReferenceFile
			} = singleReference;
			
			const expertReferenceFileName = expertReferenceFile.split("/")[expertReferenceFile.split("/").length - 1];
			
			// PDF PREVIEW LOAD
			const canvasEl = useRef(null);
			const [loading, numPages] = usePdf({
				canvasEl,
				file: `/uploads/users/experts/${environmentalExpertID}/references/${expertReferenceFileName}`,
				page: 1,
				onDocumentLoadFail: e => console.log(e),
				onPageLoadFail: e => console.log(e),
				onLoadError: e => console.log(e)
			});
			
			 
			const hrContainer = useRef(null);
			
			
			return (
				<Col as="li" xs={4} md={3} className="references-list-item">
					<a
						href={`/uploads/users/experts/${environmentalExpertID}/references/${expertReferenceFileName}`}
						rel="nofollow"
						title="zobacz"
						target="_blank"
						ref={hrContainer}
					>
					{loading ?
						<img title={expertReferenceTitle} src="/images/pdf.png" alt={expertReferenceTitle}/>
					:
						<canvas
							ref={canvasEl}
							style={{
								maxWidth: hrContainer.current.clientWidth - 10,
								maxHeight: hrContainer.current.clientWidth * 1.5
							}}
						/>
					}
					</a>
				</Col>
			);
			
		};


export default ExpertCardReferencesComponent;