import React from "react";


import {Row, Col} from "react-bootstrap";


import DigitalLibraryCategoryComponent from "@Components/Content/User/Logged/Library/Categories/Category";


export const DigitalLibraryCategoriesComponent = ({libraryCategories, activeCategory, activeCategoryChangeHandler}) => {
	
	
	const categoriesList = libraryCategories.map(category => {
		return (
			<DigitalLibraryCategoryComponent
				key={category.categoryID}
				activeCategory={activeCategory}
				categoryData={category}
				activeCategoryChangeHandler={activeCategoryChangeHandler}
			/>
		);
	});
	
	
	return (
		
		<Col xs={12} className="library-categories-list">
			<Row>
				
				<Col as="h4" xs={12} className="categories-header">
					<span>Kategorie:</span>
				</Col>
				
				<Col xs={12} className="categories-list">
					<Row>
					
						{categoriesList}
					
					</Row>
				</Col>
				
			</Row>
		</Col>
		
	);
	
};


export default DigitalLibraryCategoriesComponent;