import React from "react";


import ReactHtmlParser from "react-html-parser";


import {NavLink} from "react-router-dom";

import {Col} from "react-bootstrap";


export const HomePageArticlesListItem = ({article, author}) => {
	
	const {
		textArticleImage, textArticleSlug, textArticleTitle, textArticleShortContent
	} = article;
	
	const {
		textArticleAuthorID, textArticleAuthorCompany, textArticleAuthorName
	} = author;
	
	
	return (
		<Col as="li" xs={12} md={3} className="articles-list">
			<div className="article-thumb">
				<NavLink
					to={`/artykuly/${textArticleSlug}`}
					title={textArticleTitle}
				>
					<img
						src={textArticleImage}
						alt={textArticleTitle}
					/>
				</NavLink>
			</div>
			<h5 className="article-title">
				<NavLink
					to={`/artykuly/${textArticleSlug}`}
					title={textArticleTitle}
				>
					{textArticleTitle}
				</NavLink>
			</h5>
			<div className="article-meta">
				<span className="article-author">
					{textArticleAuthorName}
					<NavLink to={`/eksperci/${textArticleAuthorID}`} title="Przejdź do Profilu Eksperta">
						<font>{textArticleAuthorCompany}</font>
					</NavLink>
				</span>
			</div>
			<div className="article-content">
				<NavLink
					to={`/artykuly/${textArticleSlug}`}
					title={textArticleTitle}
				>
					{ReactHtmlParser(textArticleShortContent)}
				</NavLink>
			</div>
		</Col>
	);
	
};


export default HomePageArticlesListItem;