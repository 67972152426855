import React from "react";


import {Row, Col} from "react-bootstrap";


import {SectionHeader} from "@Components/Emelents/Headers";


export const FooterContact = ({mainContactData}) => {
	
	return (
		
		<Col xs={12} md={{span: 5, offset: 4}} className="footer-contact">
		
			<SectionHeader headerText="Kontakt" />
			
			<Row xs={12} className="section-content">
				
				<Col xs={12} className="section-row footer-contact-data">
					<span className="section-row-label">Tel:</span>
					<span className="section-row-value">
						<a href={`tel:+48${mainContactData.contactPhone.replaceAll(" ", "")}`} title="Zadzwoń">
							{mainContactData.contactPhone}
						</a>
					</span>
				</Col>
				
				<Col xs={12} className="section-row footer-contact-data">
					<span className="section-row-label">Email:</span>
					<span className="section-row-value">
						<a href={`${mainContactData.contactEmail}`} title="Napisz do nas" className="email-link">
							{mainContactData.contactEmail}
						</a>
					</span>
				</Col>
			
			</Row>
		
		</Col>
		
	);
	
};


export default FooterContact;
