import React from "react";


import {Col} from "react-bootstrap";


export const DigitalLibraryCategoryComponent = ({categoryData, activeCategory, activeCategoryChangeHandler}) => {
	
	
	const {
		categoryID, categoryName
	} = categoryData;
	
	
	const changeActiveCategory = (e) => {
		e.preventDefault();
		if (activeCategory === categoryID) {
			return activeCategoryChangeHandler(null);
		}
		return activeCategoryChangeHandler(categoryID);
	};
	
	
	return (
		<Col xs={12} lg={6} className="categories-list-item">
			
			<a href={`#category${categoryID}`} title={categoryName} className={activeCategory === categoryID ? "active" : ""} onClick={e => changeActiveCategory(e)}>
				<span>{categoryName}</span>
			</a>
			
		</Col>
	);
	
};


export default DigitalLibraryCategoryComponent;