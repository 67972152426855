import React, {useState} from "react";


import {Button, Col, Row} from "react-bootstrap";


export const ShopContentSummaryComponent = ({restApiRequestStatus, orderProcessContent, orderProcessHandler}) => {
	
	
	const {
		step: orderStep, services: orderedServices, specializations: orderedSpecializations
	} = orderProcessContent;
	
	
	const [regulationsAcceptStatus, setRegulationsAcceptStatus] = useState(false);
	const [formErrors, setFormErrors] = useState(null);
	
	
	const orderSubmit = () => {
		
		if (!regulationsAcceptStatus) {
			setFormErrors(
				["Nie zaakceptowano regulaminu"]
			);
		}
		
		else {
			setFormErrors(null);
			orderProcessHandler("submit", orderTotalValue);
		}
		
	};
	
	
	const orderedServicesQuantity = orderedServices ? Object.keys(orderedServices).length : 0;
	const orderedSpecializationsQuantity = orderedSpecializations ? Object.keys(orderedSpecializations).length : 0;
	
	
	if (!orderedServicesQuantity && !orderedSpecializationsQuantity) {
		return null;
	}
	
	
	const {accountData, newsletterData} = orderedServices;
	

	const servicesTotalValue = (accountData ? accountData["accountValue"] : 0) + (newsletterData ? newsletterData["newsletterValue"] : 0);
	const specializationsTotalValue = orderedSpecializationsQuantity ?
		orderedSpecializations.map(position => position.positionPrice).reduce((price1, price2) => price1 + price2)
	:
		0
	;
	
	
	const orderTotalValue = servicesTotalValue + specializationsTotalValue;
	
	
	return (
		<Col xs={12} md={{span: 5, offset: 7}} className="services-shop-page-summary">
			
			<Row className="shop-page-container">
			
				{orderedServicesQuantity !== 0 &&
				<Col xs={12} className="order-summary-container order-summary-services">
					<Row as="h5" className="order-list-header">
						<span>Wybrane Produkty</span>
					</Row>
					<Row as="ul" className="order-services-list order-list">
						{accountData &&
						<li className="services-list-service services-list-account">
							<span className="service-name">Konto {accountData["accountType"] === 1 ? "Standard" : "Premium"}</span>
							<span className="service-date">(ważne do {accountData["accountDate"]})</span>
							<span className="service-value">{accountData["accountValue"]} PLN</span>
						</li>
						}
						{newsletterData &&
						<li className="services-list-service services-list-newsletter">
							<span className="service-name">Newsletter</span>
							<span className="service-date">(ważne do {newsletterData["newsletterDate"]})</span>
							<span className="service-value">{newsletterData["newsletterValue"]} PLN</span>
						</li>
						}
						<li className="services-list-total order-list-total">
							<span className="service-name">Łącznie</span>
							<span className="service-value">{servicesTotalValue} PLN</span>
						</li>
					</Row>
				</Col>
				}
				
				{orderedSpecializationsQuantity !== 0 &&
				<Col xs={12} className="order-summary-container order-summary-services">
					<Row as="h5" className="order-list-header">
						<span>Wybrane Specializacje</span>
					</Row>
					<Row as="ul" className="order-services-list order-list">
					{orderedSpecializations.map(position =>
						<li className="services-list-service services-list-newsletter" key={position.positionID}>
							<span className="specialization-province"><strong>Pozycja {position.positionPosition}</strong> - {position.positionLocalizationName}</span>
							<span className="specialization-name">({position.positionSpecializationName})</span>
							<span className="specialization-value">{position.positionPrice} PLN</span>
						</li>
					)}
						<li className="specializations-list-total order-list-total">
							<span className="service-name">Łącznie</span>
							<span className="service-value">{specializationsTotalValue} PLN</span>
						</li>
					</Row>
				</Col>
				}
				
				
				<Col xs={12} className="order-summary-container order-summary-total">
					<Row as="h4" className="summary-total-header">
						<span className="header-label">łącznie do zapłaty</span>
						<span className="header-value">{orderTotalValue} PLN</span>
					</Row>
					<Row className="summary-total-regulations">
						<label className={"summary-total-label" + (regulationsAcceptStatus ? " checked" : "")}>
							<input
								type="checkbox"
								value={1}
								className="summary-total-input"
								onChange={() => setRegulationsAcceptStatus(!regulationsAcceptStatus)}
								disabled={restApiRequestStatus || orderStep === 2}
							/>
							<span>Akceptuję regulamin</span>
						</label>
					</Row>
					{formErrors &&
					<Row className="summary-total-errors">
						<p>{formErrors[0]}</p>
					</Row>
					}
					{orderStep < 2 &&
					<Row className="summary-total-order">
						<Button disabled={restApiRequestStatus || orderStep === 2} variant="primary"
						        className="summary-total-button"
						        onClick={() => orderSubmit(orderTotalValue)}>
							<span>Kupuję i płacę</span>
						</Button>
					</Row>
					}
				</Col>
				
			
			</Row>
		
		</Col>
	);
	
};


export default ShopContentSummaryComponent;
