import React from "react";


export default {
	
	"PRIMARY": {
		page_description: <>
			<p>
				Portal <strong>eksperci-srodowiskowi.pl</strong> jest miejscem skupiającym firmy i
				niezależnych ekspertów z zakresu ochrony środowiska w procesie administracyjnym.
				Znajdziesz tu firmy i ekspertów o różnych specjalizacjach, których wiedza i
				doświadczenie pozwolą Ci zminimalizować ryzyka procesu inwestycyjnego w obszarze ochrony
				środowiska. Wybierając odpowiednią do swoich potrzeb firmę zaoszczędzisz czas i
				pieniądze.
			</p>
			<p>
				Poniżej znajdziesz ogólne informacje proceduralne, które pozwolą Ci zorientować się w
				gąszczu przepisów z zakresu uzyskiwania decyzji o środowiskowych uwarunkowaniach.
			</p>
		</>,
		content_switcher: {
			content_switcher_main_buttons: [
				{id: "faq-base", title: "jak korzystać z portalu?"},
				{id: "knowledge-base", title: "baza wiedzy"}
			],
			content_switcher_secondary_buttons: {
				"companies-kinds": {
					id: "companies-kinds", title: "jakie firmy tu znajdę?",
					content: <>
						<p>
							Portal jest miejscem, gdzie znajdziesz firmy wykonujące raporty o oddziaływaniu na
							środowisko, karty
							informacyjne przedsięwzięć, specjalistyczne analizy z zakresu środowiska, zajmujące się
							sprawozdawczością środowiskową, wykonujące wnioski o pozwolenia środowiskowe, wnioski
							odpadowe, firmy
							doradcze, a także przyrodników zajmujących się inwentaryzacjami przyrodniczymi i wielu
							innych.
						</p>
						<p>
							Każda firma ma swoją wizytówkę zawierającą informacje o lokalizacji, doświadczeniu i
							referencje. Każda
							firma na wizytówce podaje swoje dane kontaktowe dzięki czemu możesz się z nią
							bezpośrednio skontaktować,
							bez żadnych opłat.
						</p>
					</>
				},
				"how-to-choose": {
					id: "how-to-choose", title: "jak wybrać eksperta?",
					content: <>
						<p>
							Firmę możesz wyszukać poprzez jej specjalizację jak i obszar jej działania. W zakładce
							"znajdź eksperta" znajdziesz odpowiednie narzędzie.
						</p>
						<p>
							Wybierając eksperta kieruj się przede wszystkim jego doświadczeniem oraz przy pewnym
							typie opracowań również lokalizacją - nie kieruj się tylko najniższą ceną.
							Lokalni eksperci dobrze znają uwarunkowania lokalnego środowiska i zachodzące w nim
							procesy, co przy pewnych przedsięwzięciach ma spore znaczenie.
							Zapytaj o wycenę kilka firm zanim podejmiesz decyzję o wyborze wykonawcy.
						</p>
						<p>
							Opracowania środowiskowe są podstawą procedowania w urzędach, stanowią bazę informacji o
							przedsięwzięciu dla organu prowadzącego, organów opiniujących i uzgadniających oraz
							społeczeństwa.
							Profesjonalnie wykonana dokumentacja skraca czas procedowania oraz ogranicza liczbę
							potencjalnych problemów, które mogą się wydarzyć “po drodze”, zanim otrzymasz upragnioną
							decyzję/pozwolenie.
						</p>
					</>
				},
				"how-to-send-question": {
					id: "how-to-send-question", title: "jak zadać zapytanie ofertowe?",
					content: <>
						<p>
							Po określeniu w wyszukiwarce znajdującej się na stronie głównej (po kliknięciu w przycisk
							"znajdź eksperta"), pojawi się lista wybranych firm. Na liście jest opcja zaznaczenia
							kilku firm poprzez
							odhaczenie pola “dodaj do zapytania”, pod listą znajduje się pole do wpisania
							wiadomości.
							Wiadomość zostanie automatycznie rozesłana do wybranych firm, a kopia zapytania na Twoją
							skrzynkę mailową.
						</p>
					</>
				},
			}
		}
	},
	
	"SECONDARY": {
	
		knowledge_base_items: [
			
			{
				item_title: "Decyzja o środowiskowych uwarunkowaniach",
				item_content: <>
					<p>
						Decyzja środowiskowa jest pierwszą decyzją, którą potrzebujesz uzyskać, jeśli
						planujesz realizację inwestycji. Wydaje się ją przed złożeniem wniosku o
						pozwolenie na budowę oraz innych decyzji następczych po decyzji środowiskowej.
						Dla każdej inwestycji wydawana jest tylko jedna decyzja środowiskowa. Określa
						ona warunki środowiskowe dotyczące fazy budowy i fazy eksploatacji
						przedsięwzięcia, aby jak najmniej oddziaływało ono na środowisko. W decyzji
						środowiskowej określa się m.in.:
					</p>
					<ul>
						<li>sposoby unikania, zapobiegania, minimalizowania bądź ewentualnej kompensacji
							oddziaływań planowanej inwestycji,
						</li>
						<li>zakres monitoringu przyrodniczego,</li>
						<li>obowiązek wykonania analizy porealizacyjnej,</li>
						<li>konieczność prowadzenia nadzorów środowiskowych.</li>
					</ul>
					<p>
						Warunki realizacji przedsięwzięcia są wiążące dla organów wydających kolejne
						decyzje w procesie inwestycyjnym np. pozwolenie na budowę.
					</p>
					<p>
						<strong>ważne:</strong> Aby decyzja środowiskowa mogła być wydana,
						przedsięwzięcie musi być zgodne z ustaleniami miejscowego planu zagospodarowania
						przestrzennego (jeżeli plan taki został uchwalony). W niektórych sytuacjach
						przepisy dopuszczają odstępstwo od tej zasady (więcej tu <a
						href="https://www.biznes.gov.pl/pl/publikacje/1894-przedsiewziecia-ktore"
						target="_blank" rel="noopener noreferrer">https://www.biznes.gov.pl/pl/publikacje/1894-przedsiewziecia-ktore</a>).
					</p>
				</>
			},
			
			{
				item_title: "Dla jakich inwestycji konieczne jest uzyskanie decyzji o środowiskowych uwarunkowaniach?",
				item_content: <>
					<p>
						Nie dla wszystkich inwestycji musisz otrzymać decyzję o środowiskowych
						uwarunkowaniach, listę przedsięwzięć które jej potrzebują zawiera rozporządzenie
						Rady Ministrów z dnia 10 września 2019 r. w sprawie przedsięwzięć mogących
						znacząco oddziaływać na środowisko.
					</p>
					<p>
						Lista przedsięwzięć zawiera ponad 150 pozycji, znajdują się tam głównie: obiekty
						przemysłowe, obiekty infrastrukturalne, stacje demontażu pojazdów, składowiska
						odpadów, chów i hodowla zwierząt, a także wydobycie/poszukiwanie/rozpoznanie
						kopalin, przedsięwzięcia związane z turystyką, zabudowa mieszkaniowa i usługowa,
						centra handlowe, garaże, parkingi, scalanie gruntów, wylesienia i in.
					</p>
					<p>
						Przedsięwzięcia znajdujące się na liście są podzielone na dwie grupy
					</p>
					<ul>
						<li>grupa pierwsza - przedsięwzięcia zawsze znacząco oddziałujące na
							środowisko
						</li>
						<li>grupa druga - przedsięwzięcia potencjalnie znacząco oddziałujące na
							środowisko
						</li>
					</ul>
					<p>
						Dla przedsięwzięć z pierwszej grupy obowiązkowo przeprowadza się ocenę
						oddziaływania na środowisko (<a href="#enviroment-rate" title="Ocenę oddziaływania na środowisko" className="anchorLink">zobacz tutaj</a>).
					</p>
					<p>
						Również przedsięwzięcia obejmujące rozbudowę, przebudowę lub montażu wymienionych
						na liście przedsięwzięć, mogą wymagać uzyskania decyzji środowiskowej.
					</p>
				</>
			},
			
			{
				item_title: "Kto wydaje decyzję o środowiskowych uwarunkowaniach?",
				item_content: <>
					<p>W zależności od rodzaju przedsięwzięcia:</p>
					<ul>
						<li>starostwo powiatowe</li>
						<li>wójt, burmistrz, prezydent miasta</li>
						<li>regionalna dyrekcja ochrony środowiska</li>
						<li>regionalna dyrekcja lasów państwowych</li>
						<li>Generalna Dyrekcja Ochrony Środowiska</li>
					</ul>
				</>
			},
			
			{
				item_title: "Ile czasu zajmuje procedura uzyskiwania decyzji o środowiskowych uwarunkowaniach?",
				item_content: <>
					<p>
						Kilka do kilkunastu miesięcy. To ile czasu będziesz czekał zależy w dużej mierze
						od jakości karty informacyjnej przedsięwzięcia lub raportu o oddziaływaniu na
						środowisko oraz od sprawności procedowania w urzędzie. Jeśli nie masz
						wystarczających kompetencji oraz czasu na “monitorowanie” procedury w urzędzie
						zastanów się nad ustanowieniem pełnomocnika inwestora (<a href="#sub-investor-rate" className="anchorLink">zobacz tutaj</a>)
					</p>
				</>
			},
			
			{
				item_title: "Z czego składa się wniosek o decyzję o środowiskowych uwarunkowaniach?",
				item_content: <>
					<p>
						Napisanie wniosku o decyzję o środowiskowych uwarunkowaniach najlepiej powierzyć
						specjalistom, gdyż to co się znajdzie we wniosku będzie miało swoje odbicie w
						decyzji i określonych w niej warunkach realizacji przedsięwzięcia, a w
						konsekwencji będzie wiążące dla organów wydających kolejne decyzje np.
						pozwolenie na budowę.
					</p>
					<p>
						Wniosek składa się z tekstu wniosku oraz kilku załączników (m.in. wypisów z
						rejestru gruntów, kopii map ewidencyjnych, mapy poglądowej z zasięgiem
						oddziaływania innych). Jednym z załączników jest raport o oddziaływaniu na
						środowisko lub karta informacyjna przedsięwzięcia.
					</p>
				</>
			},
			
			{
				item_title: "Kiedy karta informacyjna przedsięwzięcia (KIP), a kiedy raport?",
				item_content: <>
					<p>
						Karta informacyjna przedsięwzięcia jest opracowaniem zawierającym opis
						planowanej inwestycji wraz z podstawowymi informacjami na temat jej
						oddziaływania na środowisko. Raport jest opracowaniem bardziej złożonym,
						zawierającym analizy przyrodnicze oraz odpowiednie dla danej inwestycji analizy
						specjalistyczne np. akustyczne.
					</p>
					<p>
						Dla przedsięwzięć mogących zawsze znacząco oddziaływać na środowisko (<a href="http://prawo.sejm.gov.pl/isap.nsf/DocDetails.xsp?id=WDU20190001839" target="_blank" rel="noopener noreferrer">http://prawo.sejm.gov.pl/isap.nsf/DocDetails.xsp?id=WDU20190001839</a>),
						przygotowanie raportu o oddziaływaniu na środowisko jest obowiązkowe - możliwie dwie ścieżki postępowania:
					</p>
					<ul>
						<li>raport składa się odrazu z wnioskiem o decyzję środowiskową, jako jeden z jego załączników,</li>
						<li>jako załącznik do wniosku o decyzję środowiskową składa się kartę informacyjną przedsięwzięcia oraz wniosek o ustalenie zakresu raportu.</li>
					</ul>
					<p>
						Dla przedsięwzięć mogących potencjalnie znacząco oddziaływać na środowisko
						(druga grupa z rozporządzenia-LINK do rozporządzenia jak już będzie nowe w
						necie) wraz z wnioskiem o decyzję środowiskową składa się kartę informacyjną
						przedsięwzięcia. Urząd decyduje, czy do wydania decyzji środowiskowej potrzebne
						jest również sporządzenie raportu o oddziaływaniu na środowisko, czy też
						wystarczą informacje zawarte w karcie informacyjnej przedsięwzięcia. Jeśli urząd
						stwierdzi, że przygotowanie raportu i przeprowadzenie pełnej oceny oddziaływania
						na środowisko jest konieczne, wówczas zawiesza postępowanie do czasu aż inwestor
						przedstawi raport.
					</p>
				</>
			},
			
			{
				item_title: "Czy mogę jakoś pomóc losowi, aby organ nie nałożył obowiązku raportu?",
				item_content: <>
					<p>
						Dla przedsięwzięć mogących potencjalnie znacząco oddziaływać na środowisko (<a href="http://prawo.sejm.gov.pl/isap.nsf/DocDetails.xsp?id=WDU20190001839" target="_blank" rel="noopener noreferrer">http://prawo.sejm.gov.pl/isap.nsf/DocDetails.xsp?id=WDU20190001839</a>),
						urząd decyduje czy do wydania decyzji środowiskowej wystarczy karta informacyjna
						przedsięwzięcia, czy potrzebne jest sporządzenie raportu o oddziaływaniu na środowisko.
					</p>
					<p>
						Aby zwiększyć szanse inwestora na to, że urząd wyda decyzję środowiskową na
						podstawie karty informacyjnej przedsięwzięcia i nie będzie żądał sporządzenia
						raportu (o czym marzy zdecydowana większość inwestorów ponieważ konieczność
						wykonania raportu o oddziaływaniu na środowisko wiąże się z dodatkowymi kosztami
						oraz czasem), warto zastanowić się nad wykonaniem rozszerzonej karty
						informacyjnej przedsięwzięcia. Takie rozwiązanie znacznie zwiększa szanse na
						otrzymanie decyzji środowiskowej bez konieczności sporządzania raportu.
					</p>
					<p>
						Uzyskanie decyzji środowiskowej na podstawie karty informacyjnej pozwala zaoszczędzić pieniądze i czas.
					</p>
				</>
			},
			
			{
				item_title: "Co to jest ocena oddziaływania na środowisko?",
				item_content: <>
					<p>
						Obowiązek sporządzenia raportu o oddziaływaniu na środowisko jest jednoznaczny z
						przeprowadzeniem przez organ pełnej oceny oddziaływania na środowisko, która
						obejmuje:
					</p>
					<ul>
						<li>weryfikację raportu przez organ prowadzący procedurę - <strong><i>co w praktyce oznacza możliwość zadawania pytań, żądania wyjaśnień oraz uzupełnień</i></strong>,</li>
						<li>
							uzyskanie wymaganych opinii i uzgodnień od innych organów m.in. regionalnego dyrektora ochrony środowiska, Państwowej Inspekcji Sanitarnej, organu
							wydającego pozwolenie zintegrowane, dyrektora urzędu morskiego, organu właściwego do ocen wodnoprawnych - <strong><i>co w praktyce oznacza
							wydłużenie czasu procedowania oraz możliwość zadawania przez te organy pytań do raportu, żądania wyjaśnień i uzupełnień</i></strong>.
						</li>
						<li>zapewnienie możliwości udziału społeczeństwa w postępowaniu – konsultacje społeczne trwają 30 dni.</li>
					</ul>
					<p>
						Pełną ocenę oddziaływania na środowisko przeprowadza się, aby określić możliwe
						oddziaływania przedsięwzięcia na środowisko, zaplanować możliwości unikania,
						łagodzenia lub ewentualnej kompensacji negatywnych oddziaływań. Podstawą
						informacji w procesie oceny oddziaływania na środowisko jest raport o oddziaływaniu na środowisko.
					</p>
				</>
			},
			
			{
				item_title: "Kto może być autorem karty lub raportu i jak go wybrać?",
				item_content: <>
					<p>
						Nie ma wymagań dotyczących autora karty informacyjnej, natomiast autorem raportu
						o oddziaływaniu na środowisko może być tylko osoba z odpowiednim wykształceniem i doświadczeniem.
					</p>
					<p>
						W praktyce kiedy chcesz zlecić wykonanie karty lub raportu najlepiej zlecić to
						firmie, która ma <strong>doświadczenie</strong> w sporządzaniu opracowań
						środowiskowych dla konkretnego rodzaju przedsięwzięć oraz ma wiedzę o <strong>lokalnym środowisku</strong>.
						Do określenia oddziaływań na środowisko z jednej strony konieczne jest
						posiadanie wiedzy o środowisku i zachodzących w nim procesach, a z drugiej
						rozumienie procesów technologicznych i charakterystyki przedsięwzięcia będącego
						przedmiotem oceny. Inne są bowiem oddziaływania linii energetycznej, elektrowni
						wiatrowej, czy chlewni. Jeżeli realizujesz inwestycję na północy Polski warto
						wybrać firmę z północy Polski, ponieważ środowisko i jego problemy nie są
						jednakowe w całym kraju, inne problemy występują np. na Żuławach, a inne na
						terenach górskich. Eksperci zazwyczaj mają lepsze wyczucie lokalnego środowiska
						i procesów w nim zachodzących, dlatego łatwiej będzie im ocenić jak Twoja
						inwestycja wpłynie na lokalne środowisko i zachodzące w nim procesy.
					</p>
				</>
			},
			
			{
				item_title: "Dlaczego warto zapłacić więcej za raport/kartę informacyjną dobrej jakości?",
				item_content: <>
					<p>
						Ponieważ w konsekwencji zaoszczędzisz czas, pieniądze i swoje nerwy.
					</p>
					<p>
						Procedura wydawania decyzji o środowiskowych uwarunkowaniach jest procesem w
						którym zachodzą interakcje między inwestorem, urzędem prowadzącym postępowanie,
						a także kilkoma innymi urzędami opiniującymi i uzgadniającymi oraz
						społeczeństwem. Wszystkie te “jednostki” wypowiadają się w sprawie oddziaływania
						Twojej inwestycji na środowisko, a skąd czerpią o niej wiedzę? - z karty
						informacyjnej lub raportu. Jeśli karta lub raport będą napisane w sposób nie do
						końca profesjonalny, z niejasnościami i błędami wówczas stwarza to sytuację,
						kiedy autor raportu otrzyma zapytania, wezwania do wyjaśnień i uzupełnień.
						Koszty dodatkowych analiz ponosi inwestor, czas który jest poświęcony na
						wyjaśnienia/uzupełnienia/dodatkowe analizy - traci Inwestor.
					</p>
					<p>
						Jeśli zlecisz wykonanie opracowania firmie z odpowiednim doświadczeniem,
						znającej lokalne uwarunkowania środowiskowe, wówczas zwiększasz swoje szanse na
						sprawne procedowanie i minimalizujesz ryzyko wydłużenia czasu oczekiwania na
						decyzję środowiskową (w zależności od sprawności urzędu i stopnia skomplikowania
						sprawy czas ten wynosi od kilku do kilkunastu miesięcy).
					</p>
				</>
			},
			
			{
				item_title: "Jaka jest rola pełnomocnika inwestora w procesie pozyskiwania DŚU?",
				item_content: <>
					<p>
						Jeżeli nie masz czasu ani kompetencji, by samemu kontaktować się z urzędem
						prowadzącym postępowanie w sprawie wydania decyzji o środowiskowych
						uwarunkowaniach - zleć tą pracę pełnomocnikowi. Pełnomocnik jest osobą
						działającą w imieniu i na rzecz przedsiębiorcy. Pełnomocnik w imieniu inwestora
						składa wniosek o decyzję środowiskową, odpowiada na pytania urzędu, składa
						wyjaśnienia, jest w bieżącym kontakcie z urzędnikiem prowadzącym postępowanie. W
						praktyce najczęściej firma opracowująca kartę informacyjną lub raport pełni
						również rolę pełnomocnika.
					</p>
				</>
			},
			
			{
				item_title: "Na czym polega udział społeczny w ocenie oddziaływania na środowisko?",
				item_content: <>
					<p>
						Zawsze kiedy w postępowaniu konieczne jest sporządzenie raportu o oddziaływaniu
						na środowisko urząd ma obowiązek przeprowadzenia konsultacji społecznych. Jest
						to czas (30 dni) kiedy każdy, bez obowiązku wykazywania interesu prawnego i
						posiadania prawa strony, może zapoznać się z dokumentacją sprawy oraz złożyć
						swoje uwagi i wnioski. Organ prowadzący ma obowiązek odnieść się do wszystkich
						uwag i wniosków, a w uzasadnieniu decyzji jest zobowiązany poinformować w jaki
						sposób wziął je pod uwagę. Organizacje ekologiczne posiadają prawo strony, co
						oznacza że w każdym momencie trwania procedury posiadają prawo wglądu do akt
						sprawy oraz mają prawo odwołania od decyzji.
					</p>
				</>
			},
			
			{
				item_title: "Kiedy ponowna ocena oddziaływania na środowisko?",
				item_content: <>
					<p>
						Ponowną ocenę oddziaływania na środowisko wykonuje się na etapie wydawania
						niektórych decyzji następczych po decyzji środowiskowej np. pozwolenia na budowę
						w m.in. następujących przypadkach:
					</p>
					<ul>
						<li>na wniosek inwestora,</li>
						<li>jeżeli we wniosku np. o wydanie pozwolenia na budowę zaszły zmiany w przedsięwzięciu w stosunku do decyzji środowiskowej,</li>
						<li>kiedy obowiązek jej wykonania został nałożony w decyzji środowiskowej.</li>
					</ul>
					<p>
						Kiedy złożysz wniosek o wydanie lub zmianę pozwolenia na budowę, urząd oceni czy
						wnioskowane przedsięwzięcie jest zgodne z ustaleniami decyzji środowiskowej.
						Jeżeli z oceny wyniknie, że nie jest - wówczas urząd oceni zakres niezgodności i
						konieczne może być przeprowadzenie ponownej oceny oddziaływania na środowisko
						(zmiana istniejącej decyzji środowiskowej, bądź uzyskanie nowej). Jeżeli urząd
						nałożył obowiązek wykonania ponownej oceny oddziaływania na środowisko,
						konieczne będzie wykonanie nowego raportu. Nowy raport o oddziaływaniu na
						środowisko wykonywany na etapie ponownej oceny ma rozszerzony zakres treści w
						porównaniu z raportem wykonywanym na etapie “pierwszej” oceny.
					</p>
					<p>
						* więcej dowiesz sie tu <a href="https://www.biznes.gov.pl/pl/publikacje/3455-ponowna-ocena-oddzialywania-na-srodowisko-przy-wydawaniu-pozwolenia-na-budowe">https://www.biznes.gov.pl/pl/publikacje/3455-ponowna-ocena-oddzialywania-na-srodowisko-przy-wydawaniu-pozwolenia-na-budowe</a>
					</p>
				</>
			},
			
			{
				item_title: "Kiedy konieczne jest wykonanie inwentaryzacji przyrodniczej i na czym polega?",
				item_content: <>
					<p>
						Inwentaryzacja przyrodnicza to zbiór badań terenowych przeprowadzonych na
						potrzeby scharakteryzowania elementów środowiska przyrodniczego. Badania
						terenowe trwają rok i obejmują: ptaki, ssaki (w tym nietoperze), płazy, gady,
						szatę roślinną, siedliska, grzyby, porosty, bezkręgowce i ryby. Raport z
						inwentaryzacji zawiera informacje o wszystkich zinwentaryzowanych gatunkach
						roślin i siedliskach, z wydzieleniem gatunków i siedlisk chronionych. Zawiera
						także informacje o formach ochrony przyrody i korytarzach ekologicznych.
					</p>
					<p>
						Inwentaryzację przyrodniczą wykonuje się zawsze, kiedy przeprowadzana jest ocena
						oddziaływania na środowisko (raport) i stanowi załącznik do raportu.
					</p>
					<p>
						Do karty informacyjnej przedsięwzięcia nie ma obowiązku dołączania wyników
						inwentaryzacji przyrodniczej, ale warto to rozważyć jeśli wykonujemy rozszerzoną
						kartę informacyjną.
					</p>
				</>
			},
			
			// {
			// 	item_title: "",
			// 	item_content: <>
			//
			// 	</>
			// },
			
		]
		
	}
	
}