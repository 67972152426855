import React from "react";


export const SecondaryHeader = ({headerText = null}) => {
	
	if (!headerText) {
		return null;
	}
	
	return (
		
		<h2 className="secondary-header">
			<span>{headerText}</span>
		</h2>
	
	);
	
};


export const SectionHeader = ({headerText = null}) => {
	
	if (!headerText) {
		return null;
	}
	
	return (
		
		<h4 className="section-header">
			<span>{headerText}</span>
		</h4>
		
	);
	
};


export default {
	SectionHeader
}