import React from "react";


import {Col, Row} from "react-bootstrap";

import {RiDeleteBinLine} from "react-icons/all";


const PRICES = {
	country: {
		1: {standard: 480, premium: 400},
		2: {standard: 400, premium: 320},
		3: {standard: 320, premium: 260},
		4: {standard: 240, premium: 200},
		5: {standard: 160, premium: 140},
	},
	province: {
		1: {standard: 240, premium: 200},
		2: {standard: 200, premium: 160},
		3: {standard: 160, premium: 130},
		4: {standard: 120, premium: 100},
		5: {standard: 80, premium: 70},
	}
};


export const ShopContentSpecializationsListItemComponent = ({premiumPositionData, orderNr, premiumPositionRemoveHandler, expertAccountType}) => {
	
	
	const {
		positionID,
		positionPosition,
		positionSpecializationName,
		positionLocalization, positionLocalizationName
	} = premiumPositionData;
	
	
	return (
		<Col as="li" xs={12} className="specializations-list-item">
			<Row className="specializations-list-item-content">
			
				<Col xs={1} className="position-cell position-order-number">
					<Row className="position-cell-content">
						<span>{orderNr}</span>
					</Row>
				</Col>
				
				<Col xs={4} className="position-cell position-specialization">
					<Row className="position-cell-content">
						<span>{positionSpecializationName}</span>
					</Row>
				</Col>
				
				<Col xs={2} className="position-cell position-localization">
					<Row className="position-cell-content">
						<span>{positionLocalizationName}</span>
					</Row>
				</Col>
				
				<Col xs={2} className="position-cell position-position">
					<Row className="position-cell-content">
						<span>pozycja {positionPosition}</span>
					</Row>
				</Col>
				
				<Col xs={1} className={"position-cell position-price standard-price" + (+expertAccountType === 1 ? " active" : "")}>
					<Row className="position-cell-content">
						<label>Standard</label>
						<span>{PRICES[+positionLocalization === 17 ? "country" : "province"][positionPosition].standard} zł</span>
					</Row>
				</Col>
				
				<Col xs={1} className={"position-cell position-price premium-price" + (+expertAccountType === 2 ? " active" : "")}>
					<Row className="position-cell-content">
						<label>Premium</label>
						<span>{PRICES[+positionLocalization === 17 ? "country" : "province"][positionPosition].premium} zł</span>
					</Row>
				</Col>
				
				<Col xs={1} className="position-cell position-remove">
					<Row className="position-cell-content">
						<a title="Usuń" onClick={e => {e.preventDefault(); premiumPositionRemoveHandler(positionID)}}>
							<RiDeleteBinLine />
						</a>
					</Row>
				</Col>
			
			</Row>
		</Col>
	);
	
};


export default ShopContentSpecializationsListItemComponent;
