import React, {Component} from "react";


import {Button, Col, Form} from "react-bootstrap";

import ReactTooltip from "react-tooltip";


export class PolandMap extends Component {
	
	
		#provincesIDs = [...Array(16).keys()].map(i => i + 1);
		
		#provincesZones = [
			{provinceID: 1, provinceZones: 38, provinceName: "dolnośląskie"},
			{provinceID: 2, provinceZones: 37, provinceName: "kujawsko-pomorskie"},
			{provinceID: 3, provinceZones: 35, provinceName: "lubelskie"},
			{provinceID: 4, provinceZones: 30, provinceName: "lubuskie"},
			{provinceID: 5, provinceZones: 34, provinceName: "łódzkie"},
			{provinceID: 6, provinceZones: 24, provinceName: "małopolskie"},
			{provinceID: 7, provinceZones: 55, provinceName: "mazowieckie"},
			{provinceID: 8, provinceZones: 24, provinceName: "opolskie"},
			{provinceID: 9, provinceZones: 32, provinceName: "podkarpackie"},
			{provinceID: 10, provinceZones: 33, provinceName: "podlaskie"},
			{provinceID: 11, provinceZones: 38, provinceName: "pomorskie"},
			{provinceID: 12, provinceZones: 31, provinceName: "śląskie"},
			{provinceID: 13, provinceZones: 28, provinceName: "świętokrzyskie"},
			{provinceID: 14, provinceZones: 37, provinceName: "warmińsko-mazurskie"},
			{provinceID: 15, provinceZones: 50, provinceName: "wielkopolskie"},
			{provinceID: 16, provinceZones: 38, provinceName: "zachodniopomorskie"}
		];

		
		state = {
			selectedProvince: 0
		};
		
		
		#prepareProvincesList = () => {
			
			const selectedProvince = this.state.selectedProvince;
			
			return this.#provincesZones.map(province =>
				<li
					key={province.provinceID}
				    onClick={(e) => this.handleProvinceSelect(e, province.provinceID, province.provinceName)}
					title={province.provinceName}
					data-tip={province.provinceName}
				    className={`pl${province.provinceID} ${selectedProvince === province.provinceID || selectedProvince === 17 ? "active-region" : ""}`}
				>
					<span className="m">
						{this.#prepareProvincesZonesList(province.provinceZones)}
					</span>
					<span className="bg"></span>
					<ReactTooltip place="top" type="success" effect="float" />
				</li>
			);
			
		};
		
		
		handleProvinceSelect = (e, provinceID, provinceName) => {
			this.setState({
				selectedProvince: provinceID,
				selectedProvinceName: provinceName,
			});
			this.props.updateProvinceNotifier(provinceID, provinceName);
		};
		
		
		handleWholeCountrySelect = (e) => {
			this.setState({
				selectedProvince: e.target.checked ? 17 : null
			});
			this.props.updateProvinceNotifier(e.target.checked ? 17 : null, "Cała Polska");
		};
	
	
		#prepareProvincesZonesList = (zonesNumber) => {
			return [...Array(zonesNumber).keys()].map(i =>
				<span key={i} className={`s${i + 1}`}></span>
			)
		};
	
	
		render() {
			
			const selectedProvince = this.state.selectedProvince;
			
			return (
				<>
					<Form.Group className="select-whole-country" controlId="select-whole-country">
						<Form.Check
							type="checkbox"
							name="whole-country"
							value="Cała Polska"
							onChange={this.handleWholeCountrySelect}
						/>
						<Form.Label className={this.state.selectedProvince === 17 ? "checked-element" : ""}>Cała Polska</Form.Label>
					</Form.Group>
					
					<div className="poland-country-map cssmap-container cssmap-430">
						<ul className="poland cssmap">
							{this.#prepareProvincesList()}
						</ul>
						<Form.Control type="hidden" name="selected_region" value={selectedProvince} />
					</div>
					
					<Col xs={12} className="find-expert-form-submit">
						<Button variant="primary" onClick={e => this.props.submitFindExpertForm(e)}>Szukaj</Button>
					</Col>
					
				</>
			);
			
		}
	
		
}


		// class Class {
		//
		// }
