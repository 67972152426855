import React, {useContext, useEffect, useRef, useState} from "react";


import {UI as UIContext} from "@Contexts";


import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import {PropagateLoader} from "react-spinners";


import {SecondaryHeader} from "@Components/Emelents/Headers";
import {ScrollToDomElement} from "@Components/_UI/Animations/Scrolling";


import {PolandMap} from "@Components/Content/Pages/FindExpert/Map";
import "./_Map/cssmap-poland.css";


import FindExpertResultsComponent from "@Components/Content/Pages/FindExpert/Results";
import SpecializationsList from "@Components/Content/Pages/FindExpert/Specializations";



export const FindExpertPage = () => {
	
	
	const UI = useContext(UIContext);
	const MAIN_API_URL = UI["URLS"]["API"]["REST_API_MAIN_URL"];
	const themeDetails = UI["themeDetails"];
	
	
	const searchPageContainer = useRef(null);
	
	
	const findExpertFormData = JSON.parse(localStorage.getItem("findExpertFormData"));
	
	
	const [formSubmitStatus, setFormSubmitStatus] = useState(false);
	const [sendQuestionRequestStatus, setSendQuestionRequestStatus] = useState(false);
	const [sendQuestionStatus, setSendQuestionStatus] = useState(false);
	
	const [formMessage, setFormMessage] = useState(null);
	
	const [selectedSpecialization, setSelectedSpecialization] = useState(0);
	const [selectedSpecializationName, setSelectedSpecializationName] = useState("");
	// const [expertSpecializations, setExpertSpecializations] = useState(null);
	const [selectedProvince, setSelectedProvince] = useState(0);
	const [selectedProvinceName, setSelectedProvinceName] = useState("");
	// const [expertProvinces, setExpertProvinces] = useState(null);

	const [searchResults, setSearchResults] = useState(null);
	
	
	const submitFindExpertForm = (e) => {
		
		e.preventDefault();
		
		const formErrors = [];
		
		if (!selectedSpecialization || +selectedSpecialization === 0) {
			formErrors.push("Nie Wybrano Specjalizacji");
		}
		
		if (!selectedProvince || +selectedProvince === 0) {
			formErrors.push("Nie Wybrano Regionu");
		}
		
		const formMessage = {
			messageContent: formErrors.length > 0 ?
				<Alert variant="danger" className="find-expert-form-errors">
					<h4>Wystąpiły Błędy</h4>
					{formErrors.map((error, i) =>
						<p key={i} className="error-message-content">{error}</p>
					)}
				</Alert>
				:
				<Alert variant="success" className="find-expert-form-success">
					<h4>Trwa Wyszukiwanie</h4>
					<PropagateLoader
						color={themeDetails.specialColors.primary}
						css={`display: inline-block; min-height: 30px;`}
					/>
				</Alert>
		};
		
		setFormMessage(formMessage.messageContent);
		setFormSubmitStatus(!(formErrors.length > 0));
		
		if (formErrors.length > 0) {
			return false;
		}
		
		getFindExpertsSearchResults();
	};
	
	
	const getFindExpertsSearchResults = async () => {
		
		const experts = await fetch(`${MAIN_API_URL}/experts/find-experts/?filters=specialization:${selectedSpecialization}|province:${selectedProvince}`)
			.then(response => response.ok ? response.json() : {error: true, message: response.text()})
			.then(resource => resource);
		
		const premiumList = [];
		const defaultList = [];
		
		for (let expert of experts) {
			
			if (!expert["expert_active_status"]) {
				continue;
			}
			
			const {expert_premium_positions: expertPremiumPositions} = expert;

			const expertPremiumPositionsList = expertPremiumPositions.filter(position =>
				+position.experts_premium_position_specialization_id === +selectedSpecialization
				&&
				+position.experts_premium_position_province_id === +selectedProvince
			);
			
			if (expertPremiumPositionsList.length > 0) {
				expert.premiumPositionNumber = expertPremiumPositionsList[0]["experts_premium_position_position"];
				premiumList.push(expert);
			}
			else {
				defaultList.push(expert);
			}
			
		}
		
		premiumList.sort(function(a,b) {
			return (a.premiumPositionNumber > b.premiumPositionNumber) ? 1 : (a.premiumPositionNumber < b.premiumPositionNumber) ? -1 : 0;
		});
		
		defaultList.sort(() => Math.random() - 0.5);
		
		setSearchResults(
			{premiumList: premiumList, defaultList: defaultList}
		);
		
		setFormSubmitStatus(false);
		setFormMessage(null);
		
	};
	
	
	
	useEffect(
		() => {
			ScrollToDomElement(searchPageContainer, 500);
		},
		[formMessage, sendQuestionRequestStatus, sendQuestionStatus]
	);
	
	
	const updateSelectedProvince = (provinceId, provinceName) => {
		setSelectedProvince(provinceId);
		setSelectedProvinceName(provinceName);
	};
	
	
	const updateSelectedSpecialization = (specializationId, specializationName) => {
		setSelectedSpecializationName(selectedSpecialization === specializationId ? "" : specializationName);
		setSelectedSpecialization(selectedSpecialization === specializationId ? 0 : specializationId);
	};
	
	
	const sendQuestionHandler = (formData) => {
		setSendQuestionRequestStatus(true);
		setTimeout(() => {
			setSendQuestionStatus(true);
			setSendQuestionRequestStatus(false);
		}, 2000)
	};
	
	
	return (
		
		<Container id="site-primary-content">
			<Container id="site-primary-content-container" ref={searchPageContainer}>
				
				{formMessage}
				
				{sendQuestionRequestStatus &&
				<Alert variant="success" className="find-expert-form-success">
					<h4>Trwa Wysyłanie Zapytania</h4>
					<PropagateLoader
						color={themeDetails.specialColors.primary}
						css={`display: inline-block; min-height: 30px;`}
					/>
				</Alert>
				}
				
				{sendQuestionStatus &&
				<Alert variant="success" className="find-expert-form-success" style={{paddingBottom: 0}}>
					<h4>Dziękujemy za wysłanie Zapytania</h4>
				</Alert>
				}
				
				{!sendQuestionStatus && !sendQuestionRequestStatus && !formSubmitStatus && !searchResults &&
				<Row
					as={Form}
					method="POST"
					id="find-expert-form"
					onSubmit={e => submitFindExpertForm(e)}
					className="main-container-content main-content"
				>
					
					<Col xs={12} md={6} id="specializations-list" className="find-expert-specializations">
						<SecondaryHeader headerText="Wybierz Kategorię Zlecenia"/>
						<SpecializationsList updateSpecializationNotifier={updateSelectedSpecialization}/>
					</Col>
					
					<Col xs={12} md={6} id="provinces-list" className="find-expert-regions-map">
						<SecondaryHeader headerText="Wybierz Lokalizację"/>
						<PolandMap updateProvinceNotifier={updateSelectedProvince} submitFindExpertForm={submitFindExpertForm}/>
					</Col>
				
				</Row>
				}
				
				{!sendQuestionStatus && !sendQuestionRequestStatus && searchResults &&
				<FindExpertResultsComponent
					searchData={
						{selectedSpecialization, selectedSpecializationName, selectedProvince, selectedProvinceName}
					}
					premiumList={searchResults.premiumList}
					defaultList={searchResults.defaultList}
					sendQuestionHandler={sendQuestionHandler}
				/>
				}
			
			</Container>
		</Container>
		
	);
	
};


export default FindExpertPage;
