import React, {useState} from "react";


import {Col, Form, Row} from "react-bootstrap";

import {BsCheckCircle, FaBan, FaEdit} from "react-icons/all";


export const ExpertSingleDataField = ({fieldData, fieldValue, editAble, REST_API_EXPERT_URL}) => {
	
	
	const {fieldType, fieldLabel, fieldName} = fieldData;
	
	const [fieldEditStatus, fieldEditStatusToggle] = useState(false);
	const [fieldActualValue, changeFieldActualValue] = useState(fieldValue || "");
	
	
	const updateExpertFieldValue = (fieldName, fieldValue) => {
		fetch(REST_API_EXPERT_URL, {
			headers: {'Accept': 'application/json, text/plain', 'Content-Type': 'application/json;charset=UTF-8'},
			method: "PATCH", body: JSON.stringify({[fieldName]: fieldValue})
		})
			.then(response => response.ok ? response.text() : {error: true, code: response.text()})
			.then(resource => resource)
			.catch(e => console.log(e));
	};
	
	
	return (
		
		<Row as={Form.Group} controlId={fieldName.replace(/_/gi, "-")}>
			
			<Col xs={12} md={4} className="form-group-label">
				<Form.Label>{fieldLabel}:</Form.Label>
			</Col>
			
			<Col xs={10} md={6} className="form-group-field">
				<Form.Control
					type={fieldType ? fieldType : "text"}
					name={fieldName}
					value={fieldActualValue}
					required
					disabled={fieldEditStatus ? false : true}
					onChange={(e) => changeFieldActualValue(e.target.value)}
					readOnly={editAble}
				/>
			</Col>
			
			<Col xs={2} className="form-group-actions">
				
				{!fieldEditStatus && !editAble &&
				<FaEdit title="Edytuj" className="field-edit" onClick={() => fieldEditStatusToggle(true)} />
				}
				
				{fieldEditStatus && !editAble &&
				<>
					<FaBan title="Edytuj" className="abort-field-edit"
					       onClick={() => fieldEditStatusToggle(false)}
					/>
					<BsCheckCircle title="Zapisz" className="field-save"
					               onClick={() => {
						               updateExpertFieldValue(fieldName, fieldActualValue);
						               fieldEditStatusToggle(false);
					               }}
					/>
				</>
				}
			</Col>
		
		</Row>
	
	);
	
};


export default ExpertSingleDataField;
