import {LOG_USER_IN, LOG_USER_OUT} from "@Store/Actions/Authentication";


const initialState = {
	authenticatedUserData: JSON.parse(localStorage.getItem("loggedEnvironmentalExpert"))
};


export const AuthenticationReducer = (state = initialState, action) => {
	
	switch (action.type) {
		case LOG_USER_IN:
			localStorage.setItem("loggedEnvironmentalExpert", JSON.stringify(action.authenticatedUserData));
			return {...state, authenticatedUserData: action.authenticatedUserData};
		case LOG_USER_OUT:
			localStorage.removeItem("loggedEnvironmentalExpert");
			return  {...state, authenticatedUserData: null};
		default:
			break
	}
	
	return state;
	
};


export default AuthenticationReducer;