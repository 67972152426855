import React, {Component} from "react";
import {connect as CONNECT} from "react-redux";


import {Route} from "react-router-dom";


import {
	SiteHeader,
	SiteBanner,
	SiteMainContent,
	SiteNewsletterSection,
	SiteFooter,
	SiteCopyRights
} from "@Components/Containers";


export class Application extends Component {
	
	
		state = {
			applicationLoadedStatus: false
		};
	
	
		render() {

			return (
				
				<>
					
					<Route path="/" component={(props) => <SiteHeader {...props} />} />
					<Route path="/" component={(props) => <SiteBanner {...props} />} />
					
					
					<Route path="/" component={(props) => <SiteMainContent {...props} />} />
					
					
					<Route path="/" component={(props) => <SiteNewsletterSection {...props} />} />
					<Route path="/" component={(props) => <SiteFooter {...props} />} />
					<Route path="/" component={(props) => <SiteCopyRights {...props} />} />
					
				</>
				
			);
			
		}
	

}


export default CONNECT(null, null)(Application);
