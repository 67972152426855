import React, {useState} from "react";


import {Col, Row} from "react-bootstrap";


import {Slide} from "@material-ui/core";


export const ExpertReferencesGroupComponent = ({referenceGroupName, referenceGroupReferences, environmentalExpertID, removeReferenceFileHandler}) => {
	
	
	return (
		<Row>
			
			<Col as="h5" xs={12} className="references-group-header">
				<span>{referenceGroupName}</span>
			</Col>
			
			{referenceGroupReferences.map((referenceData, index) =>
				<ExpertReferencesReferenceComponent
					key={index}
					referenceData={referenceData}
					environmentalExpertID={environmentalExpertID}
					removeReferenceFileHandler={removeReferenceFileHandler}
				/>
			)}
		
		</Row>
	);
	
};


		const ExpertReferencesReferenceComponent = ({referenceData, removeReferenceFileHandler}) => {
			
			
			const {
				id: referenceID,
				expert_reference_title: referenceTitle,
				expert_reference_file: referenceFileURL
				
			} = referenceData;
			
			
			const [referenceFileRemoveStatus, setReferenceFileRemoveStatus] = useState(false);
			
			
			return (
				<Slide
					in={true}
					direction="down"
					timeout={{enter: 1000}}
					style={{transformOrigin: "left top", transform: "scale(1, 0.01) translate(0, 0)"}}
					mountOnEnter
					unmountOnExit
				>
					<Col xs={12} md={4} className="expert-reference-file">
						<Col xs={12} className="reference-file-content">
							<h5 className="reference-file-name">{referenceTitle}</h5>
							<a
								href={referenceFileURL}
								title={referenceTitle}
								className="reference-file-url"
								target="_blank"
								rel="noopener noreferrer"
							>
								<span>Zobacz plik</span>
							</a>
							<span
								className={(referenceFileRemoveStatus ? "active " : "") + "delete-reference-file"}
								onClick={() => {
									if (referenceFileRemoveStatus === true) {
										setReferenceFileRemoveStatus(false);
										removeReferenceFileHandler(referenceID);
										return;
									}
									setReferenceFileRemoveStatus(true);
								}}
							>
								{referenceFileRemoveStatus ? "Potwierdź usunięcie" : "Usuń"}
							</span>
						</Col>
					</Col>
				</Slide>
			);
			
			
		};
		
		
export default ExpertReferencesGroupComponent;
