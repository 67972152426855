import React, {useState} from "react";


import {Alert, Col, Row} from "react-bootstrap";


import {ProfileSubSectionHeader} from "@Components/Content/User/Logged/Profile/_Elements/Headers";


import ExpertDigitalLibraryFileComponent from "@Components/Content/User/Logged/Library/Expert/List/File";


export const ExpertDigitalLibraryFilesComponent = ({digitalLibraryFiles, removedLibraryFileHandler}) => {
	
	
	const expertLibraryFilesQuantity = digitalLibraryFiles.length;
	
	
	const digitalLibraryFilesList = expertLibraryFilesQuantity > 0
		? digitalLibraryFiles.map(libraryFile =>
			<ExpertDigitalLibraryFileComponent
				key={libraryFile.id}
				libraryFile={libraryFile}
				removedLibraryFileHandler={removedLibraryFileHandler}
			/>
		) :
		<Col as={Alert} xs={12} variant="warning">Brak Plików</Col>
	;
	
	
	return (
		<Col xs={12} md={{span: 6, offset: 1}} className="expert-library-files">
			
			<Row>
				<ProfileSubSectionHeader headerText="Dodane pliki" />
			</Row>
			
			<Row>
				{digitalLibraryFilesList}
			</Row>
		
		</Col>
	);
	
};


export default ExpertDigitalLibraryFilesComponent;
