import React from "react";


import {Container, Row} from "react-bootstrap";


import FooterSiteLogo from "@Components/Containers/Footer/Logo";
import FooterContact from "@Components/Containers/Footer/Contact";


const PortalData = {
	portalAddress: "/", portalTitle: "Eksperci Środowiskowi", portalImagesDir: "/images"
};

const AddressData = {
	mainAddressData: {addressStreet: "Gospody", addressHouseNo: "21B / 9", addressPostCode: "80-344", addressCity: "Gdańsk"},
	mainContactData: {contactPhone: "530 193 461", contactEmail: "biuro@eksperci-srodowiskowi.pl"}
};


export const SiteFooterComponent = () => {

	
	return (
		<Container fluid={true} as="footer" id="site-footer" className="main-content-container">
			<Container id="site-footer-container">
				<Row id="site-footer-content" className="main-container-content">
					
					<FooterSiteLogo
						portalAddress={PortalData.portalAddress}
						portalTitle={PortalData.portalTitle}
						portalImagesDir={PortalData.portalImagesDir}
					/>
					
					<FooterContact
						mainAddressData={AddressData.mainAddressData}
						mainContactData={AddressData.mainContactData}
					/>
				
				</Row>
			</Container>
		</Container>
	);
	
};


export default SiteFooterComponent;
