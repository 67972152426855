import React, {useState} from "react";

import {Col, Row} from "react-bootstrap";

import ExpertProfilePageCompanyDataAvatar from "@Components/Content/User/Logged/Profile/CompanyData/Avatar";
import ExpertProfilePageCompanyDataFields from "@Components/Content/User/Logged/Profile/CompanyData/DataFields";


export const ExpertProfilePageCompanyData = ({environmentalExpertEditableFields, environmentalExpertData, URLS}) => {
	
	
	const {expertID, environmentalExpertEmail} = environmentalExpertData;
	const {expert_company_name: environmentalExpertCompanyName, expert_logo} = environmentalExpertEditableFields;
	
	const [environmentalExpertAvatar, setEnvironmentalExpertAvatar] = useState(expert_logo);
	
	const environmentalExpertMainData = {expertID, environmentalExpertCompanyName, environmentalExpertEmail};
	
	
	return (
		<Col
			xs={12}
			id="expert-profile-company-data"
		>
			
			<Row>
			
				<ExpertProfilePageCompanyDataAvatar
					environmentalExpertData={environmentalExpertMainData}
					environmentalExpertAvatar={environmentalExpertAvatar}
					changeEnvironmentalExpertAvatarHandler={setEnvironmentalExpertAvatar}
					URLS={URLS}
				/>
				
				<ExpertProfilePageCompanyDataFields
					environmentalExpertData={environmentalExpertMainData}
					environmentalExpertEditableFields={environmentalExpertEditableFields}
					URLS={URLS}
				/>
				
			</Row>
		</Col>
	);
	
};


export default ExpertProfilePageCompanyData;