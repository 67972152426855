import React, {useState} from "react";


import {Col, Row} from "react-bootstrap";


import ShopContentAddSpecializationComponent from "@Components/Content/User/Logged/Shop/Content/Specializations/AddSpecialization";
import ShopContentSpecializationsListComponent from "@Components/Content/User/Logged/Shop/Content/Specializations/SpecializationsList";


export const ShopContentSpecializationsComponent = ({
	REST_API_URL, restApiRequestStatus, restApiRequestStatusHandler,
	specializationsOrderContent, expertAccountType,
	expertSpecializations, expertProvinces,
	orderProcessHandler
}) => {
	
	const [orderedExpertPremiumPositions, setOrderedExpertPremiumPositions] = useState(specializationsOrderContent || []);
	
	const orderedExpertPremiumPositionsIDs = orderedExpertPremiumPositions.map(position => position.positionID);
	
	
	const premiumPositionAddHandler = async (positionData) => {
		
		restApiRequestStatusHandler(true);
		
		const {
			newPositionLocalization, newPositionSpecialization, newPositionPosition
		} = positionData;
		
		const response = await fetch(
			`${REST_API_URL}/specializations/premium-positions/
				?
				experts_premium_position_province_id=${newPositionLocalization}
				&
				experts_premium_position_specialization_id=${newPositionSpecialization}
				&
				experts_premium_position_position=${newPositionPosition}
			`);
		
		if (!response.ok) {
			return false;
		}
		
		const newPositionResponse = (await response.json())[0];
		
		const {
			id: positionID, url: positionURL,
			experts_premium_position_position: positionPosition,
			experts_premium_position_specialization_id: positionSpecialization, experts_premium_position_specialization_name: positionSpecializationName,
			experts_premium_position_province_id: positionLocalization, experts_premium_position_province_name: positionLocalizationName
		} = newPositionResponse;
		
		const newPositionData = {
			positionID, positionURL, positionPosition, positionSpecialization, positionSpecializationName, positionLocalization, positionLocalizationName
		};
		
		setOrderedExpertPremiumPositions([...orderedExpertPremiumPositions, newPositionData]);
		orderProcessHandler("positions", [...orderedExpertPremiumPositions, newPositionData]);
		
	};
	
	const premiumPositionRemoveHandler = (positionID) => {
		restApiRequestStatusHandler(true);
		const updatedPremiumPositionsList = orderedExpertPremiumPositions.filter(position => position.positionID !== positionID);
		setOrderedExpertPremiumPositions(updatedPremiumPositionsList);
		orderProcessHandler("positions", updatedPremiumPositionsList);
	};
	
	
	if (!expertSpecializations.length || !expertProvinces.length) {
		return null;
	}
	
	
	return (
		<Col xs={12} className="shop-page-specializations">
			
			<Row as="h4" className="premium-positions-heading">
				<Col as="span" xs={12} >Wybierz obszar działania i specjalizację oraz pozycję wyświetlania oferty</Col>
			</Row>
			
			{orderedExpertPremiumPositions.length > 0 &&
			<ShopContentSpecializationsListComponent
				orderedExpertPremiumPositions={orderedExpertPremiumPositions}
				premiumPositionRemoveHandler={premiumPositionRemoveHandler}
				expertAccountType={expertAccountType}
			/>
			}
			
			<ShopContentAddSpecializationComponent
				REST_API_URL={REST_API_URL}
				restApiRequestStatus={restApiRequestStatus}
				restApiRequestStatusHandler={restApiRequestStatusHandler}
				premiumPositionAddHandler={premiumPositionAddHandler}
				expertSpecializations={expertSpecializations}
				expertProvinces={expertProvinces}
				orderedExpertPremiumPositionsIDs={orderedExpertPremiumPositionsIDs}
				addSpecializationHandler={premiumPositionAddHandler}
			/>
		
		</Col>
	);
	
};


export default ShopContentSpecializationsComponent;
