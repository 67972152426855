import React, {useEffect, useRef, useState} from "react";


import {Container, Row, Col} from "react-bootstrap";


import {BounceLoader} from "react-spinners";


import Http404ErrorComponent from "@Components/Elements/Errors/404";
import {ScrollToDomElement} from "@Components/_UI/Animations/Scrolling";


import ExpertCardMainDataComponent from "@Components/Content/Pages/Expert/ProfileCardComponent/ExpertMainData";
import ExpertCardDescriptionComponent from "@Components/Content/Pages/Expert/ProfileCardComponent/ExpertDescription";
import ExpertInformationComponent from "@Components/Content/Pages/Expert/ProfileCardComponent/ExpertInformations";


export const ExpertProfileCardComponent = ({match}) => {
	
	
	const [expertCardData, getExpertCardData] = useState(null);
	
	
	const expertID = match.params.expert_id;
	
	
	useEffect(
		() => {
			fetch(`http://environmental.webprosdev.usermd.net/api/v1/experts/${expertID}/`)
				.then(response =>
					response.ok === true ? response.json() : {error: response.status}
				)
				.then(resource => getExpertCardData(resource))
				.catch(error => console.log(error))
		}, []
	);
	
	
	const domRef = useRef();
	
	useEffect(() => {
		ScrollToDomElement(domRef.current, 1000)
	}, [expertCardData]);
	
	
	if (!expertID || !expertCardData) {
		return (
			<Container fluid={true} id="site-primary-content" className="expert-card-page">
				<Container id='site-primary-content-container'>
					<Row id="expert-card-page" className="main-container-content main-content">
						<Col xs={12} className="expert-card-loader text-center">
							<BounceLoader color="#9DBF80" />
							<BounceLoader color="#9DBF80" />
							<BounceLoader color="#9DBF80" />
						</Col>
					</Row>
				</Container>
			</Container>
		);
	}
	
	
	if (expertCardData.error && expertCardData.error === 404) {
		return (
			<Http404ErrorComponent
				errorMessage="Brak użytkownika o takim identyfikatorze"
			/>
		);
	}
	
	
	const {
		expert_company_name: expertName, expert_company_dni: expertDNI,
		expert_company_email: expertEmail, expert_company_phone: expertPhone, expert_company_www: expertWWW,
		expert_address_street: expertAddressStreet, expert_address_house_no: expertAddressHouseNo,
		expert_address_flat_no: expertAddressFlatNo, expert_address_staircase: expertAddressStaircase,
		expert_address_postcode: expertAddressPostCode,
		expert_logo: expertLogo,
		expert_address_province: expertAddressProvince, expert_address_city_name: expertAddressCity,
		expert_description: expertDescription,
		expert_provinces: expertProvinces,
		expert_specializations: expertSpecializations,
		expert_references: expertReferences
	} = expertCardData;
	
	const environmentalExpertMainData = {
		expertName, expertDNI, expertEmail, expertPhone, expertWWW,
		expertAddressStreet, expertAddressHouseNo, expertAddressFlatNo, expertAddressStaircase,
		expertAddressPostCode, expertAddressCity, expertAddressProvince
	};
	
	
	return (
		<Container fluid={true} id="site-primary-content" className="expert-card-page">
			<Container id='site-primary-content-container'>
				<Row id="expert-card-page" className="main-container-content main-content" ref={domRef}>
					<Col xs={12}>
						
						<ExpertCardMainDataComponent
							environmentalExpertID={expertID}
							environmentalExpertLogo={expertLogo}
							environmentalExpertMainData={environmentalExpertMainData}
							environmentalExpertProvinces={expertProvinces}
						/>
						
						{expertDescription &&
						<ExpertCardDescriptionComponent
							environmentalExpertID={expertID}
							environmentalExpertDescription={expertDescription}
						/>
						}
						
						<ExpertInformationComponent
							environmentalExpertID={expertID}
							environmentalExpertSpecializations={expertSpecializations}
							environmentalExpertReferences={expertReferences}
						/>
						
					</Col>
				</Row>
			</Container>
		</Container>
	);
	
};


export default ExpertProfileCardComponent;
