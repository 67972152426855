import React from "react";


import {Row, Col} from "react-bootstrap";


import {SectionHeader} from "@Components/Elements/Headers";


export const ExpertCardDescriptionComponent = ({environmentalExpertDescription}) => {
	
	
	environmentalExpertDescription = environmentalExpertDescription.trim();
	
	if (!environmentalExpertDescription) {
		return null;
	}
	
	
	return (
		
		<Row id="expert-card-description">
			
			<Col xs={12}>
				<SectionHeader
					headerText="O firmie"
				/>
			</Col>
			
			<Col xs={12} className="expert-description">
				{environmentalExpertDescription}
			</Col>
			
		</Row>
	
	);
	
};


export default ExpertCardDescriptionComponent;
