export const formatDigitalLibraryList = (libraryCategories, libraryFiles) => {
	
	
	const digitalLibraryResources = {};
	
	
	for (let libraryCategory of libraryCategories) {
		const category = {
			categoryID: libraryCategory["id"],
			categoryName: libraryCategory["digital_library_main_category_name"],
			categoryActiveStatus: libraryCategory["digital_library_main_category_active_status"],
			categoryFiles: []
		};
		if (!category.categoryActiveStatus) {
			continue;
		}
		digitalLibraryResources[libraryCategory["id"]] = category;
	}
	
	for (let libraryFile of libraryFiles) {
		const file = {
			fileID: libraryFile["id"],
			fileName: libraryFile["digital_library_file_name"],
			fileURL: libraryFile["digital_library_file_file"],
			fileAuthor: libraryFile["digital_library_file_author"],
			filePublisher: libraryFile["digital_library_file_publisher"],
			fileReleaseYear: libraryFile["digital_library_file_release_year"],
			fileKeywords: libraryFile["digital_library_file_keywords"],
			fileAuthorURL: libraryFile["digital_library_file_url"],
			fileCategory: libraryFile["digital_library_file_category_object"],
		};
		const {id: fileCategoryID} = file.fileCategory;
		digitalLibraryResources[fileCategoryID]["categoryFiles"].push(file);
	}
	
	return digitalLibraryResources;
	
};