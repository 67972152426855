import React, {useContext, useState, useEffect} from "react";

import {useSelector} from "react-redux";


import {UI as UIContext} from "@Contexts";


import {Row} from "react-bootstrap";


import ExpertDigitalLibraryFilesComponent from "@Components/Content/User/Logged/Library/Expert/List";
import UploadLibraryFileComponent from "@Components/Content/User/Logged/Library/Expert/Upload";
import {DigitalLibraryMessageComponent} from "@Components/Content/User/Logged/Library/Expert/Message";



export const DigitalLibraryExpertFilesComponent = ({digitalLibraryFilesHandler}) => {
	
	
	const REST_API_URL = useContext(UIContext)["URLS"]["API"]["REST_API_MAIN_URL"];
	
	
	const [environmentalExpert, getEnvironmentalExpert] = useState(null);
	
	useEffect(() => {
		fetch(`${REST_API_URL}/experts/${LOGGED_EXPERT["expertID"]}/`)
			.then(response => response.ok === true ? response.json() : {error: response.statusText})
			.then(resource => {
				getEnvironmentalExpert(resource);
				const {expert_digital_library_files: expertDigitalLibraryFiles} = resource;
				setExpertDigitalLibraryFiles(expertDigitalLibraryFiles);
			})
			.catch(e => {return {error: e}});
	}, []);
	
	const LOGGED_EXPERT = useSelector(state => state.authentication.authenticatedUserData);
	
	
	const [expertDigitalLibraryFiles, setExpertDigitalLibraryFiles] = useState([]);
	
	const uploadedLibraryFileHandler = (apiResponseResource) => {
		console.log(apiResponseResource);
		setExpertDigitalLibraryFiles([...expertDigitalLibraryFiles, apiResponseResource]);
		setDigitalLibraryMessage({messageType: "success", messageContent: "Plik został dodany"});
	};
	
	const removedLibraryFileHandler = (removedFileID) => {
		fetch(
			`http://environmental.webprosdev.usermd.net/api/v1/digital-library/resources/${removedFileID}/`,
			{method: "DELETE"}
		).then(response => {
			setExpertDigitalLibraryFiles(
				expertDigitalLibraryFiles.filter(libraryFile => Number(libraryFile.id) !== removedFileID)
			);
			setDigitalLibraryMessage({messageType: "warning", messageContent: "Plik został usunięty"});
		});
	};
	
	const [digitalLibraryMessage, setDigitalLibraryMessage] = useState(null);
	

	if (!environmentalExpert || environmentalExpert.error) {
		return null;
	}
	
	
	const expertLibraryFilesHandler = (type, fileData) => {
		switch (type) {
			case "upload":
				uploadedLibraryFileHandler(fileData);
				break;
			case "delete":
				removedLibraryFileHandler(fileData);
				break;
		}
		digitalLibraryFilesHandler(type, fileData);
	}
	
	
	return (
		<Row id="digital-library-page-add-content" className="digital-library-content">
			
			{digitalLibraryMessage &&
			<DigitalLibraryMessageComponent
				digitalLibraryMessage={digitalLibraryMessage}
				digitalLibraryMessageHandler={setDigitalLibraryMessage}
			/>
			}
			
			<UploadLibraryFileComponent
				environmentalExpertID={LOGGED_EXPERT["expertID"]}
				uploadedLibraryFileHandler={expertLibraryFilesHandler}
			/>
			
			<ExpertDigitalLibraryFilesComponent
				digitalLibraryFiles={expertDigitalLibraryFiles}
				removedLibraryFileHandler={expertLibraryFilesHandler}
			/>
			
		</Row>
	);

}


export default DigitalLibraryExpertFilesComponent;
