import React from "react";


import ReactHtmlParser from 'react-html-parser';


import {Row, Col} from "react-bootstrap";


import {PolandMapComponent} from "@Components/Content/Pages/Expert/ProfileCardComponent/ExpertMainData/Map";


export const ExpertCardMainDataComponent = ({environmentalExpertID, environmentalExpertLogo, environmentalExpertMainData, environmentalExpertProvinces = []}) => {
	
	
	const {
		expertName, expertEmail, expertPhone, expertWWW,
		expertAddressStreet, expertAddressHouseNo, expertAddressFlatNo, expertAddressStaircase,
		expertAddressPostCode, expertAddressCity, expertAddressProvince
	} = environmentalExpertMainData;
	
	
	const environmentalExpertLogoFileName = environmentalExpertLogo ?
		environmentalExpertLogo.split("/")[environmentalExpertLogo.split("/").length - 1] : null;

	const expertLogoFile = environmentalExpertLogo || "/images/avatar.png";
	
	
	const expertProvinces = environmentalExpertProvinces.map(province => province.id);
	const expertProvincesNames = environmentalExpertProvinces.map(province => province.province_name);

	let expertAddress = "";
	
	if (expertAddressStreet) {
		expertAddress = `ul. ${expertAddressStreet.replace("ul.", "").trim()}`;
		if (expertAddressHouseNo) {
			expertAddress += ` ${expertAddressHouseNo}` + (expertAddressStaircase ? expertAddressStaircase : "");
			expertAddress += expertAddressFlatNo ? ` / ${expertAddressFlatNo}` : "";
		}
	}
	
	if (expertAddress && (expertAddressPostCode || expertAddressCity)) {
		expertAddress += "<br/>";
	}
	
	expertAddress += (expertAddressPostCode ? `${expertAddressPostCode} ` : "") + (expertAddressCity ? expertAddressCity : "");
	console.log(expertAddressProvince)
	if (expertAddress && expertAddressProvince) {
		expertAddress += "<br/>";
	}
	expertAddress += expertAddressProvince ? expertAddressProvince["province_name"] : "";
	
	return (
		<Row id="expert-card-main-data">
			
			<Col xs={12} md={3} className="expert-card-avatar">
				<div className="avatar-container">
					<img src={expertLogoFile} alt={expertName} />
				</div>
			</Col>
			
			<Col xs={12} md={6} className="expert-card-profile-data">
				<Col as="h1" className="expert-company-name">
					<span>{expertName}</span>
				</Col>
				<Col xs={12} className="expert-address-data">
					{expertAddress &&
					<Row className="address-data-address">
						<span className="data-name">Adres:</span>
						<span className="data-value">{ReactHtmlParser(expertAddress)}</span>
					</Row>
					}
					{expertPhone && expertPhone.trim() &&
					<Row className="address-data-phone">
						<span className="data-name">Tel:</span>
						<span className="data-value">
                            <a href={`tel:${expertPhone}`} title="Zadzwoń">{expertPhone}</a>
                        </span>
					</Row>
					}
					{expertEmail && expertEmail.trim() &&
					<Row className="address-data-email">
						<span className="data-name">Email:</span>
						<span className="data-value">
							<a href={`mailto:${expertEmail}`} title="Wyślij wiadomość">{expertEmail}</a>
						</span>
					</Row>
					}
					{expertWWW && expertWWW.trim() &&
					<Row className="address-data-www">
						<span className="data-name">WWW:</span>
						<span className="data-value">
							<a href={`http://${expertWWW}`} title="Przejdź na stronę" target="_blank">{expertWWW}</a>
						</span>
					</Row>
					}
					{expertProvincesNames.length > 0 &&
					<Row className="address-data-area">
						<span className="data-name">Obszar Działania:</span>
						<span className="data-value">
							{expertProvincesNames.join(", ")}
						</span>
					</Row>
					}
				</Col>
			</Col>
			
			<Col xs={12} md={3} className="expert-card-profile-map">
				<PolandMapComponent
					mapSize={250}
					selectedProvinces={expertProvinces}
				/>
			</Col>
		
		</Row>
	);
	
};


export default ExpertCardMainDataComponent;
