import React from "react";


import {Row, Col} from "react-bootstrap";
import {FaCheck} from "react-icons/all";


export const PricesList = () => {
	
	return (
		
		<>
			
			<Row className="prices-content prices-default-content">
				
				<Col xs={6}
					className="prices-content-column prices-content-label-column prices-content-header-column"
				>
					<Row className="prices-content-column-content">cennik</Row>
				</Col>
				
				<Col xs={3}
					className="prices-content-column prices-content-value-column prices-content-header-column default-prices">
					<Row className="prices-content-column-content">
						<span className="prices-content-column-value">podstawowy</span>
					</Row>
				</Col>
				
				<Col xs={3}
					className="prices-content-column prices-content-value-column prices-content-header-column premium-prices">
					<Row className="prices-content-column-content">
						<span className="prices-content-column-value">premium</span>
					</Row>
				</Col>
				
				<Col xs={6}
				     className="prices-content-column prices-content-label-column"
				>
					<Row className="prices-content-column-content">
						<span className="prices-content-column-value">Liczba specjalizacji do wyboru *</span>
					</Row>
				</Col>
				
				<Col xs={3}
					className="prices-content-column prices-content-value-column default-prices"
				>
					<Row className="prices-content-column-content">
						<span className="prices-content-column-value">10</span>
					</Row>
				</Col>
				
				<Col
					xs={3}
					className="prices-content-column prices-content-value-column premium-prices"
				>
					<Row className="prices-content-column-content">
						<span className="prices-content-column-value">15</span>
					</Row>
				</Col>
				
				
				<Col xs={6}
					className="prices-content-column prices-content-label-column"
				>
					<Row className="prices-content-column-content">
						<span className="prices-content-column-value">Dostęp do biblioteki cyfrowej</span>
					</Row>
				</Col>
				
				<Col xs={3}
					className="prices-content-column prices-content-value-column default-prices"
				>
					<Row className="prices-content-column-content">
						<FaCheck />
					</Row>
				</Col>
				
				<Col xs={3}
					className="prices-content-column prices-content-value-column premium-prices"
				>
					<Row className="prices-content-column-content">
						<FaCheck />
					</Row>
				</Col>
				
				
				<Col
					xs={6}
					className="prices-content-column prices-content-label-column col-xs-6"
				>
					<Row className="prices-content-column-content">
						<span className="prices-content-column-value">Newsletter o szkoleniach i konferencjach</span>
					</Row>
				</Col>
				
				<Col
					xs={3}
					className="prices-content-column prices-content-value-column default-prices"
				>
					<Row className="prices-content-column-content">
						<FaCheck />
					</Row>
				</Col>
				
				<Col
					xs={3}
					className="prices-content-column prices-content-value-column premium-prices col-xs-3"
				>
					<Row className="prices-content-column-content">
						<FaCheck />
					</Row>
				</Col>
				
				<Col
					xs={6}
					className="prices-content-column prices-content-label-column"
				>
					<Row className="prices-content-column-content">
						<span className="prices-content-column-value">Możliwość publikacji artykułu na stronie głównej i w dziale dla inwestora</span>
					</Row>
				</Col>
				
				<Col
					xs={3}
					className="prices-content-column prices-content-value-column default-prices"
				>
					<Row className="prices-content-column-content">
						<FaCheck />
					</Row>
				</Col>
				
				<Col
					xs={3}
					className="prices-content-column prices-content-value-column premium-prices"
				>
					<Row className="prices-content-column-content">
						<FaCheck />
					</Row>
				</Col>
				
				<Col
					xs={6}
					className="prices-content-column prices-content-label-column"
				>
					<Row className="prices-content-column-content">
						<span className="prices-content-column-value">Cena</span>
					</Row>
				</Col>
				
				<Col
					xs={3}
					className="prices-content-column prices-content-value-column default-prices"
				>
					<Row className="prices-content-column-content">
						<span className="prices-content-column-value">600 zł</span>
					</Row>
				</Col>
				
				<Col
					xs={3}
					className="prices-content-column prices-content-value-column default-prices"
				>
					<Row className="prices-content-column-content">
						<span className="prices-content-column-value">900 zł</span>
					</Row>
				</Col>
				
				<p className="prices-content-info">ceny netto / rok</p>
				
			</Row>
			
			
			<Row className="prices-content prices-extra-content">
				
				<h5 className="prices-extra-content-header">
					<span>opcje dodatkowe</span>
				</h5>
				
				<Col
					xs={6}
					className="prices-content-column prices-content-label-column"
				>
					<Row className="prices-content-column-content">
						<span className="prices-content-column-value">Newsletter z przetargami</span>
					</Row>
				</Col>
				
				<Col
					xs={3}
					className="prices-content-column prices-content-value-column default-prices"
				>
					<Row className="prices-content-column-content">
						<span className="prices-content-column-value">500 zł</span>
					</Row>
				</Col>
				
				<Col
					xs={3}
					className="prices-content-column prices-content-value-column default-prices"
				>
					<Row className="prices-content-column-content">
						<span className="prices-content-column-value">400 zł</span>
					</Row>
				</Col>
				
				
				<Col
					xs={6}
					className="prices-content-column prices-extra-content-column prices-content-label-column"
				>
					<Row className="prices-content-column-content">
						<ul className="prices-content-column-content-list">
							<li className="prices-content-column-content-list-item prices-content-column-content-list-item-header prices-content-column-content-list-item-label">
								<span>Pozycja gwarantowana **</span>
							</li>
							<li className="prices-content-column-content-list-item prices-content-column-content-list-item-label">
								<span>Pozycja 1</span>
							</li>
							<li className="prices-content-column-content-list-item prices-content-column-content-list-item-label">
								<span>Pozycja 2</span>
							</li>
							<li className="prices-content-column-content-list-item prices-content-column-content-list-item-label">
								<span>Pozycja 3</span>
							</li>
							<li className="prices-content-column-content-list-item prices-content-column-content-list-item-label">
								<span>Pozycja 4</span>
							</li>
							<li className="prices-content-column-content-list-item prices-content-column-content-list-item-label">
								<span>Pozycja 5</span>
							</li>
						</ul>
					</Row>
				</Col>
				
				<Col
					xs={3}
					className="prices-content-column prices-extra-content-column prices-extra-content-value-column prices-content-value-column default-prices"
				>
					
						<Col
							xs={6}
							className="prices-content-column-content"
						>
							<ul className="prices-content-column-content-list province-list">
								<li className="prices-content-column-content-list-item prices-content-column-content-list-item-header">
									<span>woj.</span></li>
								<li className="prices-content-column-content-list-item"><span>240 zł</span></li>
								<li className="prices-content-column-content-list-item"><span>200 zł</span></li>
								<li className="prices-content-column-content-list-item"><span>160 zł</span></li>
								<li className="prices-content-column-content-list-item"><span>120 zł</span></li>
								<li className="prices-content-column-content-list-item"><span>80 zł</span></li>
							</ul>
						</Col>
						<Col
							xs={6}
							className="prices-content-column-content"
						>
							<ul className="prices-content-column-content-list whole-country-list">
								<li className="prices-content-column-content-list-item prices-content-column-content-list-item-header">
									<span>Polska</span></li>
								<li className="prices-content-column-content-list-item"><span>480 zł</span></li>
								<li className="prices-content-column-content-list-item"><span>400 zł</span></li>
								<li className="prices-content-column-content-list-item"><span>320 zł</span></li>
								<li className="prices-content-column-content-list-item"><span>240 zł</span></li>
								<li className="prices-content-column-content-list-item"><span>160 zł</span></li>
							</ul>
						</Col>
					
				</Col>
				
				<Col
					xs={3}
					className="prices-content-column prices-extra-content-column prices-extra-content-value-column prices-content-value-column premium-prices"
				>
					<Col
						xs={6}
						className="prices-content-column-content"
					>
						<ul className="prices-content-column-content-list province-list">
							<li className="prices-content-column-content-list-item prices-content-column-content-list-item-header">
								<span>woj.</span></li>
							<li className="prices-content-column-content-list-item"><span>200 zł</span></li>
							<li className="prices-content-column-content-list-item"><span>160 zł</span></li>
							<li className="prices-content-column-content-list-item"><span>130 zł</span></li>
							<li className="prices-content-column-content-list-item"><span>100 zł</span></li>
							<li className="prices-content-column-content-list-item"><span>70 zł</span></li>
						</ul>
					</Col>
					<Col
						xs={6}
						className="prices-content-column-content"
					>
						<ul className="prices-content-column-content-list whole-country-list">
							<li className="prices-content-column-content-list-item prices-content-column-content-list-item-header">
								<span>Polska</span></li>
							<li className="prices-content-column-content-list-item"><span>400 zł</span></li>
							<li className="prices-content-column-content-list-item"><span>320 zł</span></li>
							<li className="prices-content-column-content-list-item"><span>260 zł</span></li>
							<li className="prices-content-column-content-list-item"><span>200 zł</span></li>
							<li className="prices-content-column-content-list-item"><span>140 zł</span></li>
						</ul>
					</Col>
				</Col>
				
				<p className="prices-content-info">ceny netto / rok</p>
			
			
			</Row>
			
			
			<div className="prices-content prices-content-legend">
				
				<p>
					* Wykupując profil standard lub premium, przy zakładaniu konta, możesz z listy 50 specjalizacji
					wybrać 10 lub 15 tych którymi się zajmujesz - np.
					operaty wodnoprawne, karty informacyjne przedsięwzięć, analizy GIS, inwentaryzacje przyrodnicze. W
					następnym kroku dowolnie wybierasz województwa w których działasz.
					Po określeniu swoich specjalizacji i obszaru działania, Twoja firma będzie się wyświetlać w wynikach
					wyszukiwania losowo. Jeśli chcesz sobie zagwarantować na liście wyników wyszukiwania miejsca od 1 do
					5 - przejdź do opcji dodatkowych.
				</p>
				
				<p>
					** Możesz w danej specjalizacji i w konkretnym województwie wykupić miejsce od 1 do 5 na liście
					wyników wyszukiwania. Ceny na listach wojewódzkich różnią się od ceny na liście ogólnopolskiej.
				</p>
				
				<p>
					<strong>PRZYKŁAD:</strong> Firma działa na obszarze województwa pomorskiego, wykupuje pakiet
					"standard" oraz w specjalizacji KIP chce wyświetlać się zawsze na 2 miejscu, a w specjalizacji
					operaty wodnoprawne na 1 miejscu.
					<br/>
					600 + 200 + 240 = 1 040 zł/rok (netto)
				</p>
			
			</div>
		</>
		
	);
	
};


export default PricesList;