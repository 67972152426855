import React, {useState} from "react";


import {Container, Row, Col, Form, Button} from "react-bootstrap";


export const SiteNewsletterSectionComponent = () => {

	
	const [newsletterSubmitStatus, setNewsletterSubmitStatus] = useState(false);
	
	const changeNewsletterSubmitStatus = (e) => {
		e.preventDefault();
		setNewsletterSubmitStatus(true);
	}
	
	
	return (
		<>
			
			<div id="advertising-banners" style={{padding: "20px 0px", borderTop: "1px solid #7D7D7D"}}>
				<Container>
					<Row className="row banner text-center justify-content-center">
						<a href="https://www.targikielce.pl/ekotech" target="_blank" rel="nofollow noopener noreferrer">
							<img src="/images/ekotech_banner.jpg" alt="Ekotech"/>
						</a>
					</Row>
				</Container>
			</div>
			
			<Container fluid={true} id="site-newsletter-section" className="main-content-container">
				<Container id="site-newsletter-container">
					<Row id="site-newsletter-content" className="main-container-content">
						
						{
							newsletterSubmitStatus ?
								<Col xs={12} className="newsletter-subscribe-OK-message">
									<span>Dziękujemy za zapisanie się do newslettera</span>
								</Col>
							:
								<>
									<Col as="h2" xs={12} md={4} className="newsletter-header">
										Zapisz się do Newslettera
										<span className="sub-content">o szkoleniach i konferencjach branżowych</span>
									</Col>
									<Col
										as={Form} xs={12} md={8}
										method="POST"
										onSubmit={e => changeNewsletterSubmitStatus(e)}
										id="newsletter-form"
										className="newsletter-form"
									>
										<Form.Group>
											<Form.Control
												name="newsletter_subscribe_email"
												required="required"
												placeholder="Twój Email"
											/>
											<Button type="submit" variant="primary" onClick={e => changeNewsletterSubmitStatus(e)}>Zapisz</Button>
										</Form.Group>
									</Col>
								</>
						}
					
					</Row>
				</Container>
			</Container>
		
		</>
	);
	
};


export default SiteNewsletterSectionComponent;
