import React, {useState} from "react";


import {Row, Col} from "react-bootstrap";


export const FindExpertSingleResultComponent = ({expert, addExpertToListHandler}) => {
	
	
	const {
		id: expertID, expert_company_name: expertCompanyName,
		expert_address_province: expertAddressProvince, expert_address_city_name: expertAddressCityName,
		// expert_company_email,
		// expert_slug: expertSlug,
		expert_logo: expertLogo
	} = expert;
	
	
	const [expertCheckedStatus, setExpertCheckedStatus] = useState(false);
	
	const addExpertToList = (expertID) => {
		addExpertToListHandler(expertID);
		setExpertCheckedStatus(!expertCheckedStatus);
	};
	
	const expertAddressProvinceName = expertAddressProvince ? expertAddressProvince["province_name"] : null;
	
	return (
		<Row as="li" className="find-expert-results-item">
			
			<Col xs={12} lg={2} className="item-image">
				<a href={`/eksperci/${expertID}`} title={expertCompanyName} target="_blank" rel="nofollow noopener noreferrer">
					<img
						src={expertLogo ? expertLogo : "/images/avatar.png"}
						alt={expertCompanyName}
					/>
				</a>
			</Col>
			
			<Col xs={12} lg={7} className="item-content">
				<h5 className="item-title">
					<span>{expertCompanyName}</span>
				</h5>
				{(expertAddressProvinceName || expertAddressCityName) &&
				<h6 className="item-city">
					<span className="item-city-label">Siedziba firmy:</span>
					<span>{
						(expertAddressProvinceName ? expertAddressProvinceName : "")
						+
						(expertAddressCityName ? (expertAddressProvinceName ? " / " : "") + expertAddressCityName : "")
					}</span>
				</h6>
				}
			</Col>
			
			<Col xs={6} lg={2} className="item-options">
				<h6>
					<span>dodaj firmę do zapytania</span>
				</h6>
				<div className="add-item">
					<input
						id={`add-expert-${expertID}`}
						type="checkbox"
						value={expertID}
						checked={expertCheckedStatus}
						onChange={() => addExpertToList(expertID)}
						className="expert_to_mail"
					/>
					<label htmlFor={`add-expert-${expertID}`}></label>
				</div>
			</Col>
			
			<Col xs={6} lg={1} className="item-card">
				<a href={`/eksperci/${expertID}`} title={expertCompanyName} className="go-to-company-card" target="_blank" rel="nofollow noopener noreferrer">
					<span>zobacz wizytówkę</span>
				</a>
			</Col>
		
		</Row>
	);
	
};


export default FindExpertSingleResultComponent;
