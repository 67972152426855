import React, {useContext, useEffect, useState} from "react";

import {MainContentLoader} from "@Components/Elements/Loaders";


import {UI as UIContext} from "@Contexts";


import HomePageSlider from "@Components/Content/Pages/HomePage/Slider";
import HomePageMainButtons from "@Components/Content/Pages/HomePage/Buttons";
import HomePageContent from "@Components/Content/Pages/HomePage/Content";



export const HomePage = () => {
	
	
	const [homePageContent, getHomePageContent] = useState({});
	
	const UI = useContext(UIContext);
	const MAIN_API_URL = UI["URLS"]["API"]["REST_API_MAIN_URL"];
	
	const API_REQUEST_URL = `${MAIN_API_URL}/portal-pages/home-page/1/`;
	
	useEffect(
		() => {
			fetch(API_REQUEST_URL)
				.then(response => response.json())
				.then(resource => {
					getHomePageContent(resource);
					// ScrollToDomElement(domRef.current, 1000)
				})
		},
		[]
	);
	
	
	const {
		home_page_show_slider: showHomePageSlider, home_page_slides: homePageSlides,
		home_page_main_content_label: homePageContentLabel, home_page_main_content: homePageContentValue,
		home_page_show_articles: showArticlesOnHomePage, home_page_articles_label: homePageArticlesLabel
	} = homePageContent;
	
	
	if (homePageContentValue === undefined) {
		return (
			<MainContentLoader />
		);
	}
	
	
	return (
		<>
			
			{showHomePageSlider &&
			<HomePageSlider
				homePageSlides={homePageSlides}
			/>
			}
			
			<HomePageMainButtons />
			
			<HomePageContent
				homePageContentLabel={homePageContentLabel}
				homePageContentValue={homePageContentValue}
				showArticlesOnHomePage={showArticlesOnHomePage}
				homePageArticlesLabel={homePageArticlesLabel}
			/>
		
		</>
	);
	
};


export default HomePage;