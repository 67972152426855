import React, {useState} from "react";

import {Col, Form, Button} from "react-bootstrap";


export const ExpertProfilePageCompanyDataAvatar = ({environmentalExpertData, environmentalExpertAvatar, changeEnvironmentalExpertAvatarHandler, URLS}) => {
	
	
	const {environmentalExpertID, environmentalExpertCompanyName} = environmentalExpertData;
	
	const [changeAvatar, changeAvatarToggle] = useState(false);
	
	const [environmentalExpertAvatarFile, changeEnvironmentalExpertAvatarFile] = useState(
		environmentalExpertAvatar ?
			environmentalExpertAvatar.replace("http://127.0.0.1:8050/media/", "")
			:
			null
	);
	
	
	return (
		<Col
			xs={12}
			md={2}
			className="expert-profile-avatar"
		>
			
			<Col xs={12} className="expert-profile-avatar-image">
				<span
					title={environmentalExpertCompanyName}
					className="expert-avatar"
				>
					<img
                            src={environmentalExpertAvatar ?
	                            environmentalExpertAvatar
	                            :
	                            "/images/avatar.png"
                            }
                            alt={environmentalExpertCompanyName}
                    />
				</span>
			</Col>
		
			<Col xs={12} className="text-center">
				<a
					href="#change-avatar"
					className={(!changeAvatar ? "" : "active ") +  "expert-profile-avatar-change-switcher"}
					title={!changeAvatar ? "Zmień Avatar" : "Anuluj zmianę Avataru"}
					onClick={(e) => {e.preventDefault(); changeAvatarToggle(!changeAvatar)}}
				>
					<span>{!changeAvatar ? "Zmień Avatar" : "Anuluj"}</span>
				</a>
			</Col>
		
			{changeAvatar &&
			<Col
				as={Form}
				xs={12}
				method="POST"
				encType="multipart/form-data"
				className="expert-profile-avatar-change"
			>
				<Form.Group>
					<Form.Control
						type="file"
						name="expert_avatar"
					/>
				</Form.Group>
				<Form.Group>
					<Button type="submit" variant="primary">Zmień</Button>
				</Form.Group>
			</Col>
			}
			
		</Col>
	);
	
};


export default ExpertProfilePageCompanyDataAvatar;
