import React from "react";


import {Col} from "react-bootstrap";

import {BounceLoader} from "react-spinners";


export const MainContentLoader = ({loaderColor = "#9DBF80"}) => {
	
	return (
		<Col xs={12} className="page-content-loader text-center">
			<BounceLoader color={loaderColor} />
			<BounceLoader color={loaderColor} />
			<BounceLoader color={loaderColor} />
		</Col>
	);
	
};