import React from "react";


import {Col} from "react-bootstrap";


import ExpertSingleDataField from "@Components/Content/User/Logged/Profile/CompanyData/DataFields/Field";


export const ExpertProfilePageCompanyDataFields = ({environmentalExpertData, environmentalExpertEditableFields, URLS}) => {
	
	
	const {expertID: environmentalExpertID, environmentalExpertEmail} = environmentalExpertData;
	
	
	const REST_API_EXPERT_URL = URLS["REST_API_URL"] + "/" + environmentalExpertID + "/";
	
	
	const {
		// expert_password: environmentalExpertPassword,
		expert_company_phone: environmentalExpertPhone,
		expert_company_dni: expertCompanyDNI, expert_company_name: expertCompanyName,
		expert_company_www: environmentalExpertWWW,
		expert_address_street: environmentalExpertStreet, expert_address_house_no: environmentalExpertHouseNo,
		expert_address_staircase: environmentalExpertStaircase, expert_address_flat_no: environmentalExpertFlatNo,
		expert_address_postcode: environmentalExpertPostCode,
		// expert_address_city_id, expert_address_province_id,
	} = environmentalExpertEditableFields;
	
	
	return (
		
		<>
		
			
			<Col
				xs={12}
				md={5}
				className="expert-profile-data"
			>
				
				<h1 className="expert-profile-name">
					<span>{expertCompanyName}</span>
				</h1>
				
				<div className="expert-profile-address-data">
					
					<h4 className="profile-section-header address-data-header">
						<span className="title">Dane Adresowe</span>
					</h4>
					
					<ExpertSingleDataField
						fieldData={{fieldLabel: "Ulica", fieldName: "expert_address_street"}}
						fieldValue={environmentalExpertStreet}
						REST_API_EXPERT_URL={REST_API_EXPERT_URL}
					/>
					
					<ExpertSingleDataField
						fieldData={{fieldLabel: "Nr Domu", fieldName: "expert_address_house_no"}}
						fieldValue={environmentalExpertHouseNo}
						REST_API_EXPERT_URL={REST_API_EXPERT_URL}
					/>
					
					<ExpertSingleDataField
						fieldData={{fieldLabel: "Klatka", fieldName: "expert_address_staircase"}}
						fieldValue={environmentalExpertStaircase}
						REST_API_EXPERT_URL={REST_API_EXPERT_URL}
					/>
					
					<ExpertSingleDataField
						fieldData={{fieldLabel: "Nr Mieszkania", fieldName: "expert_address_flat_no"}}
						fieldValue={environmentalExpertFlatNo}
						REST_API_EXPERT_URL={REST_API_EXPERT_URL}
					/>
					
					<ExpertSingleDataField
						fieldData={{fieldLabel: "Kod Pocztowy", fieldName: "expert_address_postcode"}}
						fieldValue={environmentalExpertPostCode}
						REST_API_EXPERT_URL={REST_API_EXPERT_URL}
					/>
					
					<h4 className="profile-section-header contact-data-header">
						<span className="title">Dane Kontaktowe</span>
					</h4>
					
					<ExpertSingleDataField
						fieldData={{fieldType: "tel", fieldLabel: "Telefon", fieldName: "expert_company_phone"}}
						fieldValue={environmentalExpertPhone}
						REST_API_EXPERT_URL={REST_API_EXPERT_URL}
					/>
					
					<ExpertSingleDataField
						fieldData={{fieldType: "email", fieldLabel: "Email", fieldName: "expert_company_email"}}
						fieldValue={environmentalExpertEmail}
						editable={false}
					/>
					
					<ExpertSingleDataField
						fieldData={{fieldLabel: "WWW", fieldName: "expert_company_www"}}
						fieldValue={environmentalExpertWWW}
						REST_API_EXPERT_URL={REST_API_EXPERT_URL}
					/>
				
				</div>
				
			</Col>
			
			
			<Col
				xs={12}
				md={5}
				className="expert-profile-data"
			>
				
				<div className="expert-profile-address-data company-and-password-data">
					
					<h4 className="profile-section-header company-data-header">
						<span className="title">Dane Firmy</span>
					</h4>
					
					<ExpertSingleDataField
						fieldData={{fieldLabel: "Nazwa Firmy", fieldName: "expert_company_name"}}
						fieldValue={expertCompanyName}
						REST_API_EXPERT_URL={REST_API_EXPERT_URL}
					/>
					
					<ExpertSingleDataField
						fieldData={{fieldLabel: "NIP", fieldName: "expert_company_dni"}}
						fieldValue={expertCompanyDNI}
						REST_API_EXPERT_URL={REST_API_EXPERT_URL}
					/>
					
					<h4 className="profile-section-header password-change-header">
						<span className="title">Zmiana Hasła</span>
					</h4>
					
					<ExpertSingleDataField
						fieldData={{fieldType: "password", fieldLabel: "Obecne Hasło", fieldName: "expert_actual_password"}}
						fieldValue={""}
						REST_API_EXPERT_URL={REST_API_EXPERT_URL}
					/>
				
				</div>
			
			</Col>
			
		
		</>
	);
	
};





export default ExpertProfilePageCompanyDataFields;
