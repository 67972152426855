import {combineReducers} from "redux";


import Authentication from "@Store/Reducers/Authentication";


export default combineReducers(
	{
		authentication: Authentication
	}
);