import React from "react";


import {Row} from "react-bootstrap";


import ExpertCardSpecializationsComponent from "@Components/Content/Pages/Expert/ProfileCardComponent/ExpertInformations/Specializations";
import ExpertCardReferencesComponent from "@Components/Content/Pages/Expert/ProfileCardComponent/ExpertInformations/References";



export const ExpertInformationComponent = ({environmentalExpertID, environmentalExpertSpecializations = [], environmentalExpertReferences = []}) => {
	
	
	const expertReferencesQuantity = environmentalExpertReferences.length > 0;
	const expertSpecializationsQuantity = environmentalExpertSpecializations.length > 0;
	
	
	if (!expertReferencesQuantity && !expertSpecializationsQuantity) {
		return null;
	}
	
	
	const groupedExpertReferences = {};
	for (let expertReference of environmentalExpertReferences) {
		const {
			expert_reference_specialization_name: specializationName
		} = expertReference;
		if (!(specializationName in groupedExpertReferences)) {
			groupedExpertReferences[specializationName] = [];
		}
		groupedExpertReferences[specializationName].push(expertReference);
	}
	
	
	return (
		
		<Row id="expert-card-information">
			
			{expertSpecializationsQuantity &&
			<ExpertCardSpecializationsComponent
				environmentalExpertID={environmentalExpertID}
				environmentalExpertSpecializations={environmentalExpertSpecializations}
			/>
			}
			
			{expertReferencesQuantity &&
			<ExpertCardReferencesComponent
				environmentalExpertID={environmentalExpertID}
				groupedExpertReferences={groupedExpertReferences}
			/>
			}
		
		</Row>
	
	);
	
};



export default ExpertInformationComponent;