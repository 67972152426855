import React from "react";


import {Col} from "react-bootstrap";


export const ProfileSubSectionHeader = ({headerText}) => {

	return (
		<Col as="h4" xs={12} className="profile-section-header">
			<span className="title">{headerText}</span>
		</Col>
	);
	
};