import React from "react";


import {NavLink} from "react-router-dom"


import {Col, Container, Row} from "react-bootstrap";


export const SiteCopyRightsComponent = () => {
	
	
	return (
		<Container fluid={true} id="site-copy-rights" className="main-content-container">
			<Container id="site-copy-rights-container">
				<Row id="site-copy-rights-content" className="main-container-content">
					
					<Col xs={12} className="copy-rights-content">
						
						<ul className="links-menu">
							<li>
								<NavLink to="/regulamin" title="Regulamin">Regulamin</NavLink>
							</li>
							<li>
								<NavLink to="/polityka-prywatnosci" title="Polityka Prywatności">Polityka Prywatności</NavLink>
							</li>
							<li>
								<NavLink to="/polityka-cookies" title="Polityka Cookies">Polityka Cookies</NavLink>
							</li>
						</ul>
						
						copyrights <strong>Eksperci Środowiskowi ©</strong> {(new Date().getFullYear())}<br/><br/>
						powered by <a href="https://webpros.pl" target="_blank" rel="noopener noreferrer">WebPros.pl</a>
					
					</Col>
				
				</Row>
			</Container>
		</Container>
	);
	
};



export default SiteCopyRightsComponent;
