import React from "react";


import {NavLink} from "react-router-dom";

import {Col} from "react-bootstrap";


export const SiteLogo = ({portalAddress = "/", portalTitle, portalImagesDir = "/images"}) => {

	return (
		<Col xs={12} md={3} className="header-logo">
		
			<NavLink to={portalAddress} title={portalTitle}>
				<img src={`${portalImagesDir}/main-logo.png`} alt={portalTitle} />
			</NavLink>

		</Col>
	);
	
};


export default SiteLogo;