import React, {useState, useEffect, useRef} from "react";

import ReactHtmlParser from 'react-html-parser';


import {ScrollToDomElement} from "@Components/_UI/Animations/Scrolling";


import {Container, Row, Col} from "react-bootstrap";

import {MainContentLoader} from "@Components/Elements/Loaders";
import Http404ErrorComponent from "@Components/Elements/Errors/404";


export const ArticlePage = ({match}) => {

	
	const [articleData, getArticleData] = useState(null);
	const articleSlug = match.params.article_slug;

	
	const domRef = useRef();
	
	useEffect(() => {
		ScrollToDomElement(domRef.current, 1000)
	}, []);
	
	
	useEffect(() => {
		fetch(`http://environmental.webprosdev.usermd.net/api/v1/articles/${articleSlug}/`)
			.then(response => response.json())
			.then(resource => getArticleData(resource))
	}, []);
	
	
	if (!articleData) {
		return (
			<MainContentLoader />
		);
	}
	
	if (articleData.detail === "Nie znaleziono.") {
		return (
			<Http404ErrorComponent errorMessage="Brak Artykułu o takim adresie" />
		);
	}
	
	const {
		text_article_title: articleTitle,
		text_article_content: articleContent,
		text_article_author_name: articleAuthorName,
		text_article_author_company: articleAuthorCompany
	} = articleData;
	
	
	return (
		<Container id="site-primary-content" ref={domRef}>
			<Container id='site-primary-content-container'>
				<Row id="article-page" className="main-container-content main-content">
					
					<Col as="h1" xs={12} className="main-header">
						<span>{articleTitle}</span>
					</Col>
					
					<ArticleMeta
						articleAuthorName={articleAuthorName}
						articleAuthorCompany={articleAuthorCompany}
					/>
					
					<ArticleContent
						articleContent={articleContent}
					/>
				
				</Row>
			</Container>
		</Container>
	);
	
};


		const ArticleMeta = ({articleAuthorName, articleAuthorCompany}) => {
			
			return (
				<Col as="h3" xs={12} className="article-author">
					<span>{articleAuthorName}</span>
					<br/>
					<font>{articleAuthorCompany}</font>
				</Col>
			);
			
		};


		const ArticleContent = ({articleContent}) => {
		
			return (
				<Col as="article" xs={12} className="text-page-content article-page-content">
					{ReactHtmlParser(articleContent)}
				</Col>
			);
			
		};


export default ArticlePage;