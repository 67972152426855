import React, {useEffect, useRef} from "react";


import {ScrollToDomElement} from "@Components/_UI/Animations/Scrolling";

import {Container} from "react-bootstrap";


import 'react-awesome-slider/src/styles';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
const AutoplaySlider = withAutoplay(AwesomeSlider);


export const HomePageSlider = ({homePageSlides}) => {
	
	
	const domRef = useRef();
	
	useEffect(() => {
		ScrollToDomElement(domRef.current, 500)
	}, []);
	
	
	const homePageSlidesList = homePageSlides
		.filter(slide => slide.home_page_slide_item_active_status)
		.map((slide, index) => {
			const {home_page_slide_item_image: slideImage} = slide;
			return (
				<div key={index} data-src={slideImage} />
			);
		});
	
	
	return (
		<Container fluid={true} id="home-page-slider">
			<span ref={domRef}></span>
			<AutoplaySlider
				play={true}
				cancelOnInteraction={false}
				interval={4000}
				bullets={false}
				fillParent={true}
			>
				{homePageSlidesList}
			</AutoplaySlider>
		</Container>
	);
	
};


export default HomePageSlider;