import React, {Component} from "react";
import {connect as CONNECT} from "react-redux";


import {Switch, Route} from "react-router-dom";

import {Container, Row} from "react-bootstrap";


import {
	HomePage as PortalHomePage
} from "@Components/Content/Pages";

import AboutUsPage from "@Components/Content/Pages/_TextPages/AboutUs";
import ForExpertPage from "@Components/Content/Pages/ForExpert";
import ForInvestorPage from "@Components/Content/Pages/ForInvestor";
import ContactPage from "@Components/Content/Pages/Contact";

import FindExpertPage from "@Components/Content/Pages/FindExpert";
import ExpertProfileCardComponent from "@Components/Content/Pages/Expert/ProfileCardComponent";

import RegulationsPage from "@Components/Content/Pages/_TextPages/Regulations";
import PrivacyPolicyPage from "@Components/Content/Pages/_TextPages/PrivacyPolicy";
import CookiesPolicyPage from "@Components/Content/Pages/_TextPages/CookiesPolicy";

import ArticlesListPage from "@Components/Content/Articles";
import ArticlePage from "@Components/Content/Articles/Article";

import LoginPage from "@Components/Content/User/NotLogged/Login";
import RegisterPage from "@Components/Content/User/NotLogged/Register";

import ExpertProfilePage from "@Components/Content/User/Logged/Profile";
import ServicesShopPageComponent from "@Components/Content/User/Logged/Shop";
import DigitalLibraryPageComponent from "@Components/Content/User/Logged/Library";


export class SiteMainContent extends Component {
	
		render() {
			
			return (
				<Container fluid={true} as="main" id={window.location.pathname === "/" ? "home-page-content" : "site-main-content"}>
					<Row id="site-main-content-mask">
						
						<Switch>
							
							<Route path="/" exact component={(props) => <PortalHomePage {...props} pageTitle="Strona Główna" />} />
							
							<Route path="/logowanie" exact component={(props) => <LoginPage {...props} pageTitle="Zaloguj się" />} />
							<Route path="/rejestracja" exact component={(props) => <RegisterPage {...props} pageTitle="Zarejestruj się" />} />
							
							<Route path="/profil" exact component={(props) => <ExpertProfilePage {...props} pageTitle="Profil" />} />
							<Route path="/sklep" exact component={(props) => <ServicesShopPageComponent {...props} pageTitle="Profil" />} />
							
							<Route path="/biblioteka-cyfrowa" exact component={(props) => <DigitalLibraryPageComponent {...props} pageTitle="Biblioteka Cyfrowa" />} />
							
							<Route path="/o-portalu" exact component={(props) => <AboutUsPage {...props} pageTitle="O Portalu" />} />
							<Route path="/dla-eksperta" exact component={(props) => <ForExpertPage {...props} pageTitle="Dla Eksperta" />} />
							<Route path="/dla-inwestora" exact component={(props) => <ForInvestorPage {...props} pageTitle="Dla Inwestora" />} />
							<Route path="/kontakt" exact component={(props) => <ContactPage {...props} pageTitle="Kontakt" />} />

							<Route path="/znajdz-eksperta" exact component={(props) => <FindExpertPage {...props} pageTitle="Znajdź Eksperta" />} />
							<Route path="/eksperci/:expert_id" exact component={(props) => <ExpertProfileCardComponent {...props} pageTitle="Profil Eksperta" />} />
							
							<Route path="/artykuly" exact component={(props) => <ArticlesListPage {...props} pageTitle="Artykuły" />} />
							<Route path="/artykuly/:article_slug" component={(props) => <ArticlePage {...props} />} />
							
							<Route path="/regulamin" exact component={(props) => <RegulationsPage {...props} pageTitle="Regulamin" />} />
							<Route path="/polityka-prywatnosci" exact component={(props) => <PrivacyPolicyPage {...props} pageTitle="Polityka Prywatności" />} />
							<Route path="/polityka-cookies" exact component={(props) => <CookiesPolicyPage {...props} pageTitle="Polityka Cookies" />} />
						
						</Switch>
						
					</Row>
				</Container>
			);
			
		}
	
}


export default CONNECT(null, null)(SiteMainContent)
