import React, {useRef} from "react";


import {usePdf} from 'react-pdf-js';


import {Col} from "react-bootstrap";


export const DigitalLibraryFileComponent = ({filesData}) => {
	
	
	const {
		fileName, fileURL, fileAuthor, filePublisher, fileReleaseYear
	} = filesData;
	
	
	const canvasEl = useRef(null);
	const [loading] = usePdf({
		canvasEl,
		file: "https://cors-anywhere.herokuapp.com/" + fileURL,
		page: 1,
		onDocumentLoadFail: e => console.log(e),
		onPageLoadFail: e => console.log(e),
		onLoadError: e => console.log(e),
	});
	
	
	const hrContainer = useRef(null);
	
	
	return (
		<Col xs={6} lg={4} className="files-list-file">
			<a
				href={fileURL}
				rel="nofollow"
				title="zobacz"
				target="_blank"
				ref={hrContainer}
			>
				{loading ?
					<img title={fileName} src="/images/pdf.png" alt={fileName}/>
					:
					<canvas
						ref={canvasEl}
						style={{
							maxWidth: hrContainer.current.clientWidth - 10,
							maxHeight: hrContainer.current.clientWidth * 1.5
						}}
					/>
				}
				<div className="file-item-data">
					{fileAuthor && <span className="file-author">{fileAuthor}</span>}
					{filePublisher && <span className="file-publisher">{filePublisher}</span>}
					{fileReleaseYear && <span className="file-year">{fileReleaseYear}</span>}
					<span className="file-title">{fileName}</span>
				</div>
			</a>
		</Col>
	);
	
};


export default DigitalLibraryFileComponent;
