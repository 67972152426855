import React from "react";


export const PageMainHeader = ({headerText}) => {
	
	return (
		<h1 className="main-header">
			<span>{headerText}</span>
		</h1>
	);
	
};


export const PageSecondaryHeader = ({headerText}) => {

	return (
		<h2 className="secondary-header">
			<span>{headerText}</span>
		</h2>
	);
	
};


export const SectionHeader = ({headerText}) => {

	return (
		<h4 className="section-header">
			<span>{headerText}</span>
		</h4>
	);
	
};


export default {
	PageMainHeader,
	PageSecondaryHeader
};