import React, {useState} from "react";


import {Col} from "react-bootstrap";


import {Slide} from "@material-ui/core";


export const ExpertDigitalLibraryFileComponent = ({libraryFile, removedLibraryFileHandler}) => {
	
	
	const {
		id: libraryFileID,
		digital_library_file_title: libraryFileTitle,
		digital_library_file_category_object: libraryFileCategory,
		digital_library_file_file: libraryFileURL
		
	} = libraryFile;
	
	
	const {digital_library_main_category_name: libraryFileCategoryName} = libraryFileCategory;
	
	
	const [libraryFileRemove, setLibraryFileRemove] = useState(false);
	
	
	return (
		<Slide
			in={true}
			direction="down"
			timeout={{enter: 1000}}
			style={{transformOrigin: "left top", transform: "scale(1, 0.01) translate(0, 0)"}}
			mountOnEnter
		>
			<Col xs={12} md={4} className="expert-library-file">
				<Col xs={12} className="library-file-content">
					<h5 className="library-file-name">{libraryFileTitle}</h5>
					<h6 className="library-file-category">{libraryFileCategoryName}</h6>
					<a
						href={libraryFileURL}
						title={libraryFileTitle}
						className="library-file-url"
						target="_blank"
					>
						<span>Zobacz plik</span>
					</a>
					<span
						className={(libraryFileRemove ? "active " : "") + "delete-library-file"}
						onClick={() => {
							if (libraryFileRemove === true) {
								setLibraryFileRemove(false);
								removedLibraryFileHandler("delete", libraryFileID);
							}
							setLibraryFileRemove(true);
						}}
					>
						{libraryFileRemove ? "Potwierdź usunięcie" : "Usuń"}
					</span>
				</Col>
			</Col>
		</Slide>
	);
	
};


export default ExpertDigitalLibraryFileComponent;
