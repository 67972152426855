import React, {useState} from "react";


import {Form} from "react-bootstrap";

import ReactTooltip from "react-tooltip";


export const PolandMap = ({apiRequestStatus, localizationsZones, expertLocalizations, updateExpertLocalizations}) => {
	
	
	const [wholeCountrySelectedStatus, setWholeCountrySelectedStatus] = useState(
		expertLocalizations.indexOf(17) !== -1
	);
	
	
	const prepareLocalizationsListComponent = () => {
		
		const localizationsListComponent = localizationsZones.map(localization => {
			const {localizationID, localizationZones, localizationName} = localization;
			const localizationSelected = expertLocalizations.indexOf(localizationID) !== -1;
			return (
				<li
					key={localizationID}
					title={localizationName}
					data-tip={localizationName}
					className={`pl${localizationID} ${localizationSelected ? "active-region" : ""}` + (apiRequestStatus ? " disabled" : "")}
					onClick={(e) => {
						e.preventDefault();
						if (apiRequestStatus) {
							return false;
						}
						updateExpertLocalizations(localizationID)
					}}
				>
					<span className="m">
						{prepareLocalizationsZonesList(localizationZones)}
					</span>
					<span className="bg"></span>
					<ReactTooltip place="top" type="success" effect="float" />
				</li>
			);
		});
		
		return localizationsListComponent;
		
	};
	
	const prepareLocalizationsZonesList = (zonesNumber) => {
		return [...Array(zonesNumber).keys()].map(i =>
			<span key={i} className={`s${i + 1}`}></span>
		)
	};
	
	
	const localizationsListComponent = prepareLocalizationsListComponent();
	
	
	const wholeCountrySelectHandler = () => {
		updateExpertLocalizations(17);
		setWholeCountrySelectedStatus(!wholeCountrySelectedStatus);
	};
	
	
	return (
		<>
			<Form.Group className="select-whole-country" controlId="select-whole-country">
				<Form.Check
					type="checkbox"
					name="whole-country"
					value="Cała Polska"
					checked={wholeCountrySelectedStatus}
					disabled={apiRequestStatus}
					onChange={wholeCountrySelectHandler}
				/>
				<Form.Label className={(wholeCountrySelectedStatus ? "checked" : "") + (apiRequestStatus ? " disabled" : "")}>Cała Polska</Form.Label>
			</Form.Group>
			
			<div className="poland-country-map cssmap-container cssmap-430">
				<ul className="poland cssmap">
					{localizationsListComponent}
				</ul>
				{/*<Form.Control type="hidden" name="selected_region" value={selectedProvince} />*/}
			</div>
		</>
	);
	
};


// export class PolandMap extends Component {
//
//
// 		#provincesIDs = [...Array(16).keys()].map(i => i + 1);
//
// 		#provincesZones = [
// 			{localizationID: 1, localizationZones: 38, localizationName: "dolnośląskie"},
// 			{localizationID: 2, localizationZones: 37, localizationName: "kujawsko-pomorskie"},
// 			{localizationID: 3, localizationZones: 35, localizationName: "lubelskie"},
// 			{localizationID: 4, localizationZones: 30, localizationName: "lubuskie"},
// 			{localizationID: 5, localizationZones: 34, localizationName: "łódzkie"},
// 			{localizationID: 6, localizationZones: 24, localizationName: "małopolskie"},
// 			{localizationID: 7, localizationZones: 55, localizationName: "mazowieckie"},
// 			{localizationID: 8, localizationZones: 24, localizationName: "opolskie"},
// 			{localizationID: 9, localizationZones: 32, localizationName: "podkarpackie"},
// 			{localizationID: 10, localizationZones: 33, localizationName: "podlaskie"},
// 			{localizationID: 11, localizationZones: 38, localizationName: "pomorskie"},
// 			{localizationID: 12, localizationZones: 31, localizationName: "śląskie"},
// 			{localizationID: 13, localizationZones: 28, localizationName: "świętokrzyskie"},
// 			{localizationID: 14, localizationZones: 37, localizationName: "warmińsko-mazurskie"},
// 			{localizationID: 15, localizationZones: 50, localizationName: "wielkopolskie"},
// 			{localizationID: 16, localizationZones: 38, localizationName: "zachodniopomorskie"}
// 		];
//
//
// 		state = {
// 			selectedProvince: 0
// 		};
//
//
// 		#prepareProvincesList = () => {
//
// 			const selectedProvince = this.state.selectedProvince;
//
// 			return this.#provincesZones.map(province =>
// 				<li
// 					key={province.localizationID}
// 				    onClick={(e) => this.handleProvinceSelect(e, province.localizationID, province.localizationName)}
// 					title={province.localizationName}
// 					data-tip={province.localizationName}
// 				    className={`pl${province.localizationID} ${selectedProvince === province.localizationID || selectedProvince === 17 ? "active-region" : ""}`}
// 				>
// 					<span className="m">
// 						{this.#prepareProvincesZonesList(province.localizationZones)}
// 					</span>
// 					<span className="bg"></span>
// 					<ReactTooltip place="top" type="success" effect="float" />
// 				</li>
// 			);
//
// 		};
//
//
// 		handleProvinceSelect = (e, localizationID, localizationName) => {
// 			this.setState({
// 				selectedProvince: localizationID,
// 				selectedlocalizationName: localizationName,
// 			});
// 			this.props.updateProvinceNotifier(localizationID, localizationName);
// 		};
//
//
// 		handleWholeCountrySelect = (e) => {
// 			this.setState({
// 				selectedProvince: e.target.checked ? 17 : null
// 			});
// 			this.props.updateProvinceNotifier(e.target.checked ? 17 : null, "Cała Polska");
// 		};
//
//
// 		#prepareProvincesZonesList = (zonesNumber) => {
// 			return [...Array(zonesNumber).keys()].map(i =>
// 				<span key={i} className={`s${i + 1}`}></span>
// 			)
// 		};
//
//
// 		render() {
//
// 			const selectedProvince = this.state.selectedProvince;
//
// 			return (
// 				<>
// 					<Form.Group className="select-whole-country" controlId="select-whole-country">
// 						<Form.Check
// 							type="checkbox"
// 							name="whole-country"
// 							value="Cała Polska"
// 							onChange={this.handleWholeCountrySelect}
// 						/>
// 						<Form.Label className={this.state.selectedProvince === 17 ? "checked-element" : ""}>Cała Polska</Form.Label>
// 					</Form.Group>
//
// 					<div className="poland-country-map cssmap-container cssmap-430">
// 						<ul className="poland cssmap">
// 							{this.#prepareProvincesList()}
// 						</ul>
// 						<Form.Control type="hidden" name="selected_region" value={selectedProvince} />
// 					</div>
// 				</>
// 			);
//
// 		}
//
//
// }


		// class Class {
		//
		// }
