import React from "react";


import {Row} from "react-bootstrap";


import {MainContentLoader} from "@Components/Elements/Loaders";


import HomePageArticlesListItem from "@Components/Content/Pages/HomePage/Content/Articles/Article";


export const HomePageArticlesList = ({articlesList}) => {
	
	
	if (!articlesList) {
		return (
			<MainContentLoader
				loaderColor="#9DBF80"
			/>
		);
	}
	
	
	const articles = articlesList.splice(0, 4).map((article, i) =>
		article.text_article_publish_status ? <HomePageArticlesListItem
			key={i}
			article={{
				textArticleImage: article.text_article_image,
				textArticleSlug: article.text_article_slug,
				textArticleTitle: article.text_article_title,
				textArticleShortContent: article.text_article_short_content
			}}
			author={{
				textArticleAuthorID: article.text_article_author,
				textArticleAuthorCompany: article.text_article_author_company,
				textArticleAuthorName: article.text_article_author_name
			}}
		/> : null
	);
	
	
	return (
		<Row as="ul" className="articles-list">
			{articles}
		</Row>
	);
	
};


export default HomePageArticlesList;
