import React from "react";


import {NavLink} from "react-router-dom";


import {Col} from "react-bootstrap";


export const FooterSiteLogo = ({portalAddress = "/", portalTitle, portalImagesDir = "/images"}) => {
	
	return (
		<Col xs={12} md={3} className="footer-logo">
			
			<NavLink to={portalAddress} title={portalTitle}>
				<img src={`${portalImagesDir}/main-logo.png`} alt={portalTitle} className="footer-logo site-logo" />
			</NavLink>
		
		</Col>
	);
	
};


export default FooterSiteLogo;
