import React from "react";


import {NavLink} from "react-router-dom";

import {Col, Container, Row} from "react-bootstrap";


export const HomePageMainButtons = () => {
	
	
	return (
		
		<Container fluid={true} id="home-page-main-buttons">
			<Container>
				<Row className="text-center justify-content-center">
					<Col xs={12} md={{span: 4, offset: 4}} className="home-page-main-buttons-container find-expert-button">
						<NavLink to="/znajdz-eksperta" title="Znajdź Eksperta">
							Znajdź<br/>
							Firmę<br/>
							lub Eksperta
						</NavLink>
					</Col>
					{/*<Col xs={12} md={4} className="home-page-main-buttons-container">*/}
					{/*	<NavLink to="/dla-eksperta" title="Dla Eksperta">*/}
					{/*		Dla*/}
					{/*		<br/>*/}
					{/*		Eksperta*/}
					{/*	</NavLink>*/}
					{/*</Col>*/}
					{/*<Col xs={12} md={4} className="home-page-main-buttons-container">*/}
					{/*	<NavLink to="/dla-inwestora" title="Dla Inwestora">*/}
					{/*		Dla<br/>*/}
					{/*		Inwestora*/}
					{/*	</NavLink>*/}
					{/*</Col>*/}
				</Row>
			</Container>
		</Container>
	
	);
	
};


export default HomePageMainButtons;