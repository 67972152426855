import React from "react";
import {Alert, Col, Container, Row} from "react-bootstrap";
import {BounceLoader} from "react-spinners";


export const Http404ErrorComponent = ({errorMessage}) => {
	
	
	errorMessage = errorMessage || "nieprawidłowy adres URL lub błędne dane (sprawdź adres internetowy)";
	
	
	return (
		
		<Container fluid={true} id="site-primary-content" className="http-error-page">
			<Container id='site-primary-content-container'>
				<Row id="expert-card-page" className="main-container-content main-content">
					<Col
						as={Alert}
						xs={12}
						variant="danger"
						className="http-error http-404-error text-center"
					>
						
						{errorMessage}
						
					</Col>
				</Row>
			</Container>
		</Container>
		
	);
	
};


export default Http404ErrorComponent;