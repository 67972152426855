import React, {Component} from "react";
import {connect as CONNECT} from "react-redux";


import HtmlParser from "react-html-parser";


import {UI as UIContext} from "@Contexts";


import {Container, Row, Col, Form, Button} from "react-bootstrap";
import {ScrollToDomElement} from "@Components/_UI/Animations/Scrolling";
import {MainContentLoader} from "@Components/Elements/Loaders";


export class ContactPage extends Component {
	
	
		static contextType = UIContext;
	
	
		state = {
			contactPageData: null
		};
	
	
		constructor(props) {
			super(props);
			this.ref = React.createRef();
		}
	
	
		componentDidMount() {
			const MAIN_API_URL = this.context["URLS"]["API"]["REST_API_MAIN_URL"];
			const API_REQUEST_URL = `${MAIN_API_URL}/portal-pages/contact-page/1/`;
			ScrollToDomElement(this.ref.current, 500);
			fetch(API_REQUEST_URL)
				.then(response => response.json())
				.then(resource => this.setState({contactPageData: resource}));
		}
	
	
		render() {
			
			if (!this.state.contactPageData) {
				return <MainContentLoader />
			}
			
			
			const {
				contact_page_title: contactPageTitle,
				contact_page_main_content: contactPageText,
				contact_page_phone_number: contactPhoneNumber,
				contact_page_email_address: contactEmailAddress,
			} = this.state.contactPageData;
			
			
			return (
				<Container id="site-primary-content" ref={this.ref}>
					<Container id='site-primary-content-container'>
						<Row id="contact-page" className="main-container-content main-content">
							
							<h1 className="main-header">
								<span>{contactPageTitle}</span>
							</h1>
							
							<Col as="p" xs={12} className="main-content-description">
								{HtmlParser(contactPageText)}
							</Col>
							
							<Col as={Form} method="POST" xs={12} md={5} id="contact-form">
								<Form.Group className="form-row contact-form-email">
									<Form.Control
										type="email"
										name="contact_form_email"
										required="required"
										placeholder="Twój Email"
									/>
								</Form.Group>
								<Form.Group className="form-row contact-form-email">
									<Form.Control
										as="textarea"
										rows="3"
										name="contact_form_message"
										required="required"
										placeholder="Wiadomość"
									/>
								</Form.Group>
								<Form.Group className="form-row submit-row form-submit-row text-center">
									<Col xs={12}>
										<Button type="submit" variant="primary">wyślij wiadomość</Button>
									</Col>
								</Form.Group>
							</Col>
							
							<Col xs={12} md={{span: 6, offset: 1}} className="contact-page-data">
								<p className="contact-page-data-phone">
									<span className="data-name">Tel:</span>
									<span className="data-value">
										<a href={`tel:+48${contactPhoneNumber}`} title="Zadzwoń do nas">
				                            {contactPhoneNumber}
				                        </a>
									</span>
								</p>
								<p className="contact-page-data-email">
									<span className="data-name">Email:</span>
									<span className="data-value">
										<a href={`mailto:${contactEmailAddress}`} title="Napisz do nas">
				                            {contactEmailAddress}
				                        </a>
									</span>
								</p>
							</Col>
							
						</Row>
					</Container>
				</Container>
			);
		}
	
		
}


export default CONNECT(null, null)(ContactPage);
