import React from "react";


import {Alert, Col, Row} from "react-bootstrap";


import SingleSpecializationComponent from "@Components/Content/User/Logged/Profile/Specializations/SpecializationsList/SingleSpecialization";


export const SpecializationsListComponent = ({specializations = [], deleteRequestStatus, deleteApiRequest, premiumPositions = []}) => {

	
	if (!specializations || specializations.length < 1) {
		return (
			<Col as={Alert} xs={12} variant="warning">Brak specjalizacji</Col>
		);
	}
	
	
	// SORTED PREMIUM POSITIONS
	const specializationsPositionsList = {};
	if (premiumPositions.length > 0) {
		for (let position of premiumPositions) {
			if (position["experts_premium_position_reservation"]) {
				continue;
			}
			const {experts_premium_position_specialization_id: specialization} = position;
			if (!(specialization in specializationsPositionsList)) {
				specializationsPositionsList[specialization] = [];
			}
			specializationsPositionsList[specialization].push(position);
		}
	}
	
	
	const specializationsList = specializations.map((specialization, index) =>
		<SingleSpecializationComponent
			key={index}
			specialization={specialization}
			premiumPositions={specializationsPositionsList[specialization.id]}
			deleteRequestStatus={deleteRequestStatus}
			deleteApiRequest={deleteApiRequest}
		/>
	);
	
	
	return (
		<Row>
			
			<Col as="h4" xs={12} className="profile-section-header">
				<span className="title">Wybrane Specjalizacje</span>
			</Col>
			
			<Col as="ul" xs={12} className="expert-profile-specializations-list">
				{specializationsList}
			</Col>
			
		</Row>
	);
	
};


export default SpecializationsListComponent;
