import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";


import {LOG_USER_IN_ACTION} from "@Store/Actions/Authentication";


import {NavLink, Redirect} from "react-router-dom";

import {Container, Row, Form, Button, Col, Alert} from "react-bootstrap";

import {BeatLoader} from "react-spinners";

import {PageMainHeader} from "@Components/Elements/Headers";


export const LoginPageComponent = () => {

	
	const [loginFormUserEmail, changeLoginFormUserEmail] = useState("");
	const [loginFormUserPassword, changeLoginFormPassword] = useState("");
	
	const [loginFormSubmitAttempt, setLoginFormSubmitAttempt] = useState(false);
	const [loginAttempt, setLoginAttempt] = useState(false);
	const [loginFormErrors, changeLoginFormErrors] = useState({});
	
	const loggedUser = useSelector(state => state.authentication.authenticatedUserData);
	
	const logUserIn = useDispatch();
	
	// ALREADY LOGGED
	if (loggedUser) {
		return <Redirect to="/profil" />
	}
	
	const submitLoginForm = (e) => {
		
		e.preventDefault();
		setLoginFormSubmitAttempt(true);
		
		const formErrors = {};
		
		const emailRe = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
		if (!emailRe.test(loginFormUserEmail)) {
			formErrors["userEmail"] = "Nieprawidłowy adres Email";
		}
		if (loginFormUserPassword.length < 8) {
			formErrors["userPassword"] = "Podane Hasło jest zbyt krótkie";
		}
		
		if (!formErrors["userEmail"] && !formErrors["userPassword"]) {
			// API
			setLoginAttempt(true);
			loginAttemptRequest();
			return changeLoginFormErrors({});
		}
		
		return changeLoginFormErrors(formErrors);
		
	};
	
	
	const loginAttemptRequest = () => {
		fetch(
			`http://environmental.webprosdev.usermd.net/api/v1/experts/check-login/
			?user_email=${loginFormUserEmail}&user_password=${loginFormUserPassword}`
		).then(
			response => response.json()
		).then(
			resource => {
				setLoginAttempt(false);
				if (resource.error === true) {
					switch (resource.code) {
						case 41:
							return changeLoginFormErrors({userEmail: "Brak adresu email w Bazie Portalu"});
						case 42:
							return changeLoginFormErrors({userPassword: "Nieprawidłowe Hasło"});
						case 43:
							return;
					}
					return;
				}
				logUserIn(LOG_USER_IN_ACTION({expertID: resource.id, loginTime: new Date()}));
			}
		).catch(
			error => console.log(error)
		)
	};
	
	
	return (
		<Container id="site-primary-content">
			<Container id='site-primary-content-container'>
				<Row id="login-page" className="main-container-content main-content">
					
					
					<PageMainHeader headerText="Zaloguj się" />
					
					<Col as={Form} method="POST" onSubmit={submitLoginForm} id="login-form">
						
						{loginAttempt &&
						<Alert variant="success" className="text-center login-form-loader">
							<span>Logowanie...</span>
							<BeatLoader color="#9DBF80" />
						</Alert>
						}
						
						<Form.Group className="form-row login-form-email" controlId="login-form-email">
							<Form.Control
								name="login_form_email"
								value={loginFormUserEmail}
								onChange={(e) => changeLoginFormUserEmail(e.target.value)}
								placeholder="Email"
								className={loginFormSubmitAttempt ? loginFormErrors.userEmail ? "is-invalid" : "is-valid" : null}
							/>
							{loginFormSubmitAttempt && loginFormErrors.userEmail &&
							<Form.Label className="form-error-label">{loginFormErrors.userEmail}</Form.Label>
							}
						</Form.Group>
						
						<Form.Group className="form-row login-form-password" controlId="login-form-password">
							<Form.Control
								type="password"
								name="login_form_password"
								value={loginFormUserPassword}
								onChange={(e) => changeLoginFormPassword(e.target.value)}
								placeholder="Hasło"
								className={loginFormSubmitAttempt ? loginFormErrors.userPassword ? "is-invalid" : "is-valid" : null}
							/>
							{loginFormSubmitAttempt && loginFormErrors.userPassword &&
							<Form.Label className="form-error-label">{loginFormErrors.userPassword}</Form.Label>
							}
						</Form.Group>
						
						<Form.Group className="form-row login-form-password-reminder">
							<a href="#reset-password" title="Przypomnij Hasło" onClick={e => e.preventDefault()}>* zapomniałem hasła</a>
						</Form.Group>
						
						<Form.Group className="form-row login-form-register-link">
							<NavLink to="/rejestracja" title="Zarejestruj się">załóż konto</NavLink>
						</Form.Group>
						
						<Form.Group className="form-row submit-row">
							<Button type="submit" variant="primary">Zaloguj</Button>
						</Form.Group>
						
					</Col>
					
					
				</Row>
			</Container>
		</Container>
		
	);
	
};



export default LoginPageComponent;