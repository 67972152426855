import React from "react";

import {SQLDataConverter, ExpertReferences, ExpertSpecializations, ExpertLocalisations} from "@Contexts/_Tools";

import * as SPECIALIZATIONS from "@/__DATA/specializations.json";
import * as EXPERTS from "@/__DATA/experts.json";
import * as EXPERTS_LOCALISATIONS from "@/__DATA/experts_provinces.json";
import * as EXPERTS_SPECIALIZATIONS from "@/__DATA/experts_specializations.json";
import * as EXPERTS_REFERENCES from "@/__DATA/experts_references.json";
// import * as EXPERTS_PREMIUM_POSITIONS from "@/__DATA/experts_premium_positions.json";
import * as CITIES from "@/__DATA/cities.json";
import * as PROVINCES from "@/__DATA/provinces.json";


const EXPERTS_LIST = SQLDataConverter.formatCollectionData(EXPERTS);
const SPECIALIZATIONS_LIST = SQLDataConverter.formatCollectionData(SPECIALIZATIONS);
const CITIES_LIST = SQLDataConverter.formatCollectionData(CITIES);
const PROVINCES_LIST = SQLDataConverter.formatCollectionData(PROVINCES);

ExpertReferences(EXPERTS_LIST, EXPERTS_REFERENCES);
ExpertSpecializations(EXPERTS_LIST, EXPERTS_SPECIALIZATIONS);
ExpertLocalisations(EXPERTS_LIST, EXPERTS_LOCALISATIONS);

export const UI = React.createContext(null);
UI.displayName = "User Interface Global Data";

export const UIData = {
	"themeDetails": {
		"mainColors": {"primary": "#636363", "secondary": "#AAA9A9"},
		"specialColors": {"primary": "#9DBF80", "secondary": "#7B96B8"}
	},
	"API": {
	
	},
	"URLS": {
		"ROOT": "http://environmental.webprosdev.usermd.net/",
		"API": {"REST_API_MAIN_URL": "http://environmental.webprosdev.usermd.net/api/v1"},
		"MEDIA": {
			"URL": "/uploads",
			"EXPERTS": {
				"URL": "/users/experts/",
				"IMAGES": {
					"AVATARS": {"URI": "", "URL": "/avatars"}
				},
				"REFERENCES": {"URI": "", "URL": "/references"}
			}
		}
	}
};


export const DATA = React.createContext(null);

export const DATASETS = {
	specializationsList: SPECIALIZATIONS_LIST,
	expertsList: EXPERTS_LIST,
	citiesList: CITIES_LIST,
	provincesList: PROVINCES_LIST
};