import React, {useEffect, useState} from "react";


import {Alert, Button, Col, Form} from "react-bootstrap";


import {BeatLoader} from "react-spinners";
import {NavLink} from "react-router-dom";


export const SpecializationAddComponent = ({REST_API_ROOT_URL, accountType, expertID, expertSpecializationsList, addExpertSpecializationHandler}) => {
	
	
	const [specializationsList, getSpecializationsList] = useState(null);
	const [selectedSpecialization, changeSelectedSpecialization] = useState("0");
	
	
	useEffect(
		() => {
			fetch(REST_API_ROOT_URL + "/specializations/")
				.then(response => response.ok ? response.json() : null)
				.then(resource => getSpecializationsList(
					resource
						.filter(item => item.specialization_parent_id)
						.sort((item, nextItem) => item.specialization_parent_id > nextItem.specialization_parent_id)
				))
		},
		[]
	);
	
	
	const expertSpecializationsCollection = expertSpecializationsList.map(specialization => specialization.url);
	// API REQUEST STATUS
	const [addSpecializationRequestStatus, setAddSpecializationRequestStatus] = useState(false);
	
	
	const addSpecializationRequest = (e) => {
		
		e.preventDefault();
		
		setAddSpecializationRequestStatus(true);
		
		expertSpecializationsCollection.push(
			`http://environmental.webprosdev.usermd.net/api/v1/specializations/${selectedSpecialization}/`
		);
		
		fetch(
			`http://environmental.webprosdev.usermd.net/api/v1/experts/${expertID}/`, {
				headers: {'Accept': 'application/json, text/plain', 'Content-Type': 'application/json;charset=UTF-8'},
				method: "PATCH", body: JSON.stringify({
					"expert_specialization": expertSpecializationsCollection
				})
			})
			.then(response => response.ok ? response.json() : response.text())
			.then(resource => {
				setAddSpecializationRequestStatus(false);
				addExpertSpecializationHandler(resource["expert_specializations"]);
			})
			.catch(e => console.log(e));
		
	};
	
	
	// NO SPECIALIZATIONS
	if (!specializationsList) {
		return (
			<Col xs={12} className="text-center specialization-list-loader">
				<BeatLoader color="#9DBF80" />
			</Col>
		)
	}
	
	
	const expertSpecializationsIDsList = expertSpecializationsList.map(specialization => specialization.id);
	
	// SPECIALIZATIONS LIST OPTIONS
	const specializationsListOptions = specializationsList.map((specialization, index) => {
		if (expertSpecializationsIDsList.indexOf(specialization.id) !== -1) {
			return null
		}
		const {
			specialization_name: specializationName, specialization_parent_name: specializationParentName
		} = specialization;
		return (
			<option
				key={index}
				value={specialization.id}
			>{specializationName} ({specializationParentName})</option>
		);
	});
	
	
	if (expertSpecializationsList.length > 9 && +accountType !== 2) {
		return (
			<Col xs={12} className="maximum-expert-specialization">
				<Alert variant="danger" className="maximum-expert-specialization-information">
					Osiągnąłeś maksymalną liczbę specjalizacji. Aby zwiększyć ilość wykup konto premium.
				</Alert>
			</Col>
		)
	}
	
	if (expertSpecializationsList.length > 19) {
		return (
			<Col xs={12} className="maximum-expert-specialization">
				<Alert variant="danger" className="maximum-expert-specialization-information">
					Osiągnąłeś maksymalną liczbę specjalizacji
				</Alert>
			</Col>
		)
	}
	
	
	return (
	<>
		
		<Col
			as={Form}
			xs={12}
			className="add-expert-specialization-container text-center form-group-field"
			onSubmit={addSpecializationRequest}
		>
			<Form.Group>
				<Form.Control
					as="select"
					value={selectedSpecialization}
					onChange={e => changeSelectedSpecialization(e.target.value)}
					disabled={addSpecializationRequestStatus}
				>
					<option value="0">- wybierz -</option>
					{specializationsListOptions}
				</Form.Control>
			</Form.Group>
			<Form.Group className="submit-row">
				<Button type="submit" variant="primary">Dodaj</Button>
			</Form.Group>
		</Col>
		
		<Col xs={12} className="profile-go-to-shop text-center">
			<h3>Wypromuj swoje specjalizacje</h3>
			<NavLink to="/sklep" className="btn btn-primary">
				<span>Prejdź do sklepu</span>
			</NavLink>
		</Col>
		
	</>
	);
	
};


export default SpecializationAddComponent;
