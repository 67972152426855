import React from "react";


import {Row, Col, Alert} from "react-bootstrap";


export const ExpertCardSpecializationsComponent = ({environmentalExpertSpecializations}) => {

	
	const expertSpecializationsQuantity = environmentalExpertSpecializations.length;
	
	
	return (
		<Col xs={12} md={4} className="expert-card-specializations">
			
			<h4 className="expert-card-section-header section-header">
				<span>Specjalizacje Firmy</span>
			</h4>
			
			{expertSpecializationsQuantity > 0 ?
				<Row as="ul" className="expert-card-specializations-list">
					{environmentalExpertSpecializations.map(expertSpecialization =>
						<ExpertCardSpecializationComponent
							key={expertSpecialization.id}
							expertSpecialization={expertSpecialization}
						/>
					)}
				</Row>
			:
				<Row as={Alert} variant="warning" className="text-center">
					<Col xs={12}>Brak specjalizacji</Col>
				</Row>
			}
		
		</Col>
	);
	
};


		const ExpertCardSpecializationComponent = ({expertSpecialization}) => {
		
			
			const {
				specialization_name: specializationName
			} = expertSpecialization;
			
			
			return (
				<Col as="li" xs={12} md={6} className="specializations-list-item">
					<div title={specializationName} className="single-specialization">
						<span>{specializationName}</span>
					</div>
				</Col>
			);
			
		};


export default ExpertCardSpecializationsComponent;
