import React, {Component} from "react";
import {connect as CONNECT} from "react-redux";


import {LOG_USER_OUT_ACTION} from "@Store/Actions/Authentication";

import {NavLink} from "react-router-dom";

import {Col} from "react-bootstrap";


const navigationLinksCollection = [
	{url: "o-portalu", title: "O Portalu", type: 1},
	{url: "dla-eksperta", title: "Dla Eksperta", type: 1},
	{url: "dla-inwestora", title: "Dla Inwestora", type: 1},
	{url: "znajdz-eksperta", title: "Znajdź Eksperta", type: 1, cssClass: "find-expert-link"},
	{url: "artykuly", title: "Artykuły", type: 2},
	{url: "biblioteka-cyfrowa", title: "Biblioteka Cyfrowa", type: 3},
	{url: "kontakt", title: "Kontakt", type: 2},
	{url: "profil", title: "Profil", type: 3, cssClass: "profile-link"},
	{url: "sklep", title: "sklep", type: 3, cssClass: "shop-link"},
	{url: "wyloguj", title: "Wyloguj Się", type: 3, cssClass: "logout-link", logout: true},
	{url: "logowanie", title: "Zaloguj Się", type: 1, cssClass: "login-link"},
];


export class SiteNavigation extends Component {
	
	
		state = {
			logOut: false
		};
	
	
		handleLogout = (e) => {
			e.preventDefault();
			this.props.logUserOut();
			this.setState({
				logOut: true
			});
		};
	
	
		render() {
			
			
			const authenticatedUser = this.props.authenticatedUser;
			
			
			const navigationLinks = navigationLinksCollection.map((link, i) => {
				
					if ((authenticatedUser && link.type === 1) || (!authenticatedUser && link.type === 3)) {
						return null;
					}
					
					return (
						<li className="site-navigation-menu-item" key={i} >
							<NavLink
								exact
								to={`/${link.url}`}
								title={link.title}
								className={link.cssClass}
								onClick={link.logout ? this.handleLogout : null}
							>
								{link.title}
							</NavLink>
						</li>
					);
					
				}
			);
		
			return (
				<Col as="nav" xs={12} md={9} className="site-navigation">
					<a href="#navigation" className="nav-visibility-switcher">
						<span className="glyphicon glyphicon-align-justify"></span>
					</a>
					<ul className="site-navigation-menu">
						{navigationLinks}
					</ul>
				</Col>
			);
		}
	
		
}


const mapStateToProps = (state) => {
	return {
		authenticatedUser: state.authentication.authenticatedUserData
	}
};


const mapDispatchToProps = (dispatch) => {
	return {
		logUserOut: () => dispatch(LOG_USER_OUT_ACTION())
	}
};


export default CONNECT(mapStateToProps, mapDispatchToProps)(SiteNavigation);