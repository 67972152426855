export const LOG_USER_IN = "LOG_USER_IN";
export const LOG_USER_OUT = "LOG_USER_IN";


export const LOG_USER_IN_ACTION = (userData) => {
	return {
		type: LOG_USER_IN,
		authenticatedUserData: userData
	}
};


export const LOG_USER_OUT_ACTION = (userData) => {
	return {
		type: LOG_USER_OUT,
		authenticatedUserData: null
	}
};