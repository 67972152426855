import React, {useContext, useState, useEffect} from "react";
import {useSelector} from "react-redux";


import {UI as UIContext} from "@Contexts";


import {Redirect} from "react-router-dom";


import {Container, Row} from "react-bootstrap";


import {PageMainHeader} from "@Components/Elements/Headers";
import {MainContentLoader} from "@Components/Elements/Loaders";


import ServicesShopContentComponent from "@Components/Content/User/Logged/Shop/Content";


export const ServicesShopPageComponent = () => {
	
	
	const UI = useContext(UIContext);
	
	
	const REST_API_URL = UI["URLS"]["API"]["REST_API_MAIN_URL"];
	
	
	const LOGGED_EXPERT = useSelector(state => state.authentication.authenticatedUserData);
	
	const [loggedEnvironmentalExpert, getLoggedEnvironmentalExpert] = useState(null);
	
	useEffect(() => {
		if (!LOGGED_EXPERT) {
			return false;
		}
		fetch(`${REST_API_URL}/experts/${LOGGED_EXPERT["expertID"]}/`)
			.then(
				response => response.ok === true ? response.json() : null
			)
			.then(resource => getLoggedEnvironmentalExpert(resource))
			.catch(e => console.log(e));
	}, []);
	
	
	if (!LOGGED_EXPERT) {
		return <Redirect to="/logowanie" />
	}
	
	
	const loaderColor = UI["themeDetails"]["specialColors"]["primary"];
	
	
	return (
		<Container fluid={true} id="site-primary-content" className="services-shop-page">
			<Container id='site-primary-content-container'>
				<Row id="expert-profile-page" className="main-container-content main-content">
					
					<PageMainHeader headerText="Sklep" />
					
					{!loggedEnvironmentalExpert ?
						
						<MainContentLoader loaderColor={loaderColor} />
						
						:
						
						<ServicesShopContentComponent
							REST_API_URL={REST_API_URL}
							loggedEnvironmentalExpert={loggedEnvironmentalExpert}
						/>
						
					}
				
				</Row>
			
			</Container>
		</Container>
	);
	
};


export default ServicesShopPageComponent;
