export class SQLDataConverter {

	
	static formatCollectionData = (collection) => {
		
		const {columns, rows} = collection.default;

		const fieldsNames = columns.map(column => column.name);

		const collectionObject = rows.map(row => {
			const object = {};
			for (let [index, data] of row.entries()) {
				object[fieldsNames[index]] = data;
			}
			return [object.id, object];
		});
		
		const collectionList = {};
		
		for (let object of collectionObject) {
			collectionList[object[0]] = object[1];
		}
		
		collectionList[Symbol.iterator] = function*() {
			const expertsIDs = Object.keys(collectionList);
			for (let expertsID of expertsIDs) {
				yield collectionList[expertsID];
			}
		};
		
		return collectionList;
		
	};
	
	

}


export const ExpertReferences = (experts, references) => {
	
	const {rows, columns} = references.default;
	
	// console.log(experts, rows, columns)
	
	for (let expert of experts) {
		expert["expert_references"] = [];
	}
	
	for (let row of rows) {
		const reference = {};
		for (let [index, data] of row.entries()) {
			reference[columns[index].name] = data;
		}
		experts[reference["expert_reference_expert_id"]]["expert_references"].push(reference);
	}

};


export const ExpertSpecializations = (experts, specializations) => {
	
	const {rows, columns} = specializations.default;
	
	// console.log(experts, rows, columns)
	
	for (let expert of experts) {
		expert["expert_specializations"] = [];
	}
	
	for (let row of rows) {
		const specialization = {};
		for (let [index, data] of row.entries()) {
			specialization[columns[index].name] = data;
		}
		experts[specialization["expert_id"]]["expert_specializations"].push(specialization["specialization_id"]);
	}
	
};


export const ExpertLocalisations = (experts, localisations) => {
	
	const {rows, columns} = localisations.default;
	
	// console.log(experts, rows, columns)
	
	for (let expert of experts) {
		expert["expert_localisations"] = [];
	}
	
	for (let row of rows) {
		const localisation = {};
		for (let [index, data] of row.entries()) {
			localisation[columns[index].name] = data;
		}
		experts[localisation["expert_id"]]["expert_localisations"].push(localisation["province_id"]);
	}
	
};
