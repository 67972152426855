import React, {useState} from "react";


import {Col, Row} from "react-bootstrap";


export const ShopContentServicesComponent = ({servicesOrderContent, expertAccountStatus, expertNewsletterService, orderProcessHandler}) => {
	
	
	const {accountData, newsletterData} = servicesOrderContent || {};
	
	
	/*
		ACCOUNT TYPE
	 */
	
	const {
		serviceStatus: expertActiveStatus, serviceValue: expertAccountType, serviceDate: expertAccountValidDate
	} = expertAccountStatus;
	
	const [selectedAccountType, setSelectedAccountType] = useState(
		accountData ? accountData["accountType"] : (accountData ? accountData["accountType"] : 0)
	);
	
	const changeSelectedAccountType = (selectedType) => {
		setSelectedAccountType(selectedType);
		const newSelectedAccountTypeDate = selectedType ? formatEndDate() : null;
		setSelectedAccountTypeDate(newSelectedAccountTypeDate);
		const newNewsletterOrderStatus = selectedType ? newsletterOrderStatus : 0;
		const newNewsletterServiceDate = newNewsletterOrderStatus ? newsletterServiceDate : null;
		setNewsletterOrderStatus(newNewsletterOrderStatus);
		setNewsletterServiceDate(newNewsletterServiceDate);
		orderProcessHandler("account", {"account": [selectedType, newSelectedAccountTypeDate], newsletter: [newNewsletterOrderStatus, newNewsletterServiceDate]});
	};
	
	const [selectedAccountTypeDate, setSelectedAccountTypeDate] = useState(
		accountData ? accountData["accountDate"] : null
	);
	
	/*
		NEWSLETTER
	 */
	
	const {
		serviceStatus: expertNewsletterServiceStatus, serviceDate: expertNewsletterServiceValidDate
	} = expertNewsletterService;
	
	const [newsletterOrderStatus, setNewsletterOrderStatus] = useState(
		newsletterData ? 1 : (expertNewsletterServiceStatus ? 1 : 0)
	);
	
	const [newsletterServiceDate, setNewsletterServiceDate] = useState(
		newsletterData ? newsletterData["newsletterDate"] : (expertNewsletterServiceStatus ? expertNewsletterServiceValidDate : null)
	);
	
	const changeNewsletterOrderStatus = () => {
		const newStatus = newsletterOrderStatus === 1 ? 0 : 1;
		const newDate = newStatus ? formatEndDate() : null;
		setNewsletterOrderStatus(newStatus);
		setNewsletterServiceDate(newDate);
		orderProcessHandler("account", {account: [selectedAccountType, selectedAccountTypeDate], newsletter: [newStatus, newDate]});
	};
	
	
	const formatEndDate = () => {
		const d = new Date();
		const nd = new Date(d.setFullYear(d.getFullYear() + 1));
		const day = nd.getDate() + 1;
		const month = nd.getMonth() + 1;
		const year = nd.getFullYear();
		return `${day < 10 ? `0${day}` : day}-${month < 10 ? `0${month}` : month}-${year}`;
	};
	
	
	return (
		<Col xs={12} className="services-shop-page-services">
			
			<Row className="shop-page-container">
			
				<Col xs={12} className="cart-list-row cart-services-row cart-header-row">
					<Row>
						<Col xs={3} lg={6} className="row-title row-cell">
							<Col className="row-title cell-content">
								<span>Wybierz usługę</span>
							</Col>
						</Col>
						<Col xs={3} lg={2} className="standard-content row-cell">
							<Col className="cell-content">
								<span>Standard</span>
							</Col>
						</Col>
						<Col xs={3} lg={2} className="premium-content row-cell">
							<Col className="cell-content">
								<span>Premium</span>
							</Col>
						</Col>
						<Col xs={3} lg={2} className="row-cell">
							<Col className="service-date service-date-head cell-content">
								<span>Data Ważności</span>
							</Col>
						</Col>
					</Row>
				</Col>
				
				<Col xs={12} className="cart-list-row cart-services-row cart-item-row account-type-row">
					<Row>
						
						<Col xs={3} lg={6} className="row-cell">
							<Col className="row-title cell-content">
								<span>Konto</span>
							</Col>
						</Col>
						
					{+expertAccountType < 2 ?
					<>
					{/* STANDARD */}
						{!expertActiveStatus && +expertAccountType === 1 ?
						<Col xs={3} lg={2} className={"standard-content row-cell"}>
							<Col xs={12} className={"cell-content" + (selectedAccountType === 1 ? " selected" : "")}>
								<label>
									<span>600</span>
									<input
										type="checkbox"
										value={1}
										checked={selectedAccountType === 1}
										onChange={() => changeSelectedAccountType(selectedAccountType === 1 ? null : 1)}
										disabled={expertActiveStatus || +expertAccountType === 2}
									/>
								</label>
							</Col>
						</Col>
						:
						<Col xs={3} lg={2} className="standard-content already-bought row-cell">
							<Col xs={12} className="cell-content">
								Aktywne do <span className="col-value">{expertAccountValidDate}</span>
							</Col>
						</Col>
						}
						{/* /STANDARD */}
						{/* PREMIUM */}
						<Col xs={3} lg={2} className={"premium-content row-cell"}>
							<Col xs={12} className={"cell-content" + (selectedAccountType === 2 ? " selected" : "")}>
								<label>
									<span>900</span>
									<input
										type="checkbox"
										value={2}
										checked={selectedAccountType === 2}
										onChange={() => changeSelectedAccountType(selectedAccountType === 2 ? null : 2)}
										disabled={(+expertAccountType === 2)}
									/>
								</label>
							</Col>
						</Col>
						{/* PREMIUM */}
						<Col xs={3} lg={2} className="row-cell">
							<Col xs={12} className="cell-content service-date">
								<span>{selectedAccountTypeDate}</span>
							</Col>
						</Col>
						
					</>
						:
						<Col xs={9} lg={6} className="premium-content already-bought row-cell">
							<Col xs={12} className="cell-content">
								<span className="col-label">Premium Aktywne do</span>
								<span className="col-value">{expertAccountValidDate}</span>
							</Col>
						</Col>
					}
					</Row>
				</Col>
				
				{/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
					NEWSLETTER
				*/}
				
				<Col xs={12} className="cart-list-row cart-services-row cart-item-row newsletter-order-row">
					<Row>
						<Col xs={3} lg={5} className="row-cell">
							<Col xs={12} className="row-title cell-content">
								<span>Newsletter z Przetargami</span>
							</Col>
						</Col>
					{expertNewsletterServiceStatus ?
						<Col xs={9} lg={7} className="row-cell">
							<Col xs={12} className="cell-content">
								<span className="col-label">Aktywny do</span>
								<span className="col-value">{expertNewsletterServiceValidDate}</span>
							</Col>
						</Col>
						:
					<>
						<Col xs={3} lg={1} className="row-cell">
							<Col xs={12} className="select-service cell-content" title="zaznacz">
								<label className={(newsletterOrderStatus === 1 && selectedAccountType ? "selected" : "") + (!selectedAccountType ? "disabled" : "")}>
									<input
										type="checkbox"
										value={2}
										checked={newsletterOrderStatus === 1 && selectedAccountType}
										onChange={() => changeNewsletterOrderStatus()}
										disabled={!selectedAccountType}
									/>
								</label>
							</Col>
						</Col>
						<Col xs={3} lg={2} className="standard-content row-cell">
							<Col xs={12} className={"cell-content" + (newsletterOrderStatus === 1 && selectedAccountType === 1 ? " selected" : "")}>
								<label>500</label>
							</Col>
						</Col>
						<Col xs={3} lg={2} className="premium-content row-cell">
							<Col xs={12} className={"cell-content" + (newsletterOrderStatus === 1 && selectedAccountType === 2 ? " selected" : "")}>
								<label>400</label>
							</Col>
						</Col>
						<Col xs={3} lg={2} className="row-cell">
							<Col xs={12} className="service-date cell-content">
								<span>{newsletterServiceDate}</span>
							</Col>
						</Col>
					</>
					}
					
					
					</Row>
				</Col>
			
			</Row>
		
		</Col>
	);
	
};


export default ShopContentServicesComponent;
