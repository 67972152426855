import React from 'react';
import ReactDOM from 'react-dom';
import {Provider as ReduxProvider} from "react-redux";


import ApplicationStore from "@Store";
import {UI as UIContext, UIData, DATA as DATAContext, DATASETS} from "@Contexts";

import {BrowserRouter as Router} from "react-router-dom";

import Application from "./Application";

import * as serviceWorker from './serviceWorker';


ReactDOM.render(
    <React.StrictMode>
        <ReduxProvider store={ApplicationStore}>
            <UIContext.Provider value={UIData}>
                <DATAContext.Provider value={DATASETS}>
                    <Router>
                        <Application />
                    </Router>
                </DATAContext.Provider>
            </UIContext.Provider>
        </ReduxProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
