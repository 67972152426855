import React, {Component} from "react";


import {UI} from "@Contexts";


import {BeatLoader} from "react-spinners";
import {FaAngleDown} from "react-icons/all";


export class SpecializationsList extends Component {
	
	
		static contextType = UI;
	
	
		state = {
			specializationsList: null,
			selectedMainSpecialization: 0,
			selectedSubSpecialization: 0
		};
		
		
		changeCategoryHandler = (e, specializationID, subCategory = false, specializationName) => {
			
			e.preventDefault();
			
			const fieldToUpdate = subCategory === true ? "selectedSubSpecialization" : "selectedMainSpecialization";
			
			if (this.state[fieldToUpdate] === specializationID) {
				specializationID = 0;
			}
			
			this.setState({
				[fieldToUpdate]: specializationID
			});
			
			if (fieldToUpdate === "selectedSubSpecialization") {
				this.props.updateSpecializationNotifier(specializationID, specializationName);
			}
			
		};
	
		
		componentDidMount() {
			fetch("http://environmental.webprosdev.usermd.net/api/v1/specializations/")
				.then(response => response.json())
				.then(resource => this.prepareSpecializationsList(resource));
		}
	
	
	 prepareSpecializationsList = (specializations) => {
		
		if (specializations.error) {
			return null;
		}

		const specializationsList = specializations.filter(
			specialization => !specialization.specialization_parent_id && specialization.specialization_active_status
		);
		
		const mainSpecializationsList = {};
		
		for (let specialization of specializationsList) {
			
			const {
				id: specializationID, specialization_active_status: specializationActiveStatus,
				specialization_name: specializationName, specialization_description: specializationDescription,
				specialization_sort_order_priority: specializationSortOrderPriority
			} = specialization;
			
			mainSpecializationsList[specializationID] = {
				"specializationID": specializationID,
				"specializationName": specializationName,
				"specializationActiveStatus": specializationActiveStatus,
				"specializationDescription": specializationDescription,
				"specializationSortOrderPriority": specializationSortOrderPriority,
				"subSpecializations": {}
			};
			
		}
		
		for (let specialization of specializations) {
			
			const {
				id: specializationID, specialization_active_status: specializationActiveStatus,
				specialization_parent_id: specializationParentID,
				specialization_name: specializationName, specialization_description: specializationDescription,
				specialization_sort_order_priority: specializationSortOrderPriority
			} = specialization;
			
			if (!specializationParentID || !specializationActiveStatus) {
				continue;
			}
			
			mainSpecializationsList[specializationParentID]["subSpecializations"][specializationID] = {
				"specializationID": specializationID,
				"specializationName": specializationName,
				"specializationActiveStatus": specializationActiveStatus,
				"specializationDescription": specializationDescription,
				"specializationSortOrderPriority": specializationSortOrderPriority,
			};
			
		}
		
		this.setState({
			specializationsList: mainSpecializationsList
		});
		
	};
	
	
		render() {
			
			const themeColors = this.context.themeDetails.specialColors;
			
			const {
				specializationsList, selectedMainSpecialization, selectedSubSpecialization
			} = this.state;
			
			const specializationsListContent = !specializationsList
				?
				<li className="list-loader">
					<BeatLoader color={themeColors.primary} />
				</li>
				:
				Object.keys(this.state.specializationsList).map((specializationID, i) => {
					
					const isSelected = (specializationID === selectedMainSpecialization);
					
					const {
						specializationName,
						specializationActiveStatus,
						subSpecializations
					} = this.state.specializationsList[specializationID];

					if (specializationActiveStatus === false) {
						return null;
					}
					
					return (
						<MainSpecializationCategory
							key={i}
							specializationID={specializationID}
							specializationName={specializationName}
							subSpecializations={isSelected ? subSpecializations : []}
							specializationSelectHandler={this.changeCategoryHandler}
							specializationSelectStatus={isSelected}
							selectedSubSpecialization={selectedSubSpecialization}
						/>
					);
					
				}
			);
			
			return (
				<ul className="find-expert-specializations-list">
					{specializationsListContent}
				</ul>
			);
			
		}
	
		
}


		const MainSpecializationCategory = (
			{specializationID, specializationName, subSpecializations, selectedSubSpecialization, specializationSelectHandler, specializationSelectStatus}
		) => {
	
			const specializationSubCategories =
				subSpecializations.length === 0
				?
					null
				:
					<SpecializationSubCategoriesList
						specializations={subSpecializations}
						specializationsParent={specializationID}
						specializationSelectHandler={specializationSelectHandler}
						selectedSubSpecialization={selectedSubSpecialization}
					/>
			;
	
			return (
				<li className={`find-expert-specializations-list-item`}>
					<a href={`#${specializationID}`}
					   title={specializationName}
					   data-category={specializationID}
					   className={`main-category-item ${specializationSelectStatus ? "active-element" : ""}`}
					   onClick={e => specializationSelectHandler(e, specializationID)}
					>
						<span>{specializationName}</span>
						<FaAngleDown />
					</a>
					{specializationSubCategories}
				</li>
			);
	
		};


				const SpecializationSubCategoriesList = ({specializations, specializationSelectHandler, selectedSubSpecialization}) => {

					const subCategoriesList = Object.keys(specializations).map(specializationID => {
						
							const {
								specializationName,
								specializationActiveStatus,
								subSpecializations
							} = specializations[specializationID];
							
							if (specializationActiveStatus === false) {
								return null;
							}
						
							return (
								<SpecializationSubCategory
									key={specializationID}
									specializationID={specializationID}
									specializationName={specializationName}
									subSpecializations={subSpecializations}
									specializationSelectHandler={specializationSelectHandler}
									selectedSubSpecialization={selectedSubSpecialization}
								/>
							);
							
						}
					);
					
					return (
						<ul className="item-sub-categories">
							{subCategoriesList}
						</ul>
					);
					
				};
				
				
						const SpecializationSubCategory = ({specializationID, specializationName, subSpecializations, specializationSelectHandler, selectedSubSpecialization}) => {
							
							let specializationSubCategories = null;
							
							if (subSpecializations) {
								specializationSubCategories = Object.keys(subSpecializations).map(subSpecializationID =>
									<li>{subSpecializations[subSpecializationID].specializationName}</li>
								);
								specializationSubCategories =
									<ul className="sub-item-sub-categories">
										{specializationSubCategories}
									</ul>
							}
							
							return (
								<li
									className={`sub-categories-item ${subSpecializations ? "has-sub-categories" : ""}`}
								>
									<a href={`#${specializationID}`}
									   title={specializationName}
									   data-category={specializationID}
									   data-parent={1}
									   className={`sub-category-item ${specializationID === selectedSubSpecialization ? "active-element" : ""}`}
									   onClick={e => specializationSelectHandler(e, specializationID, true, specializationName)}
									>
										<span>{specializationName}</span>
									</a>
									{specializationSubCategories}
								</li>
							);
							
						};


export default SpecializationsList;