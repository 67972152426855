import React, {useEffect, useState} from "react";


import {Col, Form, Button} from "react-bootstrap";

import bsCustomFileInput from 'bs-custom-file-input'


export const ExpertReferencesUploadFormComponent = ({environmentalExpertID, environmentalExpertSpecializations, addReferenceListHandler: addReferenceFileHandler}) => {

	
	const [selectedReferenceSpecialization, changeSelectedReferenceSpecialization] = useState("0");
	const [referenceTitle, changeReferenceTitle] = useState("");
	const [expertReferenceFile, changeExpertReferenceFile] = useState(undefined);
	
	const [formMessage, setFormMessage] = useState({messageType: null, messagesList: []});
	const [fileSendStatus, setFileSendStatus] = useState(false);
	
	
	useEffect(
		() => {bsCustomFileInput.init()}, []
	);
	
	
	const formSubmitHandler = (e) => {
		
		e.preventDefault();
		
		const formErrors = {messageType: "error", messagesList: []};
		
		if (!selectedReferenceSpecialization || selectedReferenceSpecialization === "0") {
			formErrors["messagesList"].push("Nie wybrano Specializacji");
		}
		
		if (!referenceTitle || referenceTitle.trim() < 5) {
			formErrors["messagesList"].push("Nie podano tytułu referencji");
		}
		
		if (!expertReferenceFile) {
			formErrors["messagesList"].push("Nie dodano pliku");
		}
		
		if (formErrors.messagesList.length > 0) {
			setFormMessage(formErrors);
			return false;
		}
		
		setFileSendStatus("pending");
		uploadReferenceFile();
		
	};
	
	
	const uploadReferenceFile = () => {
		
		const formData = new FormData();
		
		formData.append("expert_reference_expert", `http://environmental.webprosdev.usermd.net/api/v1/experts/${environmentalExpertID}/`);
		formData.append("expert_reference_specialization", `http://environmental.webprosdev.usermd.net/api/v1/specializations/${selectedReferenceSpecialization}/`);
		formData.append("expert_reference_title", referenceTitle);
		formData.append("expert_reference_file", expertReferenceFile, expertReferenceFile.name);
		
		fetch("http://environmental.webprosdev.usermd.net/api/v1/experts-data/references/", {
			method: "POST",
			body: formData,
			// mode: 'no-cors'
		})
			.then(response => response.ok ? response.json() : {error: true, message: response.text()})
			.then(resource => {
				addReferenceFileHandler(resource);
				setFileSendStatus(false);
			})
			.catch(error => {
				setFormMessage({
					messageType: "error",
					messagesList: ["Nie udało się załadować pliku (może być zbyt duży)"]
				});
				setFileSendStatus(false);
			});
		
	};
	
	
	const specializationsList = environmentalExpertSpecializations.length > 0 ?
		environmentalExpertSpecializations.map(expertSpecialization =>
			<ExpertSpecializationOptionComponent
				key={expertSpecialization.id}
				expertSpecialization={expertSpecialization}
			/>
		)
		:
		null
	;
	
	
	return (
		
		<Col
			as={Form}
			xs={12}
			encType="'multipart/form-data'"
			onSubmit={(e) => (formSubmitHandler(e))}
			className="upload-reference-file-form expert-profile-upload-file-form"
		>
			
			{formMessage.messagesList.length > 0 &&
			<ul className={"upload-library-form-message " + formMessage.messageType}>
				{formMessage.messagesList.map((message, index) =>
					<li key={index}>{message}</li>
				)}
			</ul>
			}
			
			<Form.Group controlId="upload-reference-specialization">
				<Form.Label>Specializacja:</Form.Label>
				<Form.Control
					as="select"
					name="expert_file_add_relation"
					value={selectedReferenceSpecialization}
					onChange={(e) => changeSelectedReferenceSpecialization(e.target.value)}
					disabled={fileSendStatus === "pending"}
					custom
				>
					<option value="0">- wybierz -</option>
					{specializationsList}
				</Form.Control>
			</Form.Group>
			
			<Form.Group controlId="upload-reference-title">
				<Form.Label>Tytuł:</Form.Label>
				<Form.Control
					// required
					name="library_file_title"
					value={referenceTitle}
					onChange={(e) => changeReferenceTitle(e.target.value)}
					disabled={fileSendStatus === "pending"}
					className="library-file-title-input"
				/>
			</Form.Group>
			
			<Form.Group controlId="library-file-file custom-file">
				<Form.File
					name="library_file_file"
					onChange={e => changeExpertReferenceFile(e.target.files[0])}
					// required
					label={expertReferenceFile ? expertReferenceFile.name : "wybierz plik"}
					className="library-file-file-input"
					disabled={fileSendStatus === "pending"}
					custom
				/>
			</Form.Group>
			
			<Form.Group className="upload-file-submit">
				<Button type="submit" variant="primary">Dodaj</Button>
			</Form.Group>
			
		</Col>
		
	);
	
};


		const ExpertSpecializationOptionComponent = ({expertSpecialization}) => {
			
			const {
				id: specializationID,
				specialization_name: specializationName
			} = expertSpecialization;
			
			return (
				<option value={specializationID}>
					{specializationName}
				</option>
			);
			
		};


export default ExpertReferencesUploadFormComponent;
