import React, {useContext, useEffect, useRef, useState} from "react";

import ReactHtmlParser from 'react-html-parser';


import {UI as UIContext} from "@Contexts";


import {ScrollToDomElement} from "@Components/_UI/Animations/Scrolling";


import {Col, Container, Row} from "react-bootstrap";

import {MainContentLoader} from "@Components/Elements/Loaders";


export const PrivacyPolicyPage = () => {
	
	
	const [privacyPolicyPageContent, getPrivacyPolicyPageContent] = useState(null);
	
	const UI = useContext(UIContext);
	const MAIN_API_URL = UI["URLS"]["API"]["REST_API_MAIN_URL"];
	
	const API_REQUEST_URL = `${MAIN_API_URL}/text-pages/polityka-prywatnosci/`;
	
	useEffect(
		() => {
			fetch(API_REQUEST_URL)
				.then(response => response.json())
				.then(resource => {
					getPrivacyPolicyPageContent(resource);
					ScrollToDomElement(domRef.current, 1000);
				})
		},
		[]
	);
	
	const domRef = useRef();
	
	useEffect(() => {
		ScrollToDomElement(domRef.current, 1000)
	}, []);
	
	
	if (!privacyPolicyPageContent) {
		return (
			<MainContentLoader />
		);
	}
	
	
	const {
		text_page_title: pageTitle, text_page_content: pageContent
	} = privacyPolicyPageContent;
	
	
	return (
		
		<Container id="site-primary-content" ref={domRef}>
			<Container id='site-primary-content-container'>
				<Row as="article" id="privacy-policy-page" className="main-container-content main-content">
					
					<Col as="h1" xs={12} className="main-header">
						<span>{pageTitle}</span>
					</Col>
					
					<Col xs={12} className="text-page-content about-portal-page-content">
						{ReactHtmlParser(pageContent)}
					</Col>
				
				</Row>
			</Container>
		</Container>
	
	);
	
};


export default PrivacyPolicyPage;