import React, {useContext, useEffect, useRef, useState} from "react";

import ReactHtmlParser from "react-html-parser";


import {UI as UIContext} from "@Contexts";


import {Col, Container, Row} from "react-bootstrap";


import {ScrollToDomElement} from "@Components/_UI/Animations/Scrolling";
import {MainContentLoader} from "@Components/Elements/Loaders";
import {PageMainHeader, SectionHeader} from "@Components/Elements/Headers";


import CONTENT from "./_CONTENT";

export const ForInvestorPage = () => {
	
	
	const [activeMainContentElement, setActiveMainContentElement] = useState(null);
	
	const [activeHowToElement, setActiveHowToElement] = useState(null);
	
	const [activeKnowledgeBaseElement, setActiveKnowledgeBaseElement] = useState(null);
	const [activeKnowledgeBaseElementContent, setActiveKnowledgeBaseElementContent] = useState(null);
	
	
	const [forInvestorPageData, getForInvestorPageData] = useState(null);
	
	
	const domRef = useRef();
	
	const UI = useContext(UIContext);
	const MAIN_API_URL = UI["URLS"]["API"]["REST_API_MAIN_URL"];
	
	const API_REQUEST_URL = `${MAIN_API_URL}/portal-pages/for-investor-page/1/`;
	
	useEffect(() => {
		ScrollToDomElement(domRef.current, 500);
		fetch(API_REQUEST_URL)
			.then(response => response.json())
			.then(resource => getForInvestorPageData(resource));
	}, []);
	
	
	if (!forInvestorPageData) {
		return <MainContentLoader />
	}
	
	
	const {
		for_investor_page_title: forInvestorTitle, for_investor_page_main_content: forInvestorContent,
		for_investor_page_faq_section_header: faqHeader, for_investor_page_faq_section_label: faqLabel, for_investor_page_faq_elements: faqElements,
		for_investor_page_how_to_section_label: howToLabel, for_investor_page_how_to_elements: howToElements
	} = forInvestorPageData;
	
	
	const forInvestorPageSections = {
		knowledge_base: {
			header: faqHeader,
			label: faqLabel,
			elements: faqElements
		},
		how_to_base: {
			header: null,
			label: howToLabel,
			elements: howToElements
		}
	};
	
	const forInvestorPageSectionsNames = Object.keys(forInvestorPageSections);

	
	return (
		<>
			
			<Container fluid={true} id="site-primary-content" className="for-investor-site-content">
				<Container id="site-primary-content-container">
					<Row id="for-investor-page-main-content" ref={domRef}>
					
						<PageMainHeader headerText={forInvestorTitle} />
						
						<Col xs={12} className="page-text-content for-investor-page-description">
							<Row>
								{ReactHtmlParser(forInvestorContent)}
							</Row>
						</Col>
						
						<Col xs={12} className="for-investor-page-content-main-switcher">
							<Row>
							{forInvestorPageSectionsNames.map((sectionName, i) =>
								<Col
									key={i} xs={12} md={{span: 4, offset: 1}}
									className="for-investor-page-content-switcher-button"
								>
									<a
										href={`#${sectionName}`}
										title={forInvestorPageSections[sectionName]["label"]}
										className={activeMainContentElement === sectionName ? "active-element" : null}
										onClick={
											(e) => {
												e.preventDefault();
												setActiveMainContentElement(activeMainContentElement === sectionName ? null : sectionName);
												setActiveHowToElement(null);
												setActiveKnowledgeBaseElement(null);
											}
										}
									>
										<span>{forInvestorPageSections[sectionName]["label"]}</span>
									</a>
								</Col>
							)
							}
							</Row>
						</Col>
						
						{activeMainContentElement === "how_to_base" &&
						<Col xs={12} className="for-investor-page-content-switcher">
							<Row>
								{Object.values(CONTENT.PRIMARY.content_switcher.content_switcher_secondary_buttons).map((item, i) =>
									<Col key={i} xs={12} md={4} className="for-investor-page-content-switcher-button">
										<a
											href={`#${item.id}`}
											title={item.title}
											className={activeHowToElement === item.id ? "active-element" : null}
											onClick={
												(e) => {
													e.preventDefault();
													setActiveHowToElement(activeHowToElement === item.id ? null : item.id);
												}
											}
										>
											<span>{item.title}</span>
										</a>
									</Col>
								)
								}
							</Row>
						</Col>
						}
						
						{activeHowToElement &&
						<Col xs={12} className="page-text-content for-investor-page-main-section-content">
							<Row>
								{CONTENT.PRIMARY.content_switcher.content_switcher_secondary_buttons[activeHowToElement].content}
							</Row>
						</Col>
						}
						
					</Row>
				</Container>
			</Container>
			
			{activeMainContentElement === "knowledge_base" &&
			<Container fluid={true} id="site-secondary-content" className="for-investor-site-content">
				<Container id="site-secondary-content-container">
					<Row id="for-investor-page-secondary-content" className="for-investor-content">
						
						<SectionHeader
							headerText="procedura uzyskiwania decyzji środowiskowej - informacje podstawowe"
						/>
						
						<Col as="ul" xs={12} className="for-investor-site-content-items">
							{
								CONTENT.SECONDARY.knowledge_base_items.map((item, i) =>
									<Row key={i} as="li" className="for-investor-content-item">
										<div
											className={(activeKnowledgeBaseElement === i ? "active-element" : "") + " for-investor-content-item-header"}
											onClick={(e) => {
												setActiveKnowledgeBaseElement(activeKnowledgeBaseElement === i ? null : i);
											}}
										>
											{item.item_title}
										</div>
										{activeKnowledgeBaseElement === i &&
										<div className="for-investor-content-item-content">{item.item_content}</div>
										}
									</Row>
								)
							}
						</Col>
					
					</Row>
				</Container>
			</Container>
			}
			
		</>
	);
	
	
};


export default ForInvestorPage;
