import React from "react";


import {Alert, Col} from "react-bootstrap";

import {Slide} from "@material-ui/core";


export const SystemMessageComponent = ({slideDirection = "down", enterTimeout = 1000, userMessageContent = "", messageType = "info", closeHandler = null}) => {

	
	return (
		
		<Slide
			in={true}
			direction={slideDirection}
			timeout={{enter: enterTimeout}}
			style={{transformOrigin: "left top", transform: "scale(1, 0.01) translate(0, 0)"}}
			mountOnEnter
			unmountOnExit
		>
			
			<Col
				as={Alert}
				xs={12}
				variant={messageType}
				className="digital-library-message"
				dismissible
				onClose={closeHandler ? () => closeHandler(null) : null}
			>
				<span>{userMessageContent}</span>
			</Col>
			
		</Slide>
		
	);
	
};


export default SystemMessageComponent;