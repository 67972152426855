import React from "react";


import {Row} from "react-bootstrap";


import ShopContentSpecializationsListItemComponent from "@Components/Content/User/Logged/Shop/Content/Specializations/SpecializationsList/Specialization";


export const ShopContentSpecializationsListComponent = ({orderedExpertPremiumPositions, premiumPositionRemoveHandler, expertAccountType}) => {

	
	let orderNr = 0;
	
	const orderedExpertPremiumPositionsList = orderedExpertPremiumPositions.map(premiumPosition =>
		<ShopContentSpecializationsListItemComponent
			key={premiumPosition.positionID}
			orderNr={++orderNr}
			premiumPositionData={premiumPosition}
			premiumPositionRemoveHandler={premiumPositionRemoveHandler}
			expertAccountType={expertAccountType}
		/>
	);
	
	
	return (
		<Row as="ul" className="shop-specializations-list">
			{orderedExpertPremiumPositionsList}
		</Row>
	);
	
};


export default ShopContentSpecializationsListComponent;
