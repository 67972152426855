import $ from "jquery";


export const ScrollToDomElement = (domElement, scrollDuration = 500) => {
	
	if (!domElement) {
		return false;
	}
	
	domElement = domElement.current || domElement;
	
	$('html, body').animate({
		scrollTop: $(domElement).offset().top - 150,
		behavior: "smooth"
	}, scrollDuration);

};


export default {
	ScrollToDomElement
};